import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Pageable } from 'src/app/models/pageable.model'
import { SignerEntity } from 'src/app/entity/signer.entity'
import { Signer } from 'src/app/models/signer.model'
import { MatSnackBar } from '@angular/material/snack-bar';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ApiProcessConfigService } from 'src/app/service/api-process-config.service';
import { SignersService } from 'src/app/service/signers.service';
import { UsersService } from 'src/app/service/users.service';
import { ProcessConfig } from 'src/app/models/processConfig.model'
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { PhonePrefix } from 'src/app/models/phonePrefix.model';
import { PhonePrefixService } from 'src/app/service/phone-prefix.service';


@Component({
  selector: 'app-processes-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ProcessesConfigComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  processConfigForm = new FormGroup({
    addressee_document_type: new FormControl('', [
      Validators.required,
    ]),
    addressee_document: new FormControl('', [
      Validators.required,
    ]),
    addressee_name: new FormControl('', [
      Validators.required,
    ]),
    addressee_last_name: new FormControl('', [
      Validators.required,
    ]),
    addressee_phone: new FormControl('', [
      Validators.required,
      Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      Validators.minLength(9),
      Validators.maxLength(9),
    ]),
    addressee_mail: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    addressee_country: new FormControl('+34', [
      Validators.required,
    ]),
    expedient: new FormControl('', [
      Validators.required,
    ]),
    company: new FormControl('', []),
  });

  loading: boolean = true;
  id = "";
  idnLast = "";
  pageable = new Pageable();
  status = 0;
  viewRejectReson = false;
  viewDocumentInfo = false;
  msgRejectReason: [{ reason: string, signerName: string }];
  viewForm = false;
  signerUuid: string;
  newUser: boolean = true;
  sendEmail: boolean = true;
  buttonSend: boolean = false;

  displayedColumns: string[] = ['name', 'description', 'templates', 'action'];

  //displayedColumns: string[] = ['user', 'template', 'creatinDate','signatureDate', 'state', 'view'];
  //dataSource = new MatTableDataSource(ELEMENT_DATA);
  dataSource = new MatTableDataSource();


  filteredOptions: Observable<SignerEntity[]>;
  filteredOptionsName: Observable<SignerEntity[]>;
  filteredOptionsLastName: Observable<SignerEntity[]>;
  displayedColumns2: string[] = ['check', 'name', 'idn', 'email', 'phone'];
  ELEMENT_DATA: SignerEntity[] = null;
  options: SignerEntity[];
  optionsCompany: [{"name": string}];
  dataSigner: SignerEntity;
  textToSearch = "";
  phonePrefixes: PhonePrefix[];

  constructor(
    private processConfigService: ApiProcessConfigService,
    private signersService: SignersService,
    private userService: UsersService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private phonePrefixService: PhonePrefixService,
  ) { }


  ngOnInit(): void {
    this.breadcrumbService.setElements([]);
    this.loading = true;
    //Obtiene Procesos
    this.textToSearch = ""
    this.processConfigService.getProcessConfig().subscribe(dataProcess => {
      if (dataProcess != null) {
        let ELEMENT_DATA2: ProcessConfig[] = dataProcess;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      }
    }, error => this.onError(error));

    //Obtiene firmantes registrados
    this.signersService.getSigners().subscribe(dataSigners => {
      if (dataSigners != null) {
        this.options = dataSigners.slice();
        this.filteredOptions =
          this.processConfigForm.controls.addressee_document.valueChanges.pipe(
            startWith(''),
            map((val) => this._filter(val))
          );
          this.filteredOptionsName = this.processConfigForm.controls.addressee_name.valueChanges.pipe(
            startWith(''),
            map((val) => this._filterName(val))
          );
          this.filteredOptionsLastName = this.processConfigForm.controls.addressee_last_name.valueChanges.pipe(
            startWith(''),
            map((val) => this._filterLastName(val))
          );
      }
    }, error => this.onError(error));

    //Obtiene datos del usuario logueado
    this.userService.getUser().subscribe(dataUser => {
      if (dataUser != null) {
        this.optionsCompany = dataUser.company;
        this.phonePrefixService.getPhonePrefixes().subscribe(dataPhonePrefixes => {
          this.phonePrefixes = dataPhonePrefixes;
          this.processConfigForm.get('addressee_country').setValue(dataUser.phonePrefix.prefix);
        });
        if(this.optionsCompany.length > 0){
          this.processConfigForm.controls['company'].setValidators([Validators.required]);
        }
      }
    }, error => this.onError(error));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  view(id) {
    this.viewForm = true;
    this.id = id
  }

  private _filter(idn: string): SignerEntity[] {
    let optionFilter = this.options;
    const filterValue = idn.toLowerCase();
    const returnFilter = optionFilter.filter(option => option.idn.toLowerCase().indexOf(filterValue) === 0);
    if (returnFilter.length > 0) {
      if (idn == returnFilter[0].idn) {
        if (this.processConfigForm.controls['addressee_name'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
          this.processConfigForm.controls['addressee_name'].setValue(returnFilter[0].name);
        }
        if (this.processConfigForm.controls['addressee_last_name'].value != returnFilter[0].lastName) { // Sin esta condición se genera un bucle infinito
          this.processConfigForm.controls['addressee_last_name'].setValue(returnFilter[0].lastName);
        }
        this.processConfigForm.controls['addressee_country'].setValue(returnFilter[0].countryPhone);
        this.processConfigForm.controls['addressee_document_type'].setValue(returnFilter[0].typeOfIdn);
        this.processConfigForm.controls['addressee_phone'].setValue(returnFilter[0].phone);
        this.processConfigForm.controls['addressee_mail'].setValue(returnFilter[0].email);
        this.dataSigner = returnFilter[0];
        this.newUser = false;
        this.signerUuid = returnFilter[0].uuid
      } else {
        this.newUser = true;
      }
    } else {
      this.newUser = true;
    }
    return returnFilter;
  }

  private _filterName(name: string): SignerEntity[] {
    let optionFilter = this.options;
    const filterValue = name.toLowerCase();
    const returnFilter = optionFilter.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    if (returnFilter.length > 0) {
      if (name == returnFilter[0].name) {
        if (this.processConfigForm.controls['addressee_document'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
          this.processConfigForm.controls['addressee_document'].setValue(returnFilter[0].idn);
        }
        if (this.processConfigForm.controls['addressee_last_name'].value != returnFilter[0].lastName) { // Sin esta condición se genera un bucle infinito
          this.processConfigForm.controls['addressee_last_name'].setValue(returnFilter[0].lastName);
        }
        this.processConfigForm.controls['addressee_country'].setValue(returnFilter[0].countryPhone);
        this.processConfigForm.controls['addressee_document_type'].setValue(returnFilter[0].typeOfIdn);
        this.processConfigForm.controls['addressee_phone'].setValue(returnFilter[0].phone);
        this.processConfigForm.controls['addressee_mail'].setValue(returnFilter[0].email);
        this.dataSigner = returnFilter[0];
        this.newUser = false;
        this.signerUuid = returnFilter[0].uuid
      } else {
        this.newUser = true;
      }
    } else {
      this.newUser = true;
    }
    return returnFilter;
  }

  private _filterLastName(lastName: string): SignerEntity[] {
    let optionFilter = this.options;
    const filterValue = lastName.toLowerCase();
    const returnFilter = optionFilter.filter(option => option.lastName.toLowerCase().indexOf(filterValue) === 0);
    if (returnFilter.length > 0) {
      if (lastName == returnFilter[0].lastName) {
        if (this.processConfigForm.controls['addressee_document'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
          this.processConfigForm.controls['addressee_document'].setValue(returnFilter[0].idn);
        }
        if (this.processConfigForm.controls['addressee_name'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
          this.processConfigForm.controls['addressee_name'].setValue(returnFilter[0].name);
        }
        this.processConfigForm.controls['addressee_country'].setValue(returnFilter[0].countryPhone);
        this.processConfigForm.controls['addressee_document_type'].setValue(returnFilter[0].typeOfIdn);
        this.processConfigForm.controls['addressee_phone'].setValue(returnFilter[0].phone);
        this.processConfigForm.controls['addressee_mail'].setValue(returnFilter[0].email);
        this.dataSigner = returnFilter[0];
        this.newUser = false;
        this.signerUuid = returnFilter[0].uuid
      } else {
        this.newUser = true;
      }
    } else {
      this.newUser = true;
    }
    return returnFilter;
  }

  cancel() {
    this.viewForm = false;
  }

  checkSendEmail(value: any) {
    this.sendEmail = value.currentTarget.checked;
  }

  onSubmit() {
    if (this.processConfigForm.valid) {
      this.buttonSend = true
      let data = {
        "expedient": this.processConfigForm.controls['expedient'].value,
        "signerUuid": this.signerUuid,
        "companyUuid": this.processConfigForm.controls['company'].value,
        "sendInstructions": this.sendEmail
      }

      if (this.newUser) {
        let signerConstructor = new Map();
        signerConstructor['name'] = this.processConfigForm.controls['addressee_name'].value;
        signerConstructor['typeOfIdn'] = this.processConfigForm.controls['addressee_document_type'].value;
        signerConstructor['idn'] = this.processConfigForm.controls['addressee_document'].value;
        signerConstructor['lastName'] = this.processConfigForm.controls['addressee_last_name'].value;
        signerConstructor['phone'] = this.processConfigForm.controls['addressee_phone'].value;
        signerConstructor['email'] = this.processConfigForm.controls['addressee_mail'].value;
        signerConstructor['countryPhone'] = this.processConfigForm.controls['addressee_country'].value;

        let dataSigner = new Signer(signerConstructor);
        this.signersService.postSigner(dataSigner).subscribe(dataDService => {
          if (dataDService.status == 200) {
            data.signerUuid = dataDService.body["uuid"];
            this.processConfigService.postProcessConfig(this.id, data).subscribe(dataDService => {
              if (dataDService.status == 200) {
                this.viewForm = false;
                this.ngOnInit();
                this.showMsgSnack('Proceso creado');
                this.router.navigate(['/processes/' + dataDService.body.uuid]);
              }
            }, error => this.onError(error));
          }
        }, error => this.onError(error));

      } else {
        this.processConfigService.postProcessConfig(this.id, data).subscribe(dataDService => {
          if (dataDService.status == 200) {
            this.viewForm = false;
            this.ngOnInit();
            this.showMsgSnack('Proceso creado');
            this.router.navigate(['/processes/' + dataDService.body.uuid]);
          }
        }, error => this.onError(error));
      }

    } else {
      this.showMsgSnack('Por favor ingrese los datos requeridos');
    }
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error.');
    this.buttonSend = false
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

}
