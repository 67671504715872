import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PhonePrefix } from '../models/phonePrefix.model';

@Injectable({
  providedIn: 'root'
})
export class PhonePrefixService {

  private basePath = environment.uriApiAzure;

  constructor(private http: HttpClient) { }

  public getPhonePrefixes(): Observable<PhonePrefix[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<PhonePrefix[]>(this.basePath + '/phone-prefixes/', httpOptions);
  }


}
