export class VariableEntity {
  'id': number;
  'name': string;
  'type': string;
  'anchor': string;
  'uuid': string;
  'value': string;
  'config': string;
  'container': string;
  'requiered': boolean;
  'orederNumer': number;
  'globalIdentifier': string;
  'configParsed';
}
