import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { GuestsService } from 'src/app/service/guests.service'
import { Document } from 'src/app/models/document.model'
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';

@Component({
  selector: 'app-setdown',
  templateUrl: './setdown.component.html',
  styleUrls: ['./setdown.component.css']
})
export class GuestSetdownComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Elija un archivo';

  uuid: string
  loading: boolean;
  document: Document;
  buttonDisable: boolean = false;
  reader = new FileReader;
  readyToSign: boolean = false;
  externalUrl: string;
  sendSignDocument = 0;

  documentForm = new FormGroup({
    file: new FormControl('', [
      Validators.required,
    ])
  });

  constructor(private guestsService: GuestsService,
    private activatedRouter: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private breadcrumbService: BreadcrumbService,
    private router: Router) {
    this.activatedRouter.params.subscribe(parametros => {
      this.uuid = parametros['id'];
    });
  }

  ngOnDestroy() {
    this.sendSignDocument = 8;
  }

  ngOnInit() {
    this.loading = true;
    this.guestsService.getDocumentByUuid(this.uuid).subscribe(data => {
      if (data != null) {
        this.document = data;
        this.loading = false;
        this.breadcrumbService.setElements([
          { name: 'Documentos', link: '/guest/documents' },
          { name: this.document.templateName, link: `/guest/document/${this.uuid}/setdown` }
        ]);
      }
    }, error => this.onError(error));
  }

  download() {
    let data = this.document.templateContent;
    let filename = this.document.templateName;
    const blobURL = URL.createObjectURL(this.pdfBlobConversion(data, 'application/pdf'));
    var fileLink = document.createElement('a');
    fileLink.href = blobURL;
    fileLink.download = filename;
    fileLink.click();
    this.showMsgSnack('Descargando documento...');
  }

  pdfBlobConversion(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name + ' - ';
      });

      // HTML5 FileReader API
      this.reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          let imgBase64Path = e.target.result;
        };
      };
      this.reader.readAsDataURL(imgFile.target.files[0]);
      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Elija un archivo';
    }
  }

  sign() {
    window.open(this.externalUrl, '_blank').focus();
    this.router.navigate(['/guest/documents']);
  }

  private checkDocument() {
    this.sendSignDocument++
    this.guestsService.getDocumentByUuid(this.uuid).subscribe(data => {
      if (data != null) {
        let externalUrl = data.signers[0].externalUrl;
        if (externalUrl) {
          this.externalUrl = externalUrl;
          this.loading = false;
          this.readyToSign = true;
        } else {
          if (this.sendSignDocument > 7) {
            this.router.navigate(['/guest/documents']);
            this.showMsgSnack('Problema de conexión con el servidor, por favor intente mas tarde');
          } else {
            setTimeout(() => {
              this.checkDocument();
            }, 1000);
          }

        }
      }
    }, error => this.onError(error));
  }

  onSubmit() {
    if (this.reader.readyState == 2) {
      let datatosend = { "base64": this.reader.result.toString().replace("data:application/pdf;base64,", "") };
      this.guestsService.putDocument(this.uuid, datatosend).subscribe(data => {
        if (data.status == 200) {
          this.showMsgSnack('Generando documento...');
          this.loading = true;
          this.checkDocument();
        }
      }, error => this.onError(error));

    } else {
      this.showMsgSnack('El documento no se ha cargado');
    }
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error');
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

}
