<div class="wrapper">

  <div class="cuerpo">

    <h1 class="tp-navandu line-navandu">DOCUMENTOS</h1>

    <app-spinner *ngIf="loading"></app-spinner>

    <div class="select-state" *ngIf="!loading">
      <button mat-icon-button class="refrescar" (click)="ngOnInit()">
        <div class="tooltip3">
          <mat-icon>refresh</mat-icon>
          <span class="tooltiptext3">Actualizar</span>
        </div>
      </button>
    </div>

    <table *ngIf="!loading" mat-table [dataSource]="dataSource" class="tabla">

      <ng-container matColumnDef="expedient">
        <th mat-header-cell *matHeaderCellDef>Expediente</th>
        <td mat-cell *matCellDef="let element">
          {{element.expedient}}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentAddressee">
        <th mat-header-cell *matHeaderCellDef>Destinatario</th>
        <td mat-cell *matCellDef="let element" class="{{element.documentType}}">
          {{element.documentAdresseeDTO.fullName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>Firmante</th>
        <td mat-cell *matCellDef="let element">
          <p *ngFor="let signer of element.signers">{{signer.name}}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="template">
        <th mat-header-cell *matHeaderCellDef>Documento</th>
        <td mat-cell *matCellDef="let element" class="{{element.status.nameEs}}">
          {{element.templateName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef>F. Creación</th>
        <td mat-cell *matCellDef="let element">
          {{element.creationDate | date:'dd-MM-yyyy HH:mm'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="signatureDate">
        <th mat-header-cell *matHeaderCellDef> F. Firma</th>
        <td mat-cell *matCellDef="let element">
          {{element.signatureDate | date:'dd-MM-yyyy HH:mm'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef>Acción</th>

        <td mat-cell *matCellDef="let element" class="action">

          <container-element *ngIf="element.status.id == 1 || element.status.id == 5">

              <button stop-propagation (click)="sign(element);" mat-icon-button
                *ngIf="element.templateType == 'SetSigner' || element.templateType == 'SetDown' || element.templateType == 'SetAdmin'; else otherClass">
                <div class="tooltip3">
                  <mat-icon class="first-icon">border_color</mat-icon>
                  <span class="tooltiptext3">Firmar</span>
                </div>
              </button>

              <ng-template #otherClass>
                <button stop-propagation (click)="sign(element);" mat-icon-button>
                  <div class="tooltip3">
                    <mat-icon class=element.templateType>file_upload</mat-icon>
                    <span class="tooltiptext3">Subir documento</span>
                  </div>
                </button>
              </ng-template>

          </container-element>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>