<div class="wrapper">
  <div class="cuerpo">
    <h1 class="tp-navandu line-navandu">GENERACIÓN DE DOCUMENTO</h1>
    <app-spinner *ngIf="loading"></app-spinner>

    <form name="entrar" (ngSubmit)="onSubmit()" class="formulario" autocomplete="off" *ngIf="!loading">
      <div class="documento">
        <h2 class="tp-navandu">INSTRUCCIONES PARA CARGAR EL DOCUMENTO: {{documentName}}</h2>
        <div [innerHTML]="instructions"></div>
        <mat-form-field>
          <div>
            <mat-toolbar>
              <input matInput [(ngModel)]="fileAttr"  readonly name="name" />
              <input type="button" mat-flat-button color="primary" class="boton-up" value="Subir archivo">
            </mat-toolbar>

            <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile"
              accept="image/jpeg,image/gif,image/png,application/pdf"/>
            <div *ngIf="reader.readyState == 1">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </div>
        </mat-form-field>
        <div class="enviar">
          <input type="submit" class="boton boton-send" value="Enviar" [disabled]="buttonDisable">
        </div>
      </div>
    </form>
  </div>
</div>
