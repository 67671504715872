import { VariableDocument } from '../models/variableDocument.model'

export class SignerBatch {
    public uuid: string;
    public idn: string;
    public typeOfIdn: string;
    public name: string;
    public lastName: string;
    public email:string;
    public phone:string;
    public countryPhone:string;
    public newSigner:boolean;
    public device:string
    public variables: VariableDocument[];
}
