import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AutenticationService } from '../service/autentication.service';
import { UsersService } from '../service/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  unauthorized = false;
  serverError = false;
  loginForm: FormGroup;
  private formSubmitAttempt: boolean;
  public loginInvalid: boolean;
  hide = true;

  constructor(private formBuilder: FormBuilder,
    private autenticationService: AutenticationService,
    private userService: UsersService,
    private router: Router
  ) { }

  ngOnInit(): void {
    localStorage.clear();
    this.loginForm = this.formBuilder.group({
      'username': ['', [
        Validators.required,
      ]
      ],
      'password': ['', [
        Validators.required,
      ]
      ]
    });
  }

  onSubmit() {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    if (this.loginForm.valid) {
      try {
        this.autenticationService.login(this.loginForm.value).subscribe(dataUser => {   // data is of type HttpResponse<Object>
          if (dataUser.status == 200) {
            localStorage.setItem('token', dataUser.headers.get('authorization'));
            //Obtiene datos del usuario logueado
            this.userService.getUser().subscribe(user => {   // data is of type HttpResponse<Object>
              if (user != null) {
                this.updateStyle(user);
                this.userService.getUserConsents(user.username).subscribe(resp => {
                  if (resp != null) {
                    user.name = resp.name;
                    localStorage.setItem('user', JSON.stringify(user));
                    this.autenticationService.isLogged();
                    this.router.navigate(['/templates']);
                  }
                },
                  error => {
                    if (error.status == 401) {
                    } else {
                      console.log("Error obteniendo nombre de usuario");
                    }
                  });
              }
            },
              error => {
                if (error.status == 401) {
                  console.log(error)
                } else {
                  var texto = error
                  this.serverError = true
                }
              }
            )
          }
        },
          error => {
            if (error.status == 401) {
              this.unauthorized = true
            } else {
              var texto = error
              this.serverError = true
              console.log(texto)
            }
          }
        )
      } catch (err) {
        this.loginInvalid = true;
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }

  updateStyle(user) {
    switch (user.username) {
      case "dslegal":
        user.style = "ds";
        break;
      case "navandu":
        user.style = "navandu";
        break;
      default:
        user.style = "ds";
        break;
    }
  }
}
