import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from "./login/login.component";
import { PresentationComponent } from "./public/presentation/presentation.component";
import { SignersComponent } from "./signers/signers/signers.component";
import { AccountComponent } from "./account/account.component";
import { EditsignerComponent } from "./signers/edit-signer/edit-signer.component";
import { TemplatesComponent } from "./templates/templates/templates.component";
import { ViewtemplateComponent } from "./templates/viewtemplate/viewtemplate.component";
import { SigntemplateComponent } from "./templates/signtemplate/signtemplate.component";
import { OwnDocumentComponent } from "./templates/own-document/own-document.component";
import { DocumentsComponent } from "./documents/documents/documents.component";
import { DocumentDetailsComponent } from "./documents/details/details.component";
import { ProcessComponent } from "./processes/process/process.component";
import { ViewprocessComponent } from "./processes/viewprocess/viewprocess.component";
import { SigndocumentprocessComponent } from "./processes/signdocumentprocess/signdocumentprocess.component";
import { ProcessesConfigComponent } from "./processes/config/config.component";
import { StatsComponent } from "./stats/stats.component";
import { ViewProcessDocumentComponent } from "./processes/viewdocument/viewdocument.component";
import { GuestLoginComponent } from "./guest/login/login.component";
import { ResetpasswordComponent } from "./guest/resetpassword/resetpassword.component";
import { NewpasswordComponent } from "./guest/newpassword/newpassword.component";
import { GuestDocumentsComponent } from "./guest/documents/documents/documents.component";
import { GuestSetdownComponent } from "./guest/documents/setdown/setdown.component";
import { GuestSetSignerComponent } from "./guest/documents/setsigner/setsigner.component";
import { GuestSetupComponent } from "./guest/documents/setup/setup.component";

import { IsLoginService } from './service/is-login.service';
import { IsLoginGuestService } from './service/is-login-guest.service';
import { ResenddocumentprocessComponent } from './processes/resenddocumentprocess/resenddocumentprocess.component';
import { ResendDocumentComponent } from './documents/resenddocumentprocess/resendDocument.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'presentacion', component: PresentationComponent },
  { path: 'signers', component: SignersComponent, canActivate: [ IsLoginService ] },
  { path: 'account', component: AccountComponent, canActivate: [ IsLoginService ] },
  { path: 'account/:viewForm', component: AccountComponent, canActivate: [ IsLoginService ] },
  { path: 'signers/:id/edit', component: EditsignerComponent, canActivate: [ IsLoginService ] },
  { path: 'templates', component: TemplatesComponent, canActivate: [ IsLoginService ] },
  { path: 'templates/ownDocument/:id', component: OwnDocumentComponent, canActivate: [ IsLoginService ] },
  { path: 'templates/:id', component: ViewtemplateComponent, canActivate: [ IsLoginService ] },
  { path: 'templates/:id/sign', component: SigntemplateComponent, canActivate: [ IsLoginService ] },
  { path: 'documents', component: DocumentsComponent, canActivate: [ IsLoginService ] },
  { path: 'documents/:id/details', component: DocumentDetailsComponent, canActivate: [ IsLoginService ] },
  { path: 'documents/resend/:idDocumento', component: ResendDocumentComponent, canActivate: [ IsLoginService ]  },
  { path: 'processes', component: ProcessComponent, canActivate: [ IsLoginService ] },
  { path: 'processes/config', component: ProcessesConfigComponent, canActivate: [ IsLoginService ]  },
  { path: 'processes/:id', component: ViewprocessComponent, canActivate: [ IsLoginService ]  },
  { path: 'processes/:idprocess/sign/:idtemplate', component: SigndocumentprocessComponent, canActivate: [ IsLoginService ]  },
  { path: 'processes/:idProcess/resend/:idDocumento', component: ResenddocumentprocessComponent, canActivate: [ IsLoginService ]  },
  { path: 'processes/:idprocess/document/:iddocument', component: ViewProcessDocumentComponent, canActivate: [ IsLoginService ]  },
  { path: 'stats', component: StatsComponent, canActivate: [ IsLoginService ] },
  { path: 'guest', component: GuestLoginComponent },
  { path: 'guest/newpassword', component: NewpasswordComponent },
  { path: 'guest/resetpassword', component: ResetpasswordComponent },
  { path: 'guest/documents', component: GuestDocumentsComponent, canActivate: [ IsLoginGuestService ] },
  { path: 'guest/document/:id/setdown', component: GuestSetdownComponent, canActivate: [ IsLoginGuestService ] },
  { path: 'guest/document/:id/setsigner', component: GuestSetSignerComponent, canActivate: [ IsLoginGuestService ] },
  { path: 'guest/document/:id/setup', component: GuestSetupComponent, canActivate: [ IsLoginGuestService ] },


  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
