import { Template } from 'src/app/models/template.model'
import { VariableResponse } from 'src/app/models/VariableResponse.model'

export class Document {
    public id: number;
    public templateUuid: string;
    public documentType: number;
    public templateName: string;
    public templateType: string;
    public templateContent: string;
    public template: Template;
    public variables: Object;
    public signers: Object;
    public creationDate: Date;
    public signatureDate: Date;
    public expedient: string;
    public rejectReason: string;
    public content: string;
    public variableResponses: Array<VariableResponse>;
    public companyUuid: string;
    public base64: string;
    public sendEmail: boolean;
    public sendEmailCreator: boolean;
    public coordinates: Object;
    public orderedSignatures: boolean;
}
