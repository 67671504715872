<div class="wrapper">
    <div class="cuerpo">
        <h1 class="tp-navandu line-navandu">GENERACIÓN DE DOCUMENTO</h1>
        <app-spinner *ngIf="loading"></app-spinner>
        <form name="entrar" (ngSubmit)="onSubmit()" class="formulario" autocomplete="off"
            *ngIf="!loading && !readyToSign">
            <div class="documento">
                <h2 class="tp-navandu"> INSTRUCCIONES PARA FIRMAR EL DOCUMENTO </h2>

                <ol>
                    <li>Descargue el documento haciendo click sobre el botón “Descargar”</li>
                    <li>Complete la información solicitada en el documento descargado</li>
                    <li>Guarde el documento con los datos ya cumplimentados</li>
                    <li>Presione el botón “Subir archivo” y seleccione el documento guardado</li>
                    <li>Haga click en “Enviar” para firmar el documento que ha cargado en el sistema</li>
                </ol>


                <a class="waves-effect waves-light boton" (click)="download()"><i
                        class="material-icons left ">cloud_download</i>Descargar</a>

                <mat-form-field>
                    <div>
                        <mat-toolbar>
                            <input matInput [(ngModel)]="fileAttr" readonly name="name" />

                            <input type="button" mat-flat-button color="primary" class="boton-up" value="Subir archivo">

                        </mat-toolbar>
                        <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile"
                            multiple="multiple" accept="application/pdf" />
                        <div *ngIf="reader.readyState == 1">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>
                </mat-form-field>
                <div class="enviar">
                    <input type="submit" class="boton boton-send" value="Enviar" [disabled]="buttonDisable">
                </div>
            </div>
        </form>
        <div *ngIf="readyToSign" class="documento firmar">
            <h2>Documento generado, puede ir a firmar</h2>
            <button (click)="sign()" class="boton">FIRMAR</button>
        </div>
    </div>
</div>