<!doctype html>
<html lang="es" xml:lang="es" xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-type" content="text/html; charset=UTF-8">    
     <title>Bienvenido Superdocs</title>    
    <meta name="keywords" content="Superdocs, gestor de documentos, reutilización documentos, plantillas documentos, documentos, firma remota, firma digital" />
    <meta name="description" content="SuperDocs online, gestor de documentos para mecanizar procesos en los que se utilizan los mismos documentos muchas veces y solo requieren cambiar datos ">
    <meta name="author" content="DS LegalTech">
    <link rel="stylesheet" href="./presentation.component.css">
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap" rel="stylesheet">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css" integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/" crossorigin="anonymous"> 
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
    <script src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.js" integrity="sha512-Rd5Gf5A6chsunOJte+gKWyECMqkG8MgBYD1u80LOOJBfl6ka9CtatRrD4P0P5Q5V/z/ecvOCSYC8tLoWNrCpPg==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js" integrity="sha512-Eak/29OTpb36LLo2r47IpVzPBLXnAMPAVypbSZiZ4Qkf8p/7S/XRG5xp7OKWPPYfJT6metI+IORkR5G8F900+g==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
    <script>
    new WOW().init();
    </script>
    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0, shrink-to-fit=no">
    <link rel="shortcut icon" href="../../../assets/img/landing/favicon.ico" type="image/x-icon" />	
    
    
    <!--Política de cookies-->
    <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css" />
     <script src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js" data-cfasync="false"></script>

</head>


<body>
   <header>
     
        <img class="movil img-fondo" src="../../../assets/img/landing/cabecera-movil.jpg"  alt="Bienvenido a SuperDocs"/>        
        <img class="desktop img-fondo" src="../../../assets/img/landing/cabecera.jpg"  alt="Bienvenido a SuperDocs"/> 
     <div class="cabecera">  
         <div class="bienvenida tipo-main-color animate__animated animate__backInDown">
             <h1>Bienvenido a </h1>
             <img class="logo" src="../../../assets/img/landing/logo-SuperDocs.png" alt="SuperDocs"/>
             <h2>Te presentamos la aplicación online que te ayudará a digitalizar la gestión documental de tu empresa, simplificando los procesos, acortando los tiempos, y formalizando firmas seguras y legalmente vinculantes ya sean biométricas o remotas. </h2>
         </div> 
     </div>
    <div class="back-main-color width-100 back-menu">
      <div class="centrar">
        <div class="menu tipo-white-color">
          <div class="label">MENÚ</div>
          <div class="spacer"></div>
          <div class="item"><a href="presentacion#quees">QUÉ ES</a></div>
          <div class="item"><a href="presentacion#quienes">NOSOTROS</a></div>
          <div class="item"><a href="presentacion#comoes">CÓMO ES</a></div>
          <div class="item"><a href="presentacion#ciclos">DOCUMENTOS</a></div>
          <div class="item"><a href="presentacion#contacto">CONTACTO</a> </div>
          </div>
      </div>
     </div>      
</header> 
<main>
  <a name="quees"  class="ancla"></a>
  <article class="queesA">
    <div class="centrar">
      <div class="pares">
        <div class="quees-texto tipo-main-color animate__animated animate__bounceInLeft">
            <h2>¿Saturado de documentos que ya no sabes donde archivar?</h2>
          <h2>¿El personal de tu clínica hace una y otra vez los mismos documentos empleando demasiado tiempo y dinero?</h2>  
            <h2>¿Los procesos se eternizan porque no consigues firmas a tiempo?</h2>
            <h2>¿Buscar documentos antiguos es un quebradero de cabeza?</h2>
        </div>
           
          <div class="quees-grafico back-grey-color animate__animated animate__bounceInRight">
             <img src="../../../assets/img/landing/rueda-docs.gif" alt="Múltiples tareas para la gestión de documentos"/> 
          </div>
      </div>
     </div>
  </article>    
 <article class="queesB">
    <div class="centrar">       
      <div class="pares" dir="rtl">
        <div class="quees-texto tipo-main-color animate__animated animate__bounceInRight wow " dir="ltr" id="anima">
          <h2>Ten en un solo lugar todos tu documentos digitalizados y búscalos con un solo click. </h2>
          <h2>Formaliza contratos con proveedores, empleados y clientes de forma ágil. </h2>  
          <h2>Usa plantillas de documentos y sólo rellena los datos del firmante. </h2>
          <h2>Envía documentos a firmar de forma segura y legal a tus clientes, empleados o proveedores o preséntales un dispositivo digital para hacer su firma biométrica.</h2>
        </div>
        
        <div class="quees-grafico back-grey-color animate__animated animate__bounceInLeft wow">
          <img src="../../../assets/img/landing/archivo-docs.gif" alt="Archivo de documentos"/> 
          </div>
      </div>
     </div>
  </article>    
<article class="queesA">
    <div class="centrar">        
      <div class="pares">
        <div class="quees-texto tipo-main-color animate__animated animate__bounceInLeft wow" dir="ltr">
          <h2>Es un gestor de plantillas, firmantes y expedientes  que forman parte de un proceso multidocumental asignado a un grupo de participantes. </h2>
          <h2>Una solución Legaltech que persigue ayudar a las clínicas y centros de salud con la digitalización, optimización y eleminación del papel en sus documentos para la gestión, compartición y firmas electrónicas de escritos y contratos. </h2> 

        </div>

        <div class="quees-grafico back-grey-color animate__animated animate__bounceInRight wow">
          <img src="../../../assets/img/landing/gestor-docs.gif" alt="Gestor de plantillas"/> 
          </div>
      </div>
    </div>
  </article>
  <a name="quienes" class="ancla"></a> 
   <article class="back-main-color">
       <div class="centrar">
           <h1 class="tipo-white-color text-center padding-2">Desarrollado por DS Legal Group, despacho líder en derecho sanitario</h1>
       </div>
   </article>
   
    <article class="quienes centrar">
        <img class="imagen-quienes1" src="../../../assets/img/landing/quienes-elci.jpg" alt="El Consentimiento Informado"/>
        <div class="texto-quienes tipo-main-color texto-quienes1">
            <h2>DS Legal como despacho de referencia en Derecho Sanitario, desarrolló <strong>ElConsentimiento.es</strong> para la gestión de plantillas de Consentimientos informados de +40 especialidades médicas.</h2>
        </div>
        <img class="imagen-quienes2" src="../../../assets/img/landing/quienes-sd.jpg" alt="SuperDocs Online"/> 
         <div class="texto-quienes tipo-main-color texto-quienes2">
            <h2>Ahora presenta <strong>SuperDocs Online</strong> que permite mecanizar procesos en los que se utilizan los mismos documentos muchas veces, y solo requieren cambiar los datos del firmante o firmantes.</h2>
           <h2>Incorpora <strong>múltiples canales de firma</strong> para facilitar la forma presencial o remota y todo con la garantía legal del despacho sanitario DS Legal.</h2>
          </div>
    </article>
    <a name="comoes" class="ancla"></a>
    <article class="back-main-color">
       <div class="centrar">
           <h1 class="tipo-white-color text-center padding-2">Cómo funciona SuperDocs</h1>
       </div>
   </article>
   
    <article class="centrar">
      <div class="comofunciona">
        <div class=" funciona funciona1 tipo-main-color animate__animated animate__bounceInLeft wow">
          <h2 class="text-right">Proporcionas tu catálogo de plantillas de documentos y los ponemos a tu disposición para que los uses fácilmente, solo asignando al firmante.</h2>
          <img src="../../../assets/img/landing/icono-como1.png" alt="Catálogo a tu disposición"/> 
        </div>
        <div class="funciona funciona2 tipo-main-color animate__animated animate__bounceInRight wow" dir="rtl">
          <h2 dir="ltr" class="text-left">Las plantillas se agrupan en conjuntos para que puedas generarlos en un único expediente, por ejemplo, contratación de personal.</h2>
          <img dir="ltr" src="../../../assets/img/landing/icono-como2.png" alt="Documentos por lotes"/> 
        </div>  
        <div class=" funciona funciona3 tipo-main-color animate__animated animate__bounceInLeft wow">
          <h2 class="text-right">Acceso desde cualquier lugar por internet, ni el emisor ni el destinatario tienen que instalar una aplicación.</h2>
          <img src="../../../assets/img/landing/icono-como3.png" alt="Sin instalaciones"/> 
        </div>
        <div class="funciona funciona4 tipo-main-color animate__animated animate__bounceInRight wow" dir="rtl">
          <h2 dir="ltr" class="text-left">Firmas presenciales biométricas y remotas por móvil respaldadas por VIDsigner, que asegura la legalidad y validez como TSP Cualificado.</h2>
          <img dir="ltr" src="../../../assets/img/landing/icono-como4.png" alt="Firmas certificadas"/> 
        </div> 
      </div>
    </article>
    <a name="ciclos" class="ancla"></a> 
    <article class="back-main-color">
       <div class="centrar">
           <h1 class="tipo-white-color text-center padding-2">Ciclos de vida de los documentos</h1>
       </div>
   </article>
  
    <article class="ciclosvida centrar">
          <img class="padding-2" src="../../../assets/img/landing/ciclobasico.jpg" alt="Ciclo básico: un documento para uno o varios firmantes"/> 
          <img class="padding-2" src="../../../assets/img/landing/ciclocomplejo.jpg" alt="Ciclo complejo: un proceso de n documentos para un firmante"/> 
    </article>   
    
     <article class="centrar ciclocomplejo">
        <h2 class="text-center tipo-main-color">Tipos de documentos en un ciclo complejo</h2> 
    
        <div class="movil">
                <input type="radio" name="slider-select-element" id="element1" checked="checked" />
                <input type="radio" name="slider-select-element" id="element2" />
                <input type="radio" name="slider-select-element" id="element3" />
                <input type="radio" name="slider-select-element" id="element4" />
                <input type="radio" name="slider-select-element" id="element5" />               
                
                <div id="slider-container">
                    <div id="slider-box">
                       
                        <div class="slider-element">
                            <div class="itema">
                                <figure class="padding-4">
                                  <figcaption class="text-center tipo-main-color"><strong>1. SetSigner</strong><br>datos+firma</figcaption>
                                  <img src="../../../assets/img/landing/setSigner.png"  alt="Caso 1: datos+firma"/>                                 
                                </figure>
                            </div>
                        </div>    
                        <div class="slider-element">
                            <div class="itema">
                                <figure class="padding-4">
                                <figcaption class="text-center"><strong>2. SetFile</strong><br>lectura+firma</figcaption>
                                <img src="../../../assets/img/landing/setFile.png"  alt="Caso 2: lectura+firma"/> 
                                </figure>
                            </div>                            
                        </div>
                        <div class="slider-element">
                            <div class="itema">
                                <figure class="padding-4">
                                <figcaption class="text-center"><strong>3. SetAdmin</strong><br>lectura+firma</figcaption>
                                <img src="../../../assets/img/landing/setAdmin.png"  alt="Caso 3: lectura+firma"/> 
                                </figure>
                            </div>
                        </div>
                        <div class="slider-element">
                            <div class="itema">
                                <figure class="padding-4">
                                <figcaption class="text-center"><strong>4. SetUp</strong><br>petición+carga+envío</figcaption>
                                <img src="../../../assets/img/landing/setUp.png"  alt="Caso 4: petición+carga+envío"/> 
                                </figure>
                            </div>
                        </div>
                         <div class="slider-element">
                            <div class="itema">
                                <figure class="padding-4">
                                <figcaption class="text-center"><strong>5. SetDown</strong><br>envío+descarga+cumplimentación+carga+firma</figcaption>
                                <img src="../../../assets/img/landing/setDown.png"  alt="Caso 5: envío+descarga+cumplimentación+carga+firma"/> 
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="slider-arrows">
                    <label for="element1"></label>
                    <label for="element2"></label>
                    <label for="element3"></label>
                    <label for="element4"></label>
                    <label for="element5"></label>                    
                </div>
                <div class="slider-indicators">
                    <ul>
                        <li id="point1"></li>
                        <li id="point2"></li>
                        <li id="point3"></li>
                        <li id="point4"></li>
                        <li id="point5"></li>                        
                    </ul>
                </div>
    </div> 
    
          
    <div class="ciclocomplejo-img desktop">
      <figure class="padding-4">
        <figcaption class="text-center"><strong>1. SetSigner</strong><br>datos+firma</figcaption>
        <img class="animate__animated animate__zoomIn wow" src="../../../assets/img/landing/setSigner.png"  alt="Caso 1: datos+firma"/> 
        </figure>
      <figure class="padding-4">
        <figcaption class="text-center"><strong>2. SetFile</strong><br>lectura+firma</figcaption>
        <img class="animate__animated animate__zoomIn wow" src="../../../assets/img/landing/setFile.png"  alt="Caso 2: lectura+firma"/> 
        </figure>
      <figure class="padding-4">
        <figcaption class="text-center"><strong>3. SetAdmin</strong><br>lectura+firma</figcaption>
        <img class="animate__animated animate__zoomIn wow" src="../../../assets/img/landing/setAdmin.png"  alt="Caso 3: lectura+firma"/> 
        </figure>
      <figure class="padding-4">
        <figcaption class="text-center"><strong>4. SetUp</strong><br>petición+carga+envío</figcaption>
        <img class="animate__animated animate__zoomIn wow" src="../../../assets/img/landing/setUp.png"  alt="Caso 4: petición+carga+envío"/> 
        </figure>
      <figure class="padding-4">
        <figcaption class="text-center"><strong>5. SetDown</strong><br>envío+descarga+cumplimentación+carga+firma</figcaption>
        <img class="animate__animated animate__zoomIn wow" src="../../../assets/img/landing/setDown.png"  alt="Caso 5: envío+descarga+cumplimentación+carga+firma"/> 
        </figure>
      </div>
    </article>
    <a name="contacto" class="ancla"></a> 
    <article class="back-main-color">
       <div class="centrar">
           <h1 class="tipo-white-color text-center ">¿Estás interesado? ¿Quieres una demostración?</h1>
           <p class="tipo-white-color text-center">Rellena este formulario y te llamamos</p>
       </div>
   </article>
    <article class="centrar">
        <form class="formulario tipo-main-color" name="MainForm" id="MainForm" method="post" >  
            <input type="text" id="nombre" name="nombre" placeholder="&#xf007;&nbsp;&nbsp; Nombre y apellidos*" pattern="[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,48}" required>
           <input type="text" id="empresa" name="empresa" placeholder="&#xf1ad;&nbsp;&nbsp; Nombre empresa*" required>           
           <input type="tel" id="phone" name="phone" placeholder="&#xf095;&nbsp;&nbsp; Teléfono*" pattern="[0-9]{9}" required>
           <input type="email" id="email" name="email" placeholder="&#xf2b6;&nbsp;&nbsp; Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required>  
           <input id="check1" type="checkbox" name="check1" value="check" onclick="activar()">
           <label for="check1" id="acepto">Acepto la <a class="tipo-main-color bold" href="politicaprivacidad.html" target="_blank">Política de privacidad.</a></label>
           <input type="submit" name="submit" id="submitButton" class="desactivo tipo-white-color" value="Enviar datos" disabled >
        </form>

    </article>
    
     <article class="back-main-color">
       <div class="centrar">
           <h2 class="tipo-white-color text-center">o si lo prefieres, contacta con nosotros</h2>
           <h1 class="tipo-white-color text-center padding-bottom">91 159 99 97 | info@elconsentimiento.es</h1>
       </div>
   </article>
    
    
    <article class="centrar">
    <div class="logos-footer">
      <img src="../../../assets/img/landing/logo-DSlegal.png" alt="DS LegalGroup"/> 
      <img src="../../../assets/img/landing/logo-legalTech.png"  alt="DS LegalTech"/> 
    </div>
    </article>
    
</main> 
 <footer class="tipo-footer-color back-grey-color">
    <div class="centrar">
         <p class="text-center"><strong>DS Legal Group · Directum Societas S. L.</strong> © Copyright 2021. Todos los derechos reservados.<br>C/ Doctor Esquerdo 66 28007 Madrid. · 28007 · Madrid · Tel.: <a href="tel:910000000" target="_blank" class="tipo-footer-color underline">910 000 000</a> Email: <a href="mailto:informacion@dslegal.es" target="_blank" class="tipo-footer-color underline">informacion@dslegal.es</a><br>
        <a href="#" target="_blank" class="tipo-footer-color underline">Aviso Lega</a>l • <a href="#" target="_blank" class="tipo-footer-color underline">Política de Privacidad</a> • <a href="#" target="_blank" class="tipo-footer-color underline">Política de Cookies</a></p>
        
     </div>
 </footer>  
    
  <script>
    let errorButton = true
	var button = document.getElementById("submitButton")    
  function actualizar(element){
    document.getElementById("description").value = element.value
  }    
</script>    
    
</body>
</html>
