import { DocumentSignerRoleEntity } from "./documentSignerRole.entity";
import { CoordinatesEntity } from "./coordinates.entity";

export class SignerEntity {
    'uuid': string;
    'checked': boolean = false;
    'device': string;
    'documentSignerRole': DocumentSignerRoleEntity;
    'name': string;
    'lastName': string;
    'idn': string;
    'typeOfIdn': string;
    'email': string;
    'phone': string;
    'newSigner': boolean;
    'coordinates': CoordinatesEntity;
    'countryPhone':string;
}
