import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiStatsService } from 'src/app/service/api-stats.service';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { Stats } from 'src/app/models/stats.model';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {

  loading: boolean = true;
  stats: Stats;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private statsService: ApiStatsService,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setElements([]);
    this.loading = true;

    this.statsService.getStats().subscribe(data => {
      this.stats = data;
      this.loading = false;
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          this.showMsgSnack('Ha ocurrido un error');
        }
      }
    );
  }

  private showMsgSnack(msg) {
    this.snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }
}
