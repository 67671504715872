import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.css']
})
export class PresentationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  activar() {
    var checkBox = document.getElementById("check1");
    var button = document.getElementById("submitButton");
    console.log(checkBox)
    console.log(button)
    // if (checkBox.checked == true) {
    //   button.disabled = false;
    //   button.classList.remove("desactivo");
    //   button.classList.add("activo");

    // } else {
    //   button.disabled = true;
    //   button.classList.remove('activo');
    //   button.classList.add('desactivo');
    // }
  }

}
