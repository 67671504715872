<div class="wrapper">
  <div class="cuerpo">

    <h1 class="tp-navandu line-navandu">REENVIAR DOCUMENTO</h1>

    <app-spinner *ngIf="loading"></app-spinner>

    <form name="entrar" [formGroup]="documentForm" class="formulario" autocomplete="off" *ngIf="!loading">

      <div class="documento" *ngIf="showSignatureChanels || documentForm.controls['signatureType'].value == 2 || template.templateType.name == 'File' || doneImageConvert=='loading'">
        <div class="flex-row" *ngIf="documento.documentType == 'biometric'">
          <mat-form-field>
            <mat-label>Dispositivo</mat-label>
            <mat-select formControlName="device">
              <mat-option *ngFor="let device of devices" [value]="device.deviceName">
                {{device.deviceDescription}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <app-spinner *ngIf="doneImageConvert=='loading'"></app-spinner>
        <div *ngIf="doneImageConvert == 'done'">
          <div class="select-state" *ngIf="!loading">
            <button mat-icon-button class="refrescar" (click)="changePage('previus')">
              <div class="tooltip3">
                <mat-icon>chevron_left</mat-icon>
                <span class="tooltiptext3">Anterior</span>
              </div>
            </button>
            <p>Página: {{pageImagePdf +1}} de {{lengthPageImagePdf}}</p>
            <button mat-icon-button class="refrescar" (click)="changePage('next')">
              <div class="tooltip3">
                <mat-icon>chevron_right</mat-icon>
                <span class="tooltiptext3">Siguiente</span>
              </div>
            </button>
          </div>
          <div class="example-boundary" [style.backgroundImage]="'url('+ imagepdf +')'">
            <div class="example-box" cdkDragBoundary=".example-boundary" cdkDrag (cdkDragEnded)="dragEnd($event)" *ngIf="documento.template.templateType.name == 'File'">
              Ubicación de firma
            </div>
          </div>
        </div>
      </div>

      <div class="enviar">
        <input type="submit" class="boton" value="Reenviar" (click)="onSubmit()" [disabled]="buttonDisable">
      </div>
      <a routerLink="/processes/{{idProcess}}" class="volver"><span>Volver</span> </a>

    </form>
  </div>
</div>
