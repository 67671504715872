import { Component, Input, OnInit } from '@angular/core';
import { DocumentSigned } from 'src/app/models/documentSigned.model'

@Component({
  selector: 'shared-document-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class SharedDocumentDetailsComponent implements OnInit {

  @Input() document: DocumentSigned;
  @Input() backLink: string;
  @Input() flagString: boolean;

  constructor() {}

  ngOnInit() {
  }
}
