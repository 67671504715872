import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { ApiTemplatesService } from 'src/app/service/api-templates.service'
import { Template } from 'src/app/models/template.model'
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';


@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {

  loading: boolean = true;
  displayedColumns: string[] = ['name', 'description', 'action'];
  dataSource = new MatTableDataSource();
  textToSearch = '';
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  uuid: string;
  showOwnDocument: boolean;

  constructor(private templateService: ApiTemplatesService,
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit() {
    this.breadcrumbService.setElements([]);
    this.loading = true;
    this.paginator._intl.itemsPerPageLabel = 'Items por página'
    this.templateService.getTemplates().subscribe(dataTemplate => {
      if (dataTemplate != null) {
        var fileAdmin = dataTemplate.map(function (obj, index: number) { return [obj , index]; }).find(option => option[0]['templateType']['name'] === 'SetFileAdmin');
        if (fileAdmin != undefined){
          this.uuid = fileAdmin[0]['uuid']
          this.showOwnDocument =  true;
          dataTemplate.splice(<number>fileAdmin[1],1)
        }
        let ELEMENT_DATA2: Template[] = dataTemplate;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.textToSearch.trim().toLowerCase();
        this.loading = false;
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
          console.log(error)
        } else {
          var texto = error
          console.log(texto)
        }
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  search() {
    this.ngOnInit();
  }

  view(id) {
    this.router.navigate(['/templates/' + id]);
  }

  sign(id) {
    this.router.navigate(['/templates/' + id + '/sign']);
  }

  uploadDocument(id: string) {
    this.router.navigate(['/templates/ownDocument/' + id]);
  }
}
