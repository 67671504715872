import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiProcessService } from 'src/app/service/api-process.service'
import { ApiDocumentsService } from 'src/app/service/api-documents.service'
import { PageDocument } from 'src/app/models/pageDocument.model'
import { Signer } from 'src/app/models/signer.model'
import { DocumentTypeModel } from 'src/app/models/documentType.model';
import { Process } from 'src/app/models/process.model';
import { Template } from 'src/app/models/template.model';
import { TemplateBatch } from 'src/app/models/templateBatch.model'
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { ApiTemplatesService } from 'src/app/service/api-templates.service';
import { UsersService } from '../../service/users.service';

import { saveAs } from "file-saver";


export interface Device {
  deviceDescription: string,
  deviceName: number
}

@Component({
  selector: 'app-viewprocess',
  templateUrl: './viewprocess.component.html',
  styleUrls: ['./viewprocess.component.css']
})
export class ViewprocessComponent implements OnInit {

  documentForm = new FormGroup({
    signatureType: new FormControl('', [
      Validators.required,
    ]),
    device: new FormControl('', [
    ]),

  })

  devices: Device[];
  signatureChanels: DocumentTypeModel[];
  loading: boolean = true;
  loadingDocuments: boolean = false;
  id: string;
  process: Process;
  pageDocument: PageDocument;
  signer: Signer;
  expedient: string;
  displayedColumns: string[] = ['document', 'documentType', 'status', 'creationDate', 'signatureDate', 'action'];
  dataSource = new MatTableDataSource();
  severalTemplates = false;
  templateBatch: string[] = [];
  orderTemplates: Template[];

  displayedColumnsTemplates: string[] = ['document', 'type', 'action'];
  dataSourceTemplates = new MatTableDataSource();

  dataSourceSeveralTemplates = new MatTableDataSource<Template>();
  displayedColumnsSeveralTemplates: string[] = ['check', 'document', 'instruction', 'type'];

  constructor(private processService: ApiProcessService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private documentService: ApiDocumentsService,
    private templateService: ApiTemplatesService,
    private userService: UsersService,
    private _snackBar: MatSnackBar) {
    this.activatedRouter.params.subscribe(parametros => {
      this.id = parametros['id'];
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  processForm = new FormGroup({});

  ngOnInit(): void {
    this.loading = true;
    this.severalTemplates = false
    //Obtiene templates por proceso
    this.processService.getProcessbyUUid(this.id).subscribe(dataProcessTemplate => {
      if (dataProcessTemplate != null) {
        this.process = dataProcessTemplate;
        this.expedient = dataProcessTemplate["expedient"]
        this.dataSourceTemplates = new MatTableDataSource(this.process.processConfig.templates);
        var templateBarch: Template[] = this.process.processConfig.templates.slice()
        this.dataSourceSeveralTemplates = new MatTableDataSource(templateBarch.filter(option => option.batch));
        this.signer = this.process.signer;
        this.orderTemplates = this.process.processConfig.templates.sort((a, b) => {
          let aOrder = (a.orderNumber ? a.orderNumber : 999);
          let bOrder = (b.orderNumber ? b.orderNumber : 999);
          return (aOrder > bOrder) ? 1 : -1;
        });
        this.dataSourceTemplates = new MatTableDataSource(this.orderTemplates);
        this.breadcrumbService.setElements([
          { name: 'Procesos', link: '/processes' },
          { name: this.process.processConfig.name, link: `/processes/${this.id}` }
        ]);
        this.loading = false;
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          var texto = error
        }
      }
    )
    //Obtiene dispositivos asociados a un usuario
    this.templateService.getDevices().subscribe(dataDevices => {   // data is of type HttpResponse<Object>
      if (dataDevices != null) {
        this.devices = dataDevices;
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          var texto = error
        }
      }
    )
    //Obtiene canales de firmas disponibles para el usuario logueado
    this.userService.getUser().subscribe(dataUser => {   // data is of type HttpResponse<Object>
      if (dataUser != null) {
        this.signatureChanels = dataUser.documentTypesDtos;
      }
    },
      error => {
        if (error.status == 401) {
        } else {
          var texto = error
        }
      }
    )
    //Obtiene documentos por proceso
    this.processService.getProcessDocumentsbyUUid(this.id).subscribe(dataProcess => {
      if (dataProcess != null) {
        this.pageDocument = dataProcess;
        if (this.pageDocument.content.length > 0) {
          this.signer = this.pageDocument.content[0].signers[0];
          this.dataSource = new MatTableDataSource(this.pageDocument.content);
          this.updateTemplateDocs();
        }
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          var texto = error
        }
      }
    )
  }

  private updateTemplateDocs() {
    this.orderTemplates.forEach(template => {
      template.numDocs = this.getNumberDocs(template.name);
    });
    this.dataSourceTemplates = new MatTableDataSource(this.orderTemplates);
  }

  private getNumberDocs(templateName) {
    let total = 0;
    this.pageDocument.content.forEach(document => {
      if (document.template.name == templateName) {
        total++;
      }
    });

    return total;
  }

  sign(template) {
    this.router.navigate(['/processes/' + this.id + '/sign/' + template.uuid]);
  }

  resignVidForms(document) {
    this.router.navigate(['/processes/' + this.id + '/resend/' + document.uuid]);
  }
  
  reloadProcessDocument() {
    this.loadingDocuments = true;
    //Obtiene documentos por proceso
    this.processService.getProcessDocumentsbyUUid(this.id).subscribe(dataProcess => {
      if (dataProcess != null) {
        this.loadingDocuments = false;
        this.pageDocument = dataProcess;
        if (this.pageDocument.content.length > 0) {
          this.signer = this.pageDocument.content[0].signers[0];
          this.dataSource = new MatTableDataSource(this.pageDocument.content);
        }
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          var texto = error
          this.loadingDocuments = false;
        }
      }
    )
  }

  downloadProcessDocument() {
    //Descarga documentos por proceso
    this.processService.getDocumentsDownload(this.id).subscribe(documentsProcess => {
      this.getZipFile(documentsProcess)
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          var texto = error
          console.log(texto);
        }
      }
    )
  }

  getZipFile(data: any) {
    const blob = new Blob([data['body']], { type: 'application/zip' });

    const a: any = document.createElement('a');
    document.body.appendChild(a);

    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${this.expedient}.zip`;
    a.click();
    window.URL.revokeObjectURL(url);

  }

  downloadDocument(id) {
    this.documentService.getDocument(id).subscribe(dataDocument => {   // data is of type HttpResponse<Object>
      if (dataDocument.status == 200) {
        this.download(dataDocument.body.pdfContent, dataDocument.body.filename, dataDocument.body.mimeType)
      }
    },
      error => {
        if (error.status == 401) {
          console.log(error)
        } else {
          var texto = error
          console.log(texto)
        }
      }
    )
  }

  downloadReport(id) {
    this.documentService.getDocumentReport(id).subscribe(dataDocument => {   // data is of type HttpResponse<Object>
      if (dataDocument.status == 200) {
        this.download(dataDocument.body.pdfContent, dataDocument.body.filename, dataDocument.body.mimeType)
      }
    },
      error => {
        if (error.status == 401) {
          console.log(error)
        } else {
          var texto = error
          console.log(texto)
        }
      }

    )
  }

  downloadVidForms(id) {
    this.documentService.getDocumentVidForms(id).subscribe(dataDocument => {
      const pom = document.createElement('a');
      const csvContent = dataDocument.body;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', 'forms.csv');
      pom.click();
    },
      error => {
        console.log(error);
      });
  }

  download(data, filename, mimeType) {
    this._snackBar.open('Descargando documento...', '', {
      duration: 2000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
    if (!mimeType) {
      mimeType = 'application/pdf';
    }
    let extension = mimeType.split('/')[1];
    const blobURL = URL.createObjectURL(this.pdfBlobConversion(data, mimeType));
    var fileLink = document.createElement('a');
    fileLink.href = blobURL;
    fileLink.download = `${filename}.${extension}`;
    fileLink.click();
  }

  pdfBlobConversion(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  navigateDocument(uuid) {
    this.router.navigate([`/processes/${this.id}/document/${uuid}`], { state: { processName: this.process.processConfig.name } });
  }

  getSetUpDescription(document) {
    if (document.variableResponses.length > 0) {
      return document.variableResponses[0].value;
    }
  }

  checkSeveralTemplates(value: any) {
    this.severalTemplates = value.currentTarget.checked;
  }

  checkTemplate(event: any, value: any) {
    this.dataSourceSeveralTemplates.data.filter(s => s.uuid == value.uuid)[0].checked = event.currentTarget.checked
    if (event.currentTarget.checked) {
      this.templateBatch.push(value)
    } else {
      let index = this.templateBatch.indexOf(value);
      this.templateBatch.splice(index, 1);
    }
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error');
  }


  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  onSubmit() {
    if (this.documentForm.valid) {
      //Envía documento a firmar
      let templatesBatch = new TemplateBatch();
      templatesBatch.device = this.documentForm.controls['device'].value;
      templatesBatch.documentType = Number(this.documentForm.controls['signatureType'].value);
      templatesBatch.templates = [];
      this.dataSourceSeveralTemplates.data.filter(option => option.checked).forEach(element => {
        templatesBatch.templates.push(element.uuid)
      });
      this.processService.postProcesTemplateBatch(this.id, templatesBatch).subscribe(dataDService => {   // data is of type HttpResponse<Object>
        if (dataDService.status == 200) {
          this.showMsgSnack('Documentos enviados a firmar');
          this.ngOnInit()
        }
      },
        error => this.onError(error)
      )
    }
  }

  finalizeProcess() {
    this.processService.putProces(this.id).subscribe(dataDService => {   // data is of type HttpResponse<Object>
      if (dataDService.status == 200) {
        this.showMsgSnack('Proceso Finalizado');
        this.router.navigate(['/processes']);
      }
    },
      error => this.onError(error)
    )
  }

}
