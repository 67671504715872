import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ApiDocumentsService } from 'src/app/service/api-documents.service';
import { DocumentSigned } from 'src/app/models/documentSigned.model';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';

@Component({
  selector: 'app-viewdocument',
  templateUrl: './viewdocument.component.html',
  styleUrls: ['./viewdocument.component.css']
})
export class ViewProcessDocumentComponent implements OnInit {

  uuidProcess: string;
  uuidDocument: string;
  backLink: string;
  document: DocumentSigned;

  constructor(
    private activatedRouter: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private documentService: ApiDocumentsService
  ) {
    this.activatedRouter.params.subscribe(params => {
      this.uuidProcess = params.idprocess;
      this.uuidDocument = params.iddocument;
    });
  }

  ngOnInit(): void {
    const processName = history.state.processName;
    this.backLink = `/processes/${this.uuidProcess}`;
    this.documentService.getDocument(this.uuidDocument).subscribe(data => {
      this.document = data.body;
      this.breadcrumbService.setElements([
        { name: 'Procesos', link: '/processes' },
        { name: processName? processName: this.uuidProcess, link: `/processes/${this.uuidProcess}`},
        { name: this.document.templateName, link: '/processes/'+this.uuidProcess+'/document/'+this.uuidDocument}
      ]);
    });
  }
}
