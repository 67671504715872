export class Signer {
    uuid: string;
    idn: string;
    typeOfIdn: string;
    name: string;
    lastName: string;
    email:string;
    email2:string;
    phone:string;
    countryPhone:string;
    newSigner:boolean;
    device:string

    constructor(formData: any){
        this.typeOfIdn = formData['typeOfIdn'];
        this.idn = formData['idn'];
        this.name = formData['name'];
        this.lastName = formData['lastName'];
        this.phone = formData['phone'];
        this.email = formData['email'];
        if(formData['email2']){
            this.email2 = formData['email2'];
        }
        this.countryPhone = formData['countryPhone'];
    }
}
