import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SignersService } from "src/app/service/signers.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Signer } from 'src/app/models/signer.model';
import { PhonePrefix } from 'src/app/models/phonePrefix.model';
import { PhonePrefixService } from 'src/app/service/phone-prefix.service';
import { UsersService } from 'src/app/service/users.service';


@Component({
  selector: 'app-editsigner',
  templateUrl: './edit-signer.component.html',
  styleUrls: ['./edit-signer.component.css']
})
export class EditsignerComponent implements OnInit {

  id: string;
  phonePrefixes: PhonePrefix[];

  signerForm = new FormGroup({
    typeOfIdn: new FormControl('', [
      Validators.required,
    ]),
    idn: new FormControl('', [
      Validators.required,
    ]),
    name: new FormControl('', [
      Validators.required,
    ]),
    lastName: new FormControl('', [
      Validators.required,
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      Validators.minLength(9),
      Validators.maxLength(9),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    countryPhone: new FormControl('+34', [
      Validators.required,
    ])
  });

  constructor(private formBuilder: FormBuilder,
    private signerService: SignersService,
    private activatedRouter: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private router: Router,
    private phonePrefixService: PhonePrefixService,
    ) {
    this.activatedRouter.params.subscribe(parametros => {
      this.id = parametros['id'];
    })
  }

  ngOnInit(): void {
    //Obtiene firmante
    this.phonePrefixService.getPhonePrefixes().subscribe(dataPhonePrefixes => {
      this.phonePrefixes = dataPhonePrefixes;
    });
    this.signerService.getSigner(this.id).subscribe(dataSigner => {   // data is of type HttpResponse<Object>
      if (dataSigner != null) {
        this.signerForm.controls['typeOfIdn'].setValue(dataSigner.typeOfIdn);
        this.signerForm.controls['idn'].setValue(dataSigner.idn);
        this.signerForm.controls['name'].setValue(dataSigner.name);
        this.signerForm.controls['lastName'].setValue(dataSigner.lastName);
        this.signerForm.controls['phone'].setValue(dataSigner.phone);
        this.signerForm.controls['email'].setValue(dataSigner.email);
        //this.signerForm.controls['countryPhone'].setValue(dataSigner.countryPhone);
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
          console.log(error)
        } else {
          var texto = error
          console.log(texto)
        }
      }
    )
  }

  onSubmit() {
    if (this.signerForm.valid) { //this.documentForm.valid
      let dataSigner: Signer =  new Signer(this.signerForm.value);
      //Envía documento a firmar
      this.signerService.putEditSigner(this.id, dataSigner).subscribe(dataDService => {   // data is of type HttpResponse<Object>
        if (dataDService.status == 200) {
          this._snackBar.open("Datos actualizados correctamente", "", {
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
          });
          this.router.navigate(['/signers/signers']);
        }
      },
        error => {
          if (error.status == 401) {
            this._snackBar.open(error, "", {
              duration: 2000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
            });
          } else {
            var texto = error
            this._snackBar.open(texto, "", {
              duration: 2000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
            });
          }
        }
      )


    } else {
      console.log(this.signerForm)
      var texto = "Por favor ingrese los datos requeridos"
      this._snackBar.open(texto, "", {
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
      });
    }
  }


}
