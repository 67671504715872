<div class="wrapper">
  <div class="cuerpo">

    <app-spinner *ngIf="loading"></app-spinner>

    <div *ngIf="!loading">

      <div class="row documento" *ngIf="process.company!=null">
        <h2> INFORMACIÓN DEL PROCESO </h2>

        <div class="flex-row info-dest">
          <mat-form-field>
            <mat-label>Empresa</mat-label>
            <input matInput disabled>{{process.company.name}}
          </mat-form-field>
          <mat-form-field class="email">
            <mat-label>Estado</mat-label>
            <input matInput disabled>{{process.processStatus.nameEs}}
          </mat-form-field>

          <button class="boton boton-fin-proceso" type="button" (click)="finalizeProcess()" *ngIf="process.processStatus.id == 1">Finalizar Proceso</button>

        </div>
      </div>

      <div class="row documento" *ngIf="signer!=null">
        <h2> INFORMACIÓN DEL DESTINATARIO </h2>

        <div class="flex-row info-dest">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput disabled>{{signer.name}} {{signer.lastName}}
          </mat-form-field>
          <mat-form-field class="email">
            <mat-label>Exped.</mat-label>
            <input matInput disabled>{{expedient}}
          </mat-form-field>
          <mat-form-field class="email">
            <mat-label>Email</mat-label>
            <input matInput disabled>{{signer.email}}
          </mat-form-field>
        </div>
      </div>

      <ng-container *ngIf="process.processStatus.id == 1">
        <h2>PLANTILLAS DISPONIBLES PARA ENVIAR A FIRMAR</h2>
        <div class="switch-button">
          <label class="varios-firmantes">¿Enviar varias plantillas?</label>
          <input type="checkbox" name="switch-button" id="switch-label" class="switch-button__checkbox" (click)="checkSeveralTemplates($event)">
          <label for="switch-label" class="switch-button__label"></label>
        </div>
        <table mat-table [dataSource]="dataSourceTemplates" class="tabla tabla-plantillas-firmar" *ngIf="!severalTemplates">

          <ng-container matColumnDef="document">
            <th mat-header-cell *matHeaderCellDef> Documento </th>
            <td mat-cell *matCellDef="let element">
              <span>{{element.name}}</span>
              <span *ngIf="element.numDocs > 0" style="margin-left:10px;">({{element.numDocs}})</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element.templateType.name}}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Acción </th>
            <td mat-cell *matCellDef="let element">
              <button (click)="sign(element)" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                <div class="tooltip3">
                  <mat-icon>send</mat-icon>
                  <span class="tooltiptext3">Añadir documento</span>
                </div>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTemplates"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsTemplates;"></tr>
        </table>
      </ng-container>


      <form name="entrar" [formGroup]="documentForm" (ngSubmit)="onSubmit()" class="formulario" autocomplete="off" *ngIf="severalTemplates">
        <table mat-table [dataSource]="dataSourceSeveralTemplates" class="tabla tabla-plantillas-firmar">

          <ng-container matColumnDef="check">
            <th mat-header-cell *matHeaderCellDef> Acción </th>
            <td mat-cell *matCellDef="let element">
              <input type="checkbox" name="switch-button" id="switch-label" class="switch-button__checkbox" (click)="checkTemplate($event, element)" [checked]="element.checked">
            </td>
          </ng-container>

          <ng-container matColumnDef="document">
            <th mat-header-cell *matHeaderCellDef> Documento </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="instruction">
            <th mat-header-cell *matHeaderCellDef> Descripción </th>
            <td mat-cell *matCellDef="let element"> {{element.description}}</td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element.templateType.name}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsSeveralTemplates"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsSeveralTemplates;"></tr>
        </table>

        <div class="documento">
          <div class="flex-row">
            <mat-form-field>
              <mat-label>Tipo de firma</mat-label>
              <mat-select required formControlName="signatureType">
                <!-- <mat-option value="1">analog</mat-option> -->
                <mat-option *ngFor="let chanel of signatureChanels" [value]="chanel.id">
                  {{chanel.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>


          </div>
          <div class="flex-row" *ngIf="documentForm.controls['signatureType'].value == 2">
            <mat-form-field>
              <mat-label>Dispositivo</mat-label>
              <mat-select formControlName="device">
                <mat-option *ngFor="let device of devices" [value]="device.deviceName">
                  {{device.deviceDescription}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="enviar">
            <input type="submit" class="boton" value="Enviar" [disabled]="buttonDisable">
          </div>
        </div>
      </form>

      <h2>DOCUMENTOS</h2>
      <div class="select-state" (change)="filterStatus($event)" *ngIf="!loading">
        <button mat-icon-button class="refrescar" (click)="reloadProcessDocument()">
          <div class="tooltip3">
            <mat-icon>refresh</mat-icon>
            <span class="tooltiptext3">Actualizar</span>
          </div>
        </button>
        <button mat-icon-button class="refrescar" (click)="downloadProcessDocument()">
          <div class="tooltip3">
            <mat-icon>download</mat-icon>
            <span class="tooltiptext3">Descargar Documentos</span>
          </div>
        </button>
      </div>

      <app-spinner *ngIf="loadingDocuments"></app-spinner>
      <table *ngIf="!loadingDocuments" mat-table [dataSource]="dataSource" class="tabla tabla-documentos">

        <ng-container matColumnDef="document">
          <th mat-header-cell *matHeaderCellDef> Documento </th>
          <td mat-cell *matCellDef="let element">
            {{element.templateName}}
            <span *ngIf="element.template.templateType.name == 'SetUp'">
              ( {{getSetUpDescription(element)}} )
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="documentType">
          <th mat-header-cell *matHeaderCellDef> Tipo de firma </th>
          <td mat-cell *matCellDef="let element" class="{{element.documentType}}"> {{element.documentType}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Estado </th>
          <td mat-cell *matCellDef="let element" class="{{element.status.nameEs}}"> {{element.status.nameEs}}
          </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef> Fecha Creación </th>
          <td mat-cell *matCellDef="let element"> {{element.creationDate | date:'dd-MM-yyyy HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="signatureDate">
          <th mat-header-cell *matHeaderCellDef> Fecha Firma </th>
          <td mat-cell *matCellDef="let element"> {{element.signatureDate | date:'dd-MM-yyyy HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Acción </th>
          <td mat-cell *matCellDef="let element">
            <container-element [ngSwitch]="element.status.id">
              <button stop-propagation (click)="downloadDocument(element.uuid);" mat-icon-button *ngSwitchCase="2" aria-label="Example icon button with a vertical three dot icon">
                <div class="tooltip3">
                  <mat-icon class="first-icon">visibility</mat-icon>
                  <span class="tooltiptext3">Descargar</span>
                </div>
              </button>

            </container-element>
            <button stop-propagation mat-icon-button (click)="downloadReport(element.uuid);" *ngIf="(element.status.name == 'signed' && element.documentType == 'remote' )">
              <div class="tooltip3">
                <mat-icon>find_in_page</mat-icon>
                <span class="tooltiptext3">Evidencias</span>
              </div>
            </button>

            <button stop-propagation mat-icon-button (click)="downloadVidForms(element.uuid);" *ngIf="(element.status.name == 'signed' && element.signers[0].vidForms.length > 0)">
              <div class="tooltip3">
                <mat-icon>playlist_add_check</mat-icon>
                <span class="tooltiptext3">Formularios</span>
              </div>
            </button>

            <button stop-propagation mat-icon-button (click)="resignVidForms(element);" *ngIf="(element.status.name == 'expired')">
              <div class="tooltip3">
                <mat-icon>autorenew</mat-icon>
                <span class="tooltiptext3">Reenviar</span>
              </div>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable" (click)="navigateDocument(row.uuid)"></tr>
      </table>
    </div>
  </div>
</div>
