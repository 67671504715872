<div class="wrapper">

  <div class="cuerpo">
    <h1 class="tp-navandu line-navandu">GENERACIÓN DE DOCUMENTO</h1>
    <form name="entrar" [formGroup]="documentForm" (ngSubmit)="onSubmit()" class="formulario" autocomplete="off">
      <div class="row documento">

        <div class="switch-button">
          <label class="varios-firmantes">¿Enviar a varios firmantes?</label>
          <input type="checkbox" name="switch-button" id="switch-label" class="switch-button__checkbox"
            (click)="checkSeveralSigner($event)">
          <label for="switch-label" class="switch-button__label"></label>
          <label class="varios-firmantes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;¿Enviar copia documento firmado (destinatario)?</label>
          <input type="checkbox" name="switch-button" id="switch-label2" class="switch-button__checkbox"
            (click)="checkSendEmail($event)">
          <label for="switch-label2" class="switch-button__label"></label>
          <label class="varios-firmantes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;¿Enviar alerta documento firmado (usuario)?</label>
          <input type="checkbox" name="switch-button" id="switch-label4" class="switch-button__checkbox"
          (click)="checkSendEmailToCreator($event)" checked="true">
        <label for="switch-label4" class="switch-button__label"></label>
          <div class="tooltip4">
            <label class="varios-firmantes"
              *ngIf="!severalSigners && countSigners > 0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;¿Enviar firmas
              ordenadas?</label>
            <input type="checkbox" name="switch-button" id="switch-label3" class="switch-button__checkbox"
              (click)="checkOrderedSignatures($event)" *ngIf="!severalSigners && countSigners > 0" checked="true">
            <label for="switch-label3" class="switch-button__label" *ngIf="!severalSigners && countSigners > 0"></label>
            <span class="tooltiptext4">Firmas ordenadas por el orden de destinatarios o poder firmar libremente</span>
          </div>
        </div>

        <div class="listado-firmantes" [hidden]="!severalSigners">
          <h2>LISTADO DE FIRMANTES PARA ENVIAR DOCUMENTO</h2>
          <div class="input-field col s12 buscador">

            <input id="filter" type="text" (keyup)="applyFilter($event)">
            <label for="filter" data-error="Ingrese un dato valido" data-success=""> <i
                class="material-icons">search</i></label>
          </div>

          <table mat-table [dataSource]="dataSource" class="tabla">

            <!-- check Column -->
            <ng-container matColumnDef="check">
              <th mat-header-cell *matHeaderCellDef> Acción </th>
              <td mat-cell *matCellDef="let element">
                <input type="checkbox" name="switch-button" id="switch-label" class="switch-button__checkbox"
                  (click)="checkSignser($event, element)" [checked]="element.checked">
              </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Nombre</th>
              <td mat-cell *matCellDef="let element"> {{element.name + " " + element.lastName}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="idn">
              <th mat-header-cell *matHeaderCellDef> Documento </th>
              <td mat-cell *matCellDef="let element"> {{element.idn}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Correo </th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Teléfono </th>
              <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

        </div>

        <div class="info-destinatario" *ngIf="!severalSigners">
          <h2 class="tp-navandu"> INFORMACIÓN DEL DESTINATARIO </h2>
          <div class="flex-row">
            <mat-form-field class="example-full-width">
              <mat-label>Número de documento</mat-label>
              <input type="text" matInput formControlName="addressee_document" required [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.idn">
                  {{option.idn}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Tipo de documento</mat-label>
              <mat-select required formControlName="addressee_document_type">
                <mat-option value="Alias">Alias</mat-option>
                <mat-option value="DNI">DNI</mat-option>
                <mat-option value="NIF">NIF</mat-option>
                <mat-option value="NIE">NIE</mat-option>
                <mat-option value="CIF">CIF</mat-option>
                <mat-option value="PASS">PASS</mat-option>
                <mat-option value="RUC">RUC</mat-option>
                <mat-option value="Cedula">Cedula</mat-option>
                <mat-option value="Otro">Otro</mat-option>
              </mat-select>
              <mat-error *ngIf="documentForm.controls.addressee_document_type.hasError('required')">
                Tipo de documento <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex-row">
            <mat-form-field class="example-full-width">
              <mat-label>Nombre</mat-label>
              <input type="text" matInput formControlName="addressee_name" required [matAutocomplete]="autoName">
              <mat-autocomplete #autoName="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsName | async" [value]="option.name">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Apellidos</mat-label>
              <input type="text" matInput formControlName="addressee_last_name" required  [matAutocomplete]="autoLastName">
              <mat-autocomplete #autoLastName="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsLastName | async" [value]="option.lastName">
                  {{option.lastName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="documentForm.controls.addressee_last_name.hasError('required')">
                Apellido <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex-row filatres">
            <div class="phone-group">
              <mat-form-field class="prefix-section">
                <mat-select required formControlName="addressee_country">
                  <mat-option *ngFor="let phonePrefix of phonePrefixes" [value]="phonePrefix.prefix">
                    {{phonePrefix.code}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="phone">
                <mat-label>Móvil para sms de firma</mat-label>
                <input matInput formControlName="addressee_phone"
                  [required]="documentForm.controls.addressee_phone.hasError('required')">
                <mat-error *ngIf="documentForm.controls.addressee_phone.hasError('required')">
                  Móvil <strong>requerido</strong>
                </mat-error>
                <mat-error
                  *ngIf="documentForm.controls.addressee_phone.hasError('minlength') || documentForm.controls.addressee_phone.hasError('maxlength')">
                  Móvil <strong>debe tener 9 caracteres</strong>
                </mat-error>
                <mat-error *ngIf="documentForm.controls.addressee_phone.hasError('pattern')">
                  Móvil <strong>debe ser numérico</strong>
                </mat-error>
                <mat-error *ngIf="documentForm.controls.addressee_phone.hasError('invalidNumber')">
                  Móvil <strong>no valido</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <mat-form-field class="email">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Ex. pat@example.com" formControlName="addressee_mail"
                [required]="documentForm.controls.addressee_mail.hasError('required')">
              <mat-error
                *ngIf="documentForm.controls.addressee_mail.hasError('email') && !documentForm.controls.addressee_mail.hasError('required')">
                Por favor ingrese un correo válido
              </mat-error>
              <mat-error *ngIf="documentForm.controls.addressee_mail.hasError('required')">
                Correo <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex-row">
            <mat-form-field>
              <mat-label>Nº Expediente</mat-label>
              <input matInput formControlName="expedient">
            </mat-form-field>
          </div>
          <div class="tooltip2 iconomas">
            <a class="tp-navandu" (click)="addSigner()">
              <mat-icon>add_circle</mat-icon>
            </a>
            <span class="tooltiptext2">Añadir firmante</span>
          </div>
        </div>
      </div>

      <div formArrayName="signers"
        *ngFor="let signer of getSigners()['controls']; let j = index; let last = last; let first = first"
        class="info-destinatario">
        <div class="row documento">
          <div [formGroupName]="j">
            <h2 *ngIf="documentForm.controls.addresseeAsSigner.value; else notSigner" class="tp-navandu"> INFORMACIÓN
              DEL FIRMANTE {{j + 2}}</h2>
            <ng-template #notSigner>
              <h2 class="tp-navandu"> INFORMACIÓN DEL FIRMANTE {{j + 1}}</h2>
            </ng-template>
            <div class="flex-row">
              <mat-form-field class="example-full-width">
                <mat-label>Número de documento</mat-label>
                <input type="text" matInput formControlName="signer_document" required [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filterOptionsSigners[j] | async" [value]="option.idn">
                    {{option.idn}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Tipo de documento</mat-label>
                <mat-select required formControlName="signer_documet_type">
                  <mat-option value="Alias">Alias</mat-option>
                  <mat-option value="DNI">DNI</mat-option>
                  <mat-option value="NIF">NIF</mat-option>
                  <mat-option value="NIE">NIE</mat-option>
                  <mat-option value="CIF">CIF</mat-option>
                  <mat-option value="PASS">PASS</mat-option>
                  <mat-option value="RUC">RUC</mat-option>
                  <mat-option value="Cedula">Cedula</mat-option>
                  <mat-option value="Otro">Otro</mat-option>
                </mat-select>
                <mat-error *ngIf="signer.controls.signer_documet_type.hasError('required')">
                  Tipo de documento <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="flex-row">
              <mat-form-field>
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="signer_name" required>
                <mat-error *ngIf="signer.controls.signer_name.hasError('required')">
                  Nombre <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Apellidos</mat-label>
                <input matInput formControlName="signer_last_name" required>
                <mat-error *ngIf="signer.controls.signer_last_name.hasError('required')">
                  Apellido <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex-row filatres">
              <div class="phone-group">
                <mat-form-field class="prefix-section">
                  <mat-select required formControlName="addressee_country">
                    <mat-option *ngFor="let phonePrefix of phonePrefixes" [value]="phonePrefix.prefix">
                      {{phonePrefix.code}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="phone">
                  <mat-label>Móvil para sms de firma</mat-label>
                  <input matInput formControlName="signer_phone"
                    [required]="signer.controls.signer_phone.hasError('required')">
                  <mat-error *ngIf="signer.controls.signer_phone.hasError('required')">
                    Móvil <strong>requerido</strong>
                  </mat-error>
                  <mat-error
                    *ngIf="signer.controls.signer_phone.hasError('minlength') || signer.controls.signer_phone.hasError('maxlength')">
                    Móvil <strong>debe tener 9 caracteres</strong>
                  </mat-error>
                  <mat-error *ngIf="signer.controls.signer_phone.hasError('pattern')">
                    Móvil <strong>debe ser numérico</strong>
                  </mat-error>
                  <mat-error *ngIf="signer.controls.signer_phone.hasError('invalidNumber')">
                    Móvil <strong>no valido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-form-field class="email">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Ex. pat@example.com" formControlName="signer_mail"
                  [required]="signer.controls.signer_mail.hasError('required')">
                <mat-error
                  *ngIf="signer.controls.signer_mail.hasError('email') && !signer.controls.signer_mail.hasError('required')">
                  Por favor ingrese un correo válido
                </mat-error>
                <mat-error *ngIf="signer.controls.signer_mail.hasError('required')">
                  Correo <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="masmenos">
            <div class="tooltip2 iconomenos">
              <a class="tp-navandu" (click)="removeSigner(j)">
                <mat-icon>remove_circle</mat-icon>
              </a>
              <span class="tooltiptext2">Quitar firmante</span>
            </div>
            <div>
              <div class="tooltip2 iconomas">
                <a class="tp-navandu" (click)="addSigner()">
                  <mat-icon>add_circle</mat-icon>
                </a>
                <span class="tooltiptext2">Añadir firmante</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row documento">
        <div class="info-destinatario">
          <div class="flex-row">
            <mat-form-field>
              <mat-label>Tipo de firma</mat-label>
              <mat-select required formControlName="signatureType" (selectionChange)="select($event.value)">
                <mat-option *ngFor="let chanel of signatureChanels" [value]="chanel.id">
                  {{chanel.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="documentForm.controls.signatureType.hasError('required')">
                Tipo de firma <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="documentForm.controls['signatureType'].value == '2'">
              <mat-label>Dispositivo</mat-label>
              <mat-select formControlName="device" required>
                <mat-option *ngFor="let device of devices" [value]="device.deviceName">
                  {{device.deviceDescription}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="documentForm.controls.device.hasError('required')">
                Dispositivo <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="documento sign">
        <mat-form-field>
          <div>
            <mat-toolbar>
              <input matInput [(ngModel)]="fileAttr" readonly name="name" [ngModelOptions]="{standalone: true}" />
              <input type="button" mat-flat-button color="primary" class="boton-up" value="Subir archivo">
            </mat-toolbar>

            <input type="file" #fileInput id="uploadFile" (change)="converter($event)" name="uploadFile"
              accept="application/pdf" />
            <div *ngIf="reader.readyState == 1">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </div>
        </mat-form-field>

        <app-spinner *ngIf="doneImageConvert=='loading'"></app-spinner>
        <div *ngIf="doneImageConvert == 'done'">
          <div class="select-state" *ngIf="!loading">
            <button mat-icon-button class="refrescar" (click)="changePage('previus')" type="button">
              <div class="tooltip3">
                <mat-icon>chevron_left</mat-icon>
                <span class="tooltiptext3">Anterior</span>
              </div>
            </button>
            <p>Página: {{pageImagePdf +1}} de {{lengthPageImagePdf}}</p>
            <button mat-icon-button class="refrescar" (click)="changePage('next')" type="button">
              <div class="tooltip3">
                <mat-icon>chevron_right</mat-icon>
                <span class="tooltiptext3">Siguiente</span>
              </div>
            </button>
          </div>
          <div class="example-boundary" [style.backgroundImage]="'url('+ imagepdf +')'">
            <div class="element example-box" cdkDragBoundary=".example-boundary" cdkDrag
              (cdkDragEnded)="dragEnd($event, 0)" id="coordinate" (click)="displayContextMenu($event, 0)"
              [cdkDragDisabled]="isDisplayContextMenu[0].locked">
              Firma 1
              <app-context-menu *ngIf="isDisplayContextMenu[0].visible" [ngStyle]="getRightClickMenuStyle()"
                [contextMenuItems]="rightClickMenuItems" (onContextMenuItemClick)="handleMenuItemClick($event, 0)">
              </app-context-menu>
            </div>
            <div *ngFor="let firmante of arraySigners; let i = index">
              <div class="element example-box" cdkDragBoundary=".example-boundary" cdkDrag [cdkDragFreeDragPosition]="firmante"
                (cdkDragEnded)="dragEnd($event, i+1)" id="coordinate[{{i}}]" (click)="displayContextMenu($event, i+1)"
                [cdkDragDisabled]="isDisplayContextMenu[i+1].locked">Firma {{i +2}}
                <app-context-menu *ngIf="isDisplayContextMenu[i+1].visible" [ngStyle]="getRightClickMenuStyle()"
                  [contextMenuItems]="rightClickMenuItems" (onContextMenuItemClick)="handleMenuItemClick($event, i+1)">
                </app-context-menu>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="enviar">
        <input type="submit" class="boton" value="Enviar" [disabled]="buttonDisable">
      </div>

      <a routerLink="/templates" class="volver"><span>Volver</span> </a>
    </form>
  </div>
</div>