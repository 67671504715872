import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs'

import { environment } from '../../environments/environment';
import { Document } from 'src/app/models/document.model'
import { DocumentBatch } from 'src/app/models/documentBatch.model'
import { DocumentSigned } from 'src/app/models/documentSigned.model'
import { PageDocument } from 'src/app/models/pageDocument.model'
import { DocumentInfo } from 'src/app/models/documentInfo.model'
import { Pageable } from 'src/app/models/pageable.model'
import { DocumentImage } from 'src/app/models/documentImage.model'

@Injectable({
  providedIn: 'root'
})
export class ApiDocumentsService {

  private basePath = environment.uriApiAzure;

  constructor(private http: HttpClient) { }

  postDocument(document: Document): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      })
    return this.http.post(this.basePath + '/document', JSON.stringify(document), { headers: headers, observe: 'response' });
  }

  postDocumentInProcess(id, data): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      })
    return this.http.post(this.basePath + '/process/'+id+'/document', JSON.stringify(data), { headers: headers, observe: 'response' });
  }

  postDocumentInProcessResign(id, data): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      })
    return this.http.post(this.basePath + '/document/'+id+'/resend', JSON.stringify(data), { headers: headers, observe: 'response' });
  }

  postDocumentBatch(documentBatch: DocumentBatch): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      })
    return this.http.post(this.basePath + '/batch', JSON.stringify(documentBatch), { headers: headers, observe: 'response' });
  }

  postDocumentBatchFile(documentBatch: DocumentBatch): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      })
    return this.http.post(this.basePath + '/batch/file', JSON.stringify(documentBatch), { headers: headers, observe: 'response' });
  }

  public getDocuments(): Observable<PageDocument[]> {   //PageDocument
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<PageDocument[]>(this.basePath + '/documents', httpOptions);
  }

  public getDocumentsFilters(filter: string, pageable: Pageable): Observable<PageDocument[]> {
    let url = ""
    url = url + (pageable.pageNumber != 0 ? "&page=" + pageable.pageNumber : "")
    url = url + (pageable.pageSize != null ? "&size=" + pageable.pageSize : "")
    url = url + (pageable.order != null ? "&order=" + pageable.order : "")
    url = url + (pageable.direction != null ? "&direction=" + pageable.direction : "")
    url = url + (filter != null ? "&filter="+filter:"")

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<PageDocument[]>(this.basePath + '/documents?' + url, httpOptions);
  }

  public getDocumentsbyStatus(statusId): Observable<PageDocument[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<PageDocument[]>(this.basePath + '/documents/status/' + statusId, httpOptions);
  }

  public getDocumentsbyStatusFilters(statusId, filter: string, pageable: Pageable): Observable<PageDocument[]> {
    let url = ""
    url = url + (pageable.pageNumber != 0 ? "&page=" + pageable.pageNumber : "")
    url = url + (pageable.pageSize != null ? "&size=" + pageable.pageSize : "")
    url = url + (pageable.order != null ? "&order=" + pageable.order : "")
    url = url + (pageable.direction != null ? "&direction=" + pageable.direction : "")
    url = url + (filter != null ? "&filter="+filter:"")
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<PageDocument[]>(this.basePath + '/documents/status/' + statusId + "?" + url, httpOptions);
  }

  public getDocument(id): Observable<HttpResponse<DocumentSigned>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.get<DocumentSigned>(this.basePath + '/document/' + id, { headers: headers, observe: 'response' })
  }

  public getDocumentInfo(id): Observable<HttpResponse<DocumentInfo>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.get<DocumentInfo>(this.basePath + '/document/' + id + "/info", { headers: headers, observe: 'response' })
  }

  public getDocumentReport(id): Observable<HttpResponse<DocumentSigned>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.get<DocumentSigned>(this.basePath + '/document/report/' + id, { headers: headers, observe: 'response' })
  }

  public getDocumentVidForms(id): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'text/csv',
      'Authorization': localStorage.getItem("token")
    });
    return this.http.get(this.basePath + '/document/' + id + '/vidforms', { headers: headers, responseType: 'text', observe: 'response' });
  }


  postDocumentConverter(base64): Observable<HttpResponse<[DocumentImage]>> {
    let headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      })
    return this.http.post<[DocumentImage]>(this.basePath + '/converter', JSON.stringify(base64), { headers: headers, observe: 'response' });
  }

  postDocumentConverterUUID(uuid: string): Observable<HttpResponse<[DocumentImage]>> {
    let headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      })
    return this.http.post<[DocumentImage]>(this.basePath + '/converter/' + uuid, null, { headers: headers, observe: 'response' });
  }

}
