import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';

import { Stats } from 'src/app/models/stats.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiStatsService {

  private basePath = environment.uriApiAzure;

  constructor(private http: HttpClient) {}

  public getStats(): Observable<Stats> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Stats>(this.basePath + '/stats', httpOptions);
  }
}
