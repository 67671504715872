import { CdkDragEnd } from "@angular/cdk/drag-drop";
import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentImage } from 'src/app/models/documentImage.model';
import { DocumentTypeModel } from 'src/app/models/documentType.model';
import { Template } from 'src/app/models/template.model';
import { ApiTemplatesService } from 'src/app/service/api-templates.service';
import { ApiDocumentsService } from '../../service/api-documents.service';
import { UsersService } from '../../service/users.service';
import { VariableEntity } from '../../entity/variable.entity';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { SignerEntity } from '../../entity/signer.entity';
import { CoordinatesEntity } from '../../entity/coordinates.entity';
import { ContextMenuModel } from '../../interfaces/context-menu-model';
export interface Device {
  deviceDescription: string,
  deviceName: number
}

@Component({
  selector: 'app-resenddocument',
  templateUrl: './resendDocument.component.html',
  styleUrls: ['./resenddocument.component.css']
})

export class ResendDocumentComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Elija un archivo';

  loading: boolean = true;
  documentForm:FormGroup = new FormGroup({
    signatureType: new FormControl<number>(1, [
      Validators.required,

    ]),
    emailRemote: new FormControl('', []),
    device: new FormControl('', []),
    coordinates: new FormArray([]),
  });

  emailOptions: string[] = [];
  idTemplate: string;
  idDocumento: string;
  documento;
  buttonDisable = false;
  template: Template = new Template();
  devices: Device[];
  signatureChanels: DocumentTypeModel[];
  signer: [VariableEntity] = [new VariableEntity()];
  showSignatureChanels: Boolean = true;
  imageConverter: [DocumentImage];
  doneImageConvert;
  imagepdf: string;
  countSigners: number = 0;
  countCoordindates = 0;
  countSignersForms = 0;
  pageImagePdf: number = 0
  lengthPageImagePdf: number = 0;
  coordinatePosX = 0;
  coordinatePosY = 0;
  coordinatePage = 0;
  validFile = false;
  img = new Image;
  arraySigners:Array<{'x': number, 'y': number}> = [];
  isDisplayContextMenu: Array<{'visible': boolean, 'locked': boolean}> = [];
  indexDisplayContextMenu = 0;
  rightClickMenuItems: Array<ContextMenuModel> = [];

  constructor(
    private formBuilder: FormBuilder,
    private templateService: ApiTemplatesService,
    private documentsService: ApiDocumentsService,
    private userService: UsersService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private _snackBar: MatSnackBar) {
    this.activatedRouter.params.subscribe(parametros => {
      this.idDocumento = parametros['idDocumento'];
    });
  }

  ngOnInit(): void {
    for (let i = 0; i < 30; i++) {
      this.isDisplayContextMenu.push({visible: false, locked: false});
    }
    this.loading = true;
    this.img.width = 1240;
    this.img.height = 1754;

    this.documentsService.getDocument(this.idDocumento).subscribe(document => {
      this.documento = document.body;
      this.converter(this.idDocumento);
      this.loading = false;
      this.countSigners = this.documento.signers.length;

      this.documento.signers.forEach((signer, i) => {
        if (i != 0) {
          this.addCoordinates(this.countSigners);
          this.arraySigners.push({'x': 78*(this.countSigners%5), 'y': 30*Math.floor(this.countSigners/5)});
        }
        if (!this.emailOptions.includes(signer.email)) {
          this.emailOptions.push(signer.email);
        }
      });

      this.breadcrumbService.setElements([
        { name: 'Documentos', link: '/documents' },
        { name: this.documento.templateName, link: `/documents/resend/${this.idDocumento}` }
      ]);

      //Obtiene canales de firmas disponibles para el usuario logueado
      this.userService.getUser().subscribe(dataUser => {
        if (dataUser != null) {
          switch (this.documento.template.templateType.name) {
            case 'SetSigner':
              this.signatureChanels = [{ id: 3, name: "remote", active: true }];
              this.documentForm.controls.signatureType.setValue(3);
              break;
            case 'SetDown':
              this.signatureChanels = [{ id: 3, name: "remote", active: true }];
              this.documentForm.controls.signatureType.setValue(3);
              break;
            case 'SetUp':
              this.signatureChanels = [{ id: 1, name: "analog", active: true }];
              this.documentForm.controls.signatureType.setValue(1);
              this.showSignatureChanels = false;
              break;
            default:
              this.signatureChanels = dataUser.documentTypesDtos;
              break;
          }
          this.signatureChanels = this.signatureChanels.filter(chanel => chanel.name == this.documento.documentType);
        }
      }, error => this.onError(error));
  });
    //Obtiene dispositivos asociados a un usuario
    this.templateService.getDevices().subscribe(dataDevices => {
      if (dataDevices != null) {
        this.devices = dataDevices;
      }
    }, error => this.onError(error));

  }

  addCoordinates(index: number) {
    let coordinatesArray = this.documentForm.controls.coordinates as FormArray;
    let coordinatesForm = this.buildCoordinates(index);
    this.countCoordindates++
    coordinatesArray.push(coordinatesForm);
  }

  buildCoordinates(index: number) {
    let y = 30 * index;
    y *= (297 / 585)
    return this.formBuilder.group({
      id: '0',
      page: '',
      posX: 0,
      posY: y
    });
  }

  converter(uuid: string) {
    this.documentsService.postDocumentConverterUUID(uuid).subscribe(dataConverter => {
      if (dataConverter.status == 200) {
        this.imageConverter = dataConverter.body;
        this.img.src = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.lengthPageImagePdf = this.imageConverter.length
        this.doneImageConvert = "done"
        this.validFile = true;
      }
    }, error => {
      this.buttonDisable = false;
      this.doneImageConvert = "done"
      this.onError(error);
    });
  }

  changePage(direction) {
    if (direction == "next") {
      if (this.pageImagePdf < this.lengthPageImagePdf - 1) {
        this.pageImagePdf = this.pageImagePdf + 1
        this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.img.src = this.imagepdf;
      }
    }
    else {
      if (this.pageImagePdf != 0) {
        this.pageImagePdf = this.pageImagePdf - 1
        this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.img.src = this.imagepdf;
      }
    }
  }

  onSubmit() {
    let documentSignerDTOs = {
      "device": this.documentForm.controls['device'].value,
      "uuid": "",
      "idn": "",
      "typeOfIdn": "",
      "name": "",
      "email": this.documentForm.controls['emailRemote'].value,
      "phone": "",
      "externalUrl": "",
      "forms": null,
      "vidForms": null,
      "documentType": this.documentForm.controls['signatureType'].value,
    }

    if (this.documento.template.templateType.name == 'File' || this.documento.template.templateType.name == 'SetFileAdmin') {
      if (this.img.height > 297) {
        this.coordinatePosY = this.coordinatePosY
      }

      documentSignerDTOs["coordinates"] = this.processCoordinates()
    }

    this.resignVidForms(this.documento.uuid, documentSignerDTOs);

  }

  processCoordinates() {
    let coordinatesArray = [new CoordinatesEntity()];
    let coordinate: CoordinatesEntity = new CoordinatesEntity();
    coordinate.posX = this.coordinatePosX < 0 ? 0 : this.coordinatePosX * (210 / 413);
    coordinate.posY = this.coordinatePosY < 0 ? 0 : this.coordinatePosY * (297 / 585);
    coordinate.page = this.coordinatePage == 0 ? this.coordinatePage + 1 : this.coordinatePage;
    coordinatesArray.push(coordinate);
    this.documentForm.get('coordinates').value.forEach((element, index) => {
      let coordinate: CoordinatesEntity = new CoordinatesEntity();
      coordinate.posX = element.posX < 0 ? 0 : element.posX * (210 / 413);
      coordinate.posY = element.posY < 0 ? 0 : element.posY * (297 / 585);
      coordinate.page = element.page == "" ? this.coordinatePage + 1 : element.page;
      coordinatesArray.push(coordinate);
    });
    coordinatesArray.splice(0, 1)

    return coordinatesArray;
  }

  resignVidForms(documentUUID, dataToSend) {
    //Envía documento a firmar
    this.showMsgSnack('Documento reenviado');
    this.documentsService.postDocumentInProcessResign(documentUUID, dataToSend).subscribe(dataDService => {
      if (dataDService.status == 200) {
        this.router.navigate(['/documents']);
      }
    }, error => {
      this.onError(error);
    });
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error.');
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  dragEnd(event: CdkDragEnd, index: number) {
    if(index == 0){
      this.coordinatePosX = event.source.getFreeDragPosition()['x']
      this.coordinatePosY = event.source.getFreeDragPosition()['y']
    }else{
      this.documentForm.get('coordinates').value[index-1].posX = event.source.getFreeDragPosition()['x'];
      this.documentForm.get('coordinates').value[index-1].posY = event.source.getFreeDragPosition()['y'];
    }
  }

  displayContextMenu(event, index) {
    if(this.indexDisplayContextMenu != index){
      this.isDisplayContextMenu[this.indexDisplayContextMenu].visible =  false;
    }
    this.isDisplayContextMenu[index].visible =  true;
    this.indexDisplayContextMenu = index;

    this.rightClickMenuItems = [
      {
        menuText: 'Fijar',
        menuEvent: 'Handle refactor',
      },
      {
        menuText: 'Mover',
        menuEvent: 'Handle format',
      },
    ];
  }

  getRightClickMenuStyle() {
    return {
      position: 'absolute',
      top: `15px`,
      left: `58px`
    }
  }

  handleMenuItemClick(event, data) {

    switch (event.data) {
      case this.rightClickMenuItems[0].menuEvent:
        this.isDisplayContextMenu[data].locked = true
        if(data == 0){
          this.coordinatePage = this.pageImagePdf + 1;
        }else{
          this.documentForm.get('coordinates').value[data-1].page = this.pageImagePdf + 1;
        }
           break;
      case this.rightClickMenuItems[1].menuEvent:
        this.isDisplayContextMenu[data].locked = false
        break;
    }
  }

  @HostListener('click', ['$event.target']) onClick(e) {
    if(!e['className'].includes('cdk-drag element example-box')){
      this.isDisplayContextMenu[this.indexDisplayContextMenu].visible = false;
    }
  }

}
