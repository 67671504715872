<div class="wrapper">
  <div class="cuerpo">

    <h1 class="tp-navandu line-navandu">CONSUMO</h1>

    <app-spinner *ngIf="loading"></app-spinner>

    <div *ngIf="!loading">
      <div class="box">
        <span class="label">Numero de documentos:</span>
        <span>{{stats.numDocuments}}</span>
      </div>

      <div class="box">
        <h3>Documentos por estado:</h3>
        <div>
          <span class="label">Pendiente:</span>
          <span>{{stats.status.pending}}</span>
        </div>
        <div>
          <span class="label">Firmado:</span>
          <span>{{stats.status.signed}}</span>
        </div>
        <div>
          <span class="label">Rechazado:</span>
          <span>{{stats.status.rejected}}</span>
        </div>
        <div>
          <span class="label">Generando:</span>
          <span>{{stats.status.generating}}</span>
        </div>
        <div>
          <span class="label">Generando:</span>
          <span>{{stats.status.asigned}}</span>
        </div>
        <div>
          <span class="label">Asignado:</span>
          <span>{{stats.status.assigned}}</span>
        </div>
        <div>
          <span class="label">Error:</span>
          <span>{{stats.status.error}}</span>
        </div>
        <div>
          <span class="label">Expirado:</span>
          <span>{{stats.status.expired}}</span>
        </div>
      </div>

      <div class="box">
        <h3>Documentos por tipo:</h3>
        <div>
          <span class="label">Analógico:</span>
          <span>{{stats.type.analog}}</span>
        </div>
        <div>
          <span class="label">Biométrico:</span>
          <span>{{stats.type.biometric}}</span>
        </div>
        <div>
          <span class="label">Remote:</span>
          <span>{{stats.type.remote}}</span>
        </div>
      </div>

      <div class="box">
        <span class="label">Numero de plantillas:</span>
        <span>{{stats.numTemplates}}</span>
      </div>

      <div class="box">
        <h3>Documentos por plantilla:</h3>
        <div *ngFor="let doc of stats.documentsByTemplate">
          <span class="label">{{doc.name}}:</span>
          <span>{{doc.total}}</span>
        </div>
      </div>

    </div>
  </div>
</div>
