<div class="wrapper">

  <div class="cuerpo">

    <h1 class="tp-navandu line-navandu">INICIAR PROCESO</h1>
    <div class="input-field col s12 buscador">
      <input id="filter" type="text" (keyup)="applyFilter($event)">
      <label for="filter" data-error="Ingrese un dato valido" data-success="">
        <i class="material-icons">search</i>
      </label>
    </div>

    <app-spinner *ngIf="loading"></app-spinner>

    <table mat-table [dataSource]="dataSource" class="tabla tabla-inicio-procesos" *ngIf="!loading">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Descripción </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <ng-container matColumnDef="templates">
        <th mat-header-cell *matHeaderCellDef> Plantilllas Asociadas </th>
        <td mat-cell *matCellDef="let element">
          <p *ngFor="let template of element.templates">{{template.name}}</p>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Acción </th>
        <td mat-cell *matCellDef="let element">
          <button (click)="view(element.uuid)" mat-icon-button
            aria-label="Example icon button with a vertical three dot icon">
            <div class="tooltip3">
              <mat-icon>send</mat-icon>
              <span class="tooltiptext3">Iniciar proceso</span>
            </div>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

  <div class="ver-firmante" *ngIf="viewForm">
    <form name="entrar" class="formulario form-gen-doc" autocomplete="off" [formGroup]="processConfigForm"
      (ngSubmit)="onSubmit()">

      <div class="info-destinatario">
        <h2 class="tp-navandu"> INFORMACIÓN DEL DESTINATARIO </h2>
        <div class="flex-row">
          <mat-form-field class="example-full-width">
            <mat-label>Número de documento</mat-label>
            <input type="text" matInput formControlName="addressee_document" required [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.idn">
                {{option.idn}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tipo de documento</mat-label>
            <mat-select required formControlName="addressee_document_type">
              <mat-option value="Alias">Alias</mat-option>
              <mat-option value="DNI">DNI</mat-option>
              <mat-option value="NIF">NIF</mat-option>
              <mat-option value="NIE">NIE</mat-option>
              <mat-option value="CIF">CIF</mat-option>
              <mat-option value="PASS">PASS</mat-option>
              <mat-option value="RUC">RUC</mat-option>
              <mat-option value="Cedula">Cedula</mat-option>
              <mat-option value="Otro">Otro</mat-option>
            </mat-select>
            <mat-error *ngIf="processConfigForm.controls.addressee_document_type.hasError('required')">
              Tipo de documento <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-form-field class="example-full-width">
            <mat-label>Nombre</mat-label>
            <input type="text" matInput formControlName="addressee_name" required [matAutocomplete]="autoName">
            <mat-error *ngIf="processConfigForm.controls.addressee_name.hasError('required')">
              Nombre <strong>requerido</strong>
            </mat-error>
            <mat-autocomplete #autoName="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptionsName | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Apellido</mat-label>
            <input type="text" matInput formControlName="addressee_last_name" required [matAutocomplete]="autoLastName">
            <mat-error *ngIf="processConfigForm.controls.addressee_last_name.hasError('required')">
              Apellido <strong>requerido</strong>
            </mat-error>
            <mat-autocomplete #autoLastName="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptionsLastName | async" [value]="option.lastName">
                {{option.lastName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="flex-row filatres">
          <div class="phone-group">
            <mat-form-field class="prefix-section">
              <mat-select required formControlName="addressee_country">
                <mat-option *ngFor="let phonePrefix of phonePrefixes" [value]="phonePrefix.prefix">
                  {{phonePrefix.code}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="phone">
              <mat-label>Móvil para sms de firma</mat-label>
              <input matInput formControlName="addressee_phone"
                [required]="processConfigForm.controls.addressee_phone.hasError('required')">
              <mat-error *ngIf="processConfigForm.controls.addressee_phone.hasError('required')">
                Móvil <strong>requerido</strong>
              </mat-error>
              <mat-error
                *ngIf="processConfigForm.controls.addressee_phone.hasError('minlength') || processConfigForm.controls.addressee_phone.hasError('maxlength')">
                Móvil <strong>debe tener 9 caracteres</strong>
              </mat-error>
              <mat-error *ngIf="processConfigForm.controls.addressee_phone.hasError('pattern')">
                Móvil <strong>debe ser numérico</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <mat-form-field class="email">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Ex. pat@example.com" formControlName="addressee_mail"
              [required]="processConfigForm.controls.addressee_mail.hasError('required')">
            <mat-error
              *ngIf="processConfigForm.controls.addressee_mail.hasError('email') && !processConfigForm.controls.addressee_mail.hasError('required')">
              Por favor ingrese un correo válido
            </mat-error>
            <mat-error *ngIf="processConfigForm.controls.addressee_mail.hasError('required')">
              Correo <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>



      <div class="flex-row">
        <mat-form-field class="expediente">
          <mat-label>Nº Expediente</mat-label>
          <input matInput formControlName="expedient" required>
        </mat-form-field>

        <div class="switch-button">
          <label class="varios-firmantes">¿Enviar instrucciones por email?</label>
          <input type="checkbox" name="switch-button" id="switch-label" class="switch-button__checkbox"
            (click)="checkSendEmail($event)" checked>
          <label for="switch-label" class="switch-button__label"></label>
        </div>
      </div>
      <div class="flex-row">
        <mat-form-field *ngIf="optionsCompany.length > 0">
          <mat-label>Empresa</mat-label>
          <mat-select required formControlName="company">
            <mat-option *ngFor="let company of optionsCompany" [value]="company.uuid">
              {{company.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="processConfigForm.controls.company.hasError('required')">
            Empresa <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="enviar2">
        <input type="button" (click)="cancel()" class="boton volver2" value="Cancelar">
        <input type="submit" class="boton volver2" value="Iniciar Proceso" [disabled]="buttonSend">
      </div>

    </form>

  </div>
</div>