<div class="wrapper">

  <div class="ver-detalle" *ngIf="viewRejectReson">
    <div class="datos-detalle">
      <table class="tabla-detalle">
        <tr>
          <th>Firmante</th>
          <th>Mensaje</th>
        </tr>
        <tr *ngFor="let reject of msgRejectReason">
          <td>{{reject.signerName}}</td>
          <td>{{reject.reason}}</td>
        </tr>
      </table>
      <input type="button" class="boton volver2" value="Cancelar" (click)="cancel()">
    </div>
  </div>

  <div class="ver-detalle" *ngIf="viewDocumentInfo">
    <div class="datos-detalle">
      <table class="tabla-detalle">
        <tr>
          <th>Firmante</th>
          <th>Estado</th>
          <th>Fecha</th>
        </tr>
        <tr *ngFor="let signer of documentInformation.signers">
          <td>{{signer.name}}</td>
          <td class="{{signer.status.name}}">{{signer.status.nameEs}}</td>
          <td *ngIf="signer.operationTime!='null'; else empty">{{signer.operationTime}}</td>
          <ng-template #empty>
            <td></td>
          </ng-template>
        </tr>
      </table>
      <input type="button" class="boton volver2" value="Volver" (click)="cancel()">
    </div>
  </div>

  <div class="cuerpo">

    <h1 class="tp-navandu line-navandu">DOCUMENTOS</h1>

    <div class="input-field col s12 buscador">
      <input id="filter" type="text" (keyup)="applyFilter($event.keyCode)" [(ngModel)]="textToSearch">
      <label for="filter" data-error="Ingrese un dato valido" data-success="">
        <button mat-icon-button (click)="search()">
          <mat-icon>search</mat-icon>
        </button>
      </label>
    </div>

    <app-spinner *ngIf="loading"></app-spinner>

    <div *ngIf="!loading">

      <div class="select-state" (change)="filterStatus($event)" *ngIf="!loading">

        <button mat-icon-button class="refrescar" (click)="ngOnInit()">
          <div class="tooltip3">
            <mat-icon>refresh</mat-icon>

            <span class="tooltiptext3">Actualizar</span>
          </div>
        </button>
        <select>
          <option value="0" selected>Todos</option>
          <option value="1" class="pendiente">Pendiente</option>
          <option value="2" class="firmado">Firmado</option>
          <option value="3" class="rechazado">Rechazado</option>
          <option value="4" class="expirado">Expirado</option>
        </select>
      </div>

      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="tabla">
        <ng-container matColumnDef="expedient">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Expediente</th>
          <td mat-cell *matCellDef="let element">
            {{element.expedient}}
          </td>
        </ng-container>

        <ng-container matColumnDef="documentAddressee">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Destinatario </th>
          <td mat-cell *matCellDef="let element" class="{{element.documentType}}">
            {{element.documentAdresseeDTO.fullName}}
          </td>

        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef> Firmante </th>
          <td mat-cell *matCellDef="let element">

            <p *ngFor="let signer of element.signers">{{signer.name}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="template">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
          <td mat-cell *matCellDef="let element" class="{{element.status.nameEs}}"> {{element.templateName}} </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> F. Creación </th>
          <td mat-cell *matCellDef="let element"> {{element.creationDate | date:'dd-MM-yyyy HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="signatureDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> F. Firma </th>
          <td mat-cell *matCellDef="let element"> {{element.signatureDate | date:'dd-MM-yyyy HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef>Acción</th>
          <td mat-cell *matCellDef="let element" class="action">

            <container-element [ngSwitch]="element.status.id">
              <button stop-propagation (click)="downloadDocument(element.uuid);" mat-icon-button *ngSwitchCase="2"
                aria-label="Example icon button with a vertical three dot icon">
                <div class="tooltip3">
                  <mat-icon class="first-icon">visibility</mat-icon>
                  <span class="tooltiptext3">Descargar</span>
                </div>
              </button>

              <container-element *ngSwitchCase="3">
                <button stop-propagation (click)="viewRejectReason(element.rejectReason);" mat-icon-button
                  aria-label="Example icon button with a vertical three dot icon"
                  *ngIf="element.rejectReason != null; else notRejectedReason">
                  <div class="tooltip3">
                    <mat-icon class="only-icon">visibility</mat-icon>
                    <span class="tooltiptext3">Detalle</span>
                  </div>
                </button>
                <ng-template #notRejectedReason>
                  <button stop-propagation (click)="downloadDocument(element.uuid);" mat-icon-button disabled
                    aria-label="Example icon button with a vertical three dot icon">
                    <mat-icon class="only-icon-disabled">visibility</mat-icon>
                  </button>
                </ng-template>
              </container-element>
              <button stop-propagation (click)="documentInfo(element.uuid);" mat-icon-button
                aria-label="Example icon button with a vertical three dot icon" *ngSwitchCase="1">
                <div class="tooltip3">
                  <mat-icon class="only-icon">visibility</mat-icon>
                  <span class="tooltiptext3">Detalle</span>
                </div>
              </button>

              <button stop-propagation mat-icon-button (click)="resignVidForms(element);" *ngIf="(element.status.name == 'expired')">
                <div class="tooltip3">
                  <mat-icon>autorenew</mat-icon>
                  <span class="tooltiptext3">Reenviar</span>
                </div>
              </button>

            </container-element>

            <button stop-propagation mat-icon-button (click)="downloadReport(element.uuid);"
              *ngIf="(element.status.name == 'signed' && element.documentType == 'remote' )">
              <div class="tooltip3">
                <mat-icon>find_in_page</mat-icon>
                <span class="tooltiptext3">Evidencias</span>
              </div>
            </button>

            <button stop-propagation mat-icon-button (click)="downloadVidForms(element.uuid);"
              *ngIf="(element.status.name == 'signed' && element.signers[0].vidForms.length > 0)">
              <div class="tooltip3">
                <mat-icon>playlist_add_check</mat-icon>
                <span class="tooltiptext3">Formularios</span>
              </div>
            </button>

          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable"
          (click)="navigateDocument(row.uuid)"></tr>
      </table>

      <mat-card *ngIf="loadingDataTable" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner 
          color="primary" 
          mode="indeterminate">
        </mat-progress-spinner>
      </mat-card>

      <div class="paginacion">
        <label>Items por página: </label>
        <select class="items" [(ngModel)]="pageable.pageSize" (change)="itemsByPage()">
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>

        <button mat-icon-button disabled={{pageable.first}} (click)="first()">
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <button mat-icon-button disabled={{pageable.first}} (click)="previus()">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button mat-icon-button disabled={{pageable.last}} (click)="next()">
          <mat-icon>navigate_next</mat-icon>
        </button>
        <button mat-icon-button disabled={{pageable.last}} (click)="end()">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>