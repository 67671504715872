import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { SignerEntity } from '../entity/signer.entity';
import { Signer } from '../models/signer.model';
import { DocumentSignerRoleEntity } from '../entity/documentSignerRole.entity';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SignersService {

  private basePath = environment.uriApiAzure;

  constructor(private http: HttpClient) { }

  public getSigner(uuid:string): Observable<Signer> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Signer>(this.basePath + '/signer/' + uuid, httpOptions);
  }

  public getDocumentsCount(): Observable<Number> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Number>(this.basePath + '/documentsCount/', httpOptions);
  }

  public getSigners(): Observable<SignerEntity[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<SignerEntity[]>(this.basePath + '/signers/', httpOptions);
  }

  public getDocumentSignerRole(): Observable<DocumentSignerRoleEntity[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<DocumentSignerRoleEntity[]>(this.basePath + '/documentSignerRole/', httpOptions);
  }

  public putEditSigner(uuid: string, signer: Signer): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.put(this.basePath + '/signer/' + uuid, JSON.stringify(signer), { headers: headers, observe: 'response' });
  }

  public postSigner(signer: Signer): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      })
    return this.http.post(this.basePath + '/signer', JSON.stringify(signer), { headers: headers, observe: 'response' });
  }

  public deleteSigner(uuid: string): Observable<unknown>  {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
      return this.http.delete(this.basePath + '/signer/' + uuid, httpOptions);
  }

}
