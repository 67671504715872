<div class="wrapper">

  <div class="cuerpo">
    <h1 class="tp-navandu line-navandu">PLANTILLAS DISPONIBLES</h1>

    <div class="input-field col s12 buscador">
      <input id="filter" type="text" (keyup)="applyFilter($event)" [(ngModel)]="textToSearch">
      <label for="filter" data-error="Ingrese un dato valido" data-success="">
        <button mat-icon-button (click)="search();">
          <i class="material-icons">search</i>
        </button>
      </label>
    </div>

    <app-spinner *ngIf="loading"></app-spinner>

    <div class="ownDocument" *ngIf="!loading && showOwnDocument">
      <!-- <div class="usuario-activo"> -->
      <div class="labelOwn">
        <span>Enviar a firmar documento propio</span>
      </div>
      <div class="iconOwn">
        <button mat-icon-button class="refrescar" (click)="uploadDocument(uuid)">
          <div class="tooltip4">
            <mat-icon>publish</mat-icon>
            <span class="tooltiptext4">Enviar documento</span>
          </div>
        </button>
      </div>
      <!-- </div> -->
    </div>

    <table mat-table [dataSource]="dataSource" class="tabla" *ngIf="!loading">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Descripción </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Acción </th>
        <td mat-cell *matCellDef="let element">
          <button (click)="view(element.uuid)" mat-icon-button>
            <div class="tooltip3">
              <mat-icon>remove_red_eye</mat-icon>
              <span class="tooltiptext3">Detalles</span>
            </div>
          </button>
          <button (click)="sign(element.uuid)" mat-icon-button>
            <div class="tooltip3">
              <mat-icon>send</mat-icon>
              <span class="tooltiptext3">Generar</span>
            </div>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons>
    </mat-paginator>

  </div>
</div>
