import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

import { GuestsService } from 'src/app/service/guests.service'
import { Document } from 'src/app/models/document.model'
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';

@Component({
  selector: 'app-guest-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class GuestDocumentsComponent implements OnInit {

  displayedColumns: string[] = ['expedient', 'documentAddressee', 'user', 'template', 'creationDate', 'signatureDate', 'view'];
  dataSource = new MatTableDataSource();
  loading: boolean;

  constructor(
    private guestsService: GuestsService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.breadcrumbService.setElements([]);
    this.guestsService.getDocuments().subscribe(data => {
      if (data != null) {
        let ELEMENT_DATA2: Document[] = data["content"];
        this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
        this.loading = false;
      }
    }, error => this.onError(error));
  }

  sign(element) {
    if (element["status"]['id'] == 5) {
      switch (element["templateType"]) {
        case 'SetSigner':
          this.router.navigate([`/guest/document/${element.uuid}/setsigner`]);
          break;
        case "SetDown":
          this.router.navigate(['/guest/document/' + element["uuid"] + '/setdown']);
          break;
        case "SetUp":
          this.router.navigate(['/guest/document/' + element["uuid"] + '/setup']);
          break;
        default:
          let externalUrl = element.signers[0].externalUrl;
          if (externalUrl) {
            window.open(externalUrl, '_blank').focus();
          }
          break;
      }
    } else {
      let externalUrl = element.signers[0].externalUrl;
      if (externalUrl) {
        window.open(externalUrl, '_blank').focus();
      }
    }
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error.');
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }
}
