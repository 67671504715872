import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';

import { SignersService } from 'src/app/service/signers.service'
import { SignerEntity } from 'src/app/entity/signer.entity'
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { Signer } from 'src/app/models/signer.model';
import { PhonePrefix } from 'src/app/models/phonePrefix.model';
import { PhonePrefixService } from 'src/app/service/phone-prefix.service';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-signers',
  templateUrl: './signers.component.html',
  styleUrls: ['./signers.component.css']
})
export class SignersComponent implements OnInit {

  loading: boolean = true;
  displayedColumns: string[] = ['name', 'idn', 'creationDate', 'email', 'phone', 'action'];
  dataSource = new MatTableDataSource();

  id: string;
  viewForm = false;
  newSigner: boolean = false;
  askDelete: boolean = false;
  signToDelete: string;
  phonePrefixes: PhonePrefix[];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  signerForm = new FormGroup({
    typeOfIdn: new FormControl('', [
      Validators.required,
    ]),
    idn: new FormControl('', [
      Validators.required,
    ]),
    name: new FormControl('', [
      Validators.required,
    ]),
    lastName: new FormControl('', [
      Validators.required,
    ]),
    phone: new FormControl('', [
    ]),
    email: new FormControl('', [
    ]),
    email2: new FormControl('', [
    ]),
    countryPhone: new FormControl('+34', [
      Validators.required,
    ])
  });


  constructor(
    private signerService: SignersService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private breadcrumbService: BreadcrumbService,
    private userService: UsersService,
    private phonePrefixService: PhonePrefixService,
  ) { }

  ngOnInit() {
    this.breadcrumbService.setElements([]);
    this.loading = true;
    this.paginator._intl.itemsPerPageLabel = 'Items por página'

    this.signerService.getSigners().subscribe(dataTemplate => {
      if (dataTemplate != null) {
        let ELEMENT_DATA2: SignerEntity[] = dataTemplate;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          this.onError(error)
        }
      }
    );
    this.userService.getUser().subscribe(dataUser => {   // data is of type HttpResponse<Object>
      if (dataUser != null) {
        this.phonePrefixService.getPhonePrefixes().subscribe(dataPhonePrefixes => {
          this.phonePrefixes = dataPhonePrefixes;
          this.signerForm.get('countryPhone').setValue(dataUser.phonePrefix.prefix);
        });
      }
    },
      error => {
        if (error.status == 401) {
        } else {
          var texto = error
        }
      }
    );


    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  sign(id) {
    if (id != "new") {
      this.id = id;
      this.signerService.getSigner(id).subscribe(dataSigner => {   // data is of type HttpResponse<Object>
        if (dataSigner != null) {
          this.signerForm.controls['typeOfIdn'].setValue(dataSigner.typeOfIdn);
          this.signerForm.controls['idn'].setValue(dataSigner.idn);
          this.signerForm.controls['name'].setValue(dataSigner.name);
          this.signerForm.controls['lastName'].setValue(dataSigner.lastName);
          this.signerForm.controls['countryPhone'].setValue(dataSigner.countryPhone);
          this.signerForm.controls['phone'].setValue(dataSigner.phone);
          this.signerForm.controls['email'].setValue(dataSigner.email);
        }
      },
        error => {
          if (error.status == 401) {
            this.router.navigate(['/login']);
          } else {
            this.onError(error)
          }
        }
      )
    } else {
      this.newSigner = true
    }
    this.viewForm = true;
  }

  delete(id) {
    this.askDelete = true;
    this.signToDelete = id;
  }

  beDelete(sure) {
    if (sure) {
      //Eliminar firmante
      this.signerService.deleteSigner(this.signToDelete).subscribe(dataDService => {
        if (dataDService == null) {
          this.ngOnInit();
          this.showMsgSnack('Firmante eliminado correctamente');
          this.askDelete = false;
        }
      },
        error => {
          console.log(error)
          this.showMsgSnack(error['error']['message']);
          this.askDelete = false;
        }
      )
    } else {
      this.askDelete = false;
    }

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  cancel() {
    this.viewForm = false;

  }

  onSubmit() {
    if (this.signerForm.value['phone'] != null || this.signerForm.value['phone'] != "") {
      this.signerForm.get('phone').setValidators([Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      Validators.minLength(9),
      Validators.maxLength(9)]);
    }
    if (this.signerForm.value['email'] != null || this.signerForm.value['email'] != "") {
      this.signerForm.get('email').setValidators([Validators.email]);
    }
    if (this.signerForm.value['email2'] != null || this.signerForm.value['email2'] != "") {
      this.signerForm.get('email2').setValidators([Validators.email]);
    }
    this.signerForm.controls.email.updateValueAndValidity()
    this.signerForm.controls.phone.updateValueAndValidity()
    if (this.signerForm.valid) {
      if (this.newSigner) {
        let dataSigner: Signer =  new Signer(this.signerForm.value);
        //Crea firmante
        this.signerService.postSigner(dataSigner).subscribe(dataDService => {
          if (dataDService.status == 200) {
            this.viewForm = false;
            this.ngOnInit();
            this.showMsgSnack('Firmante creado correctamente');
            this.router.navigate(['/signers']);
          }
        },
          error => {
            this.showMsgSnack(error);
          }
        )
      } else {
        let dataSigner: Signer =  new Signer(this.signerForm.value);
        //Modificar datos del firmante
        this.signerService.putEditSigner(this.id, dataSigner).subscribe(dataDService => {
          if (dataDService.status == 200) {
            this.viewForm = false;
            this.ngOnInit();
            this.showMsgSnack('Datos actualizados correctamente');
            this.router.navigate(['/signers']);
          }
        },
          error => {
            this.showMsgSnack(error);
          }
        )

      }



    } else {
      this.showMsgSnack('Por favor ingrese los datos requeridos');
    }
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error');
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

}
