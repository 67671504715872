import { DocumentTypeModel } from 'src/app/models/documentType.model'
import { PhonePrefix } from './phonePrefix.model';
export class User {
  id: number
  uuid: string;
  name: string;
  businessName: string;
  cif: string;
  nif: string;
  direction: string;
  email: string;
  url: string;
  username: string;
  vid_password: string;
  vid_user: string;
  logo_id: string;
  phone: string;
  emailSender: string;
  emailBody: string;
  urlLogo: string;
  documentTypesDtos: [DocumentTypeModel];
  company: [{ "name": string }];
  phonePrefix: PhonePrefix;

  constructor(formdata: any){
    this.cif = formdata['cif'];
    this.name = formdata['name'];
    this.phone = formdata['phone'];
    this.email = formdata['email'];
    this.emailSender = formdata['emailSender'];
    this.emailBody = formdata['emailBody'];
    this.businessName = formdata['businessName'];
    this.direction = formdata['direction'];
    this.url = formdata['url'];
    this.urlLogo = formdata['urlLogo'];
  }
}
