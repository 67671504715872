import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//PDF
import { PdfViewerModule } from 'ng2-pdf-viewer';

//Componentes material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table'
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

import { SafeHtmlPipe } from './safe-html.pipe';
import { HeaderComponent } from './header/header.component';

// Controllers
import { LoginComponent } from './login/login.component';
import { SignersComponent } from './signers/signers/signers.component';
import { TemplatesComponent } from './templates/templates/templates.component';
import { ViewtemplateComponent } from './templates/viewtemplate/viewtemplate.component';
import { SigntemplateComponent } from './templates/signtemplate/signtemplate.component';
import { EditsignerComponent } from './signers/edit-signer/edit-signer.component';
import { NewsignerComponent } from './signers/new-signer/new-signer.component';
import { ViewSignerComponent } from './signers/view-signer/view-signer.component';
import { DocumentsComponent } from './documents/documents/documents.component';
import { DocumentDetailsComponent } from './documents/details/details.component';
import { GuestLoginComponent } from './guest/login/login.component';
import { GuestDocumentsComponent } from './guest/documents/documents/documents.component';
import { GuestSetSignerComponent } from './guest/documents/setsigner/setsigner.component';
import { ProcessComponent } from './processes/process/process.component';
import { ViewprocessComponent } from './processes/viewprocess/viewprocess.component';
import { ProcessesConfigComponent } from './processes/config/config.component';
import { SigndocumentprocessComponent } from './processes/signdocumentprocess/signdocumentprocess.component';
import { StatsComponent } from './stats/stats.component';
import { GuestSetdownComponent } from './guest/documents/setdown/setdown.component';
import { GuestSetupComponent } from './guest/documents/setup/setup.component';
import { ViewProcessDocumentComponent } from './processes/viewdocument/viewdocument.component';

// Shared
import { StopPropagationDirective } from './shared/stopPropagation.directive';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { SharedDocumentDetailsComponent } from './shared/document/details/details.component';

// Adapters
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './custom-date.adapter';
import { NewpasswordComponent } from './guest/newpassword/newpassword.component';
import { ResetpasswordComponent } from './guest/resetpassword/resetpassword.component';
import { PresentationComponent } from './public/presentation/presentation.component';
import { AccountComponent } from './account/account.component';
import { OwnDocumentComponent } from './templates/own-document/own-document.component';
import { ErrormessagePipe } from './pipe/errormessage.pipe';
import { ContextMenuComponent } from './templates/context-menu/context-menu.component';
import { ResenddocumentprocessComponent } from './processes/resenddocumentprocess/resenddocumentprocess.component';
import { ResendDocumentComponent } from './documents/resenddocumentprocess/resendDocument.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignersComponent,
    TemplatesComponent,
    ViewtemplateComponent,
    SigntemplateComponent,
    EditsignerComponent,
    NewsignerComponent,
    ViewSignerComponent,
    DocumentsComponent,
    ResendDocumentComponent,
    DocumentDetailsComponent,
    GuestLoginComponent,
    GuestDocumentsComponent,
    GuestSetSignerComponent,
    HeaderComponent,
    SafeHtmlPipe,
    ProcessComponent,
    ViewprocessComponent,
    ProcessesConfigComponent,
    SigndocumentprocessComponent,
    ResenddocumentprocessComponent,
    StatsComponent,
    GuestSetdownComponent,
    GuestSetupComponent,
    ViewProcessDocumentComponent,
    StopPropagationDirective,
    SpinnerComponent,
    SharedDocumentDetailsComponent,
    NewpasswordComponent,
    ResetpasswordComponent,
    PresentationComponent,
    AccountComponent,
    OwnDocumentComponent,
    ErrormessagePipe,
    ContextMenuComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    DragDropModule,
    MatDatepickerModule,
    MatNativeDateModule,
    PdfViewerModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    MatDatepickerModule,
    MatNativeDateModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
