import { Component, OnInit, ViewChild, ElementRef, Type } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CdkDragEnd } from "@angular/cdk/drag-drop";

import { ApiTemplatesService } from 'src/app/service/api-templates.service'
import { ApiProcessService } from 'src/app/service/api-process.service'
import { Template } from 'src/app/models/template.model'
import { VariableDocument } from 'src/app/models/variableDocument.model'
import { VariableEntity } from 'src/app/entity/variable.entity'
import { Signer } from 'src/app/models/signer.model'
import { ApiDocumentsService } from '../../service/api-documents.service';
import { UsersService } from '../../service/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentTypeModel } from 'src/app/models/documentType.model';
import { Process } from 'src/app/models/process.model';
import { DocumentImage } from 'src/app/models/documentImage.model';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';

export interface Device {
  deviceDescription: string,
  deviceName: number
}

export interface Variable {
  id: number,
  name: string,
  type: string,
  anchor: string,
  uuid: string
  value: string
}

@Component({
  selector: 'app-signdocumentprocess',
  templateUrl: './signdocumentprocess.component.html',
  styleUrls: ['./signdocumentprocess.component.css']
})

export class SigndocumentprocessComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Elija un archivo';

  loading: boolean = true;
  documentForm:FormGroup = new FormGroup({
    signatureType: new FormControl('', [
      Validators.required,
    ]),
    emailRemote: new FormControl('', []),
    device: new FormControl('', []),
  });

  emailOptions: string[];
  showEmailSigned = true
  reader = new FileReader;
  idProcess: string;
  idTemplate: string;
  buttonDisable = false;
  template: Template = new Template();
  templateContent;
  devices: Device[];
  signatureChanels: DocumentTypeModel[];
  showSignatureChanels: Boolean = true;
  addressee: [VariableEntity] = [new VariableEntity()];
  signerAdressee: Signer;
  expedient: string;
  process: Process;
  signer: [VariableEntity] = [new VariableEntity()];
  document: [VariableEntity] = [new VariableEntity()];
  form: [VariableEntity] = [new VariableEntity()];
  user: [VariableEntity] = [new VariableEntity()];
  signerDefault: [VariableEntity] = [new VariableEntity()];
  sendEmail: boolean = false
  sendOpenId: boolean = false
  imageConverter: [DocumentImage];
  doneImageConvert
  imagepdf: string
  pageImagePdf: number = 0
  lengthPageImagePdf: number = 0
  coordinatePosX = 0
  coordinatePosY = 0
  validFile = false;
  img = new Image;

  constructor(private formBuilder: FormBuilder,
    private templateService: ApiTemplatesService,
    private documentsService: ApiDocumentsService,
    private processService: ApiProcessService,
    private userService: UsersService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private _snackBar: MatSnackBar) {
    this.activatedRouter.params.subscribe(parametros => {
      this.idProcess = parametros['idprocess'];
      this.idTemplate = parametros['idtemplate'];
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.img.width = 1240;
    this.img.height = 1754;

    //Obtiene proceso y template
    this.processService.getProcessbyTemplateUUid(this.idProcess, this.idTemplate).subscribe(dataProcessTemplate => {
      if (dataProcessTemplate != null) {
        this.process = dataProcessTemplate;
        this.signerAdressee = this.process.signer

        this.emailOptions = [dataProcessTemplate.signer.email];
        this.documentForm.controls.emailRemote.setValue(dataProcessTemplate.signer.email)
        if (dataProcessTemplate.signer.email2 != null) {
          this.emailOptions.push(dataProcessTemplate.signer.email2)
        }
        this.template = this.process.processConfig.templates[0];
        this.templateContent = this.process.processConfig.templates[0].content;
        this.documentForm.valueChanges.subscribe(data => {
          this.uploadDocument()
        })
        this.breadcrumbService.setElements([
          { name: 'Procesos', link: '/processes' },
          { name: this.process.processConfig.name, link: `/processes/${this.idProcess}` },
          { name: this.template.name, link: `/processes/${this.idProcess}/sign/${this.idTemplate}` }
        ]);

        //Obtiene canales de firmas disponibles para el usuario logueado
        this.userService.getUser().subscribe(dataUser => {
          if (dataUser != null) {
            switch (this.template.templateType.name) {
              case 'SetSigner':
                this.signatureChanels = [{ id: 3, name: "remote", active: true }];
                this.documentForm.controls.signatureType.setValue(3);
                break;
              case 'SetDown':
                this.signatureChanels = [{ id: 3, name: "remote", active: true }];
                this.documentForm.controls.signatureType.setValue(3);
                break;
              case 'SetUp':
                this.signatureChanels = [{ id: 1, name: "analog", active: true }];
                this.documentForm.controls.signatureType.setValue(1);
                this.showSignatureChanels = false;
                break;
              default:
                this.signatureChanels = dataUser.documentTypesDtos;
                break;
            }
          }
        }, error => this.onError(error));

        if (this.template.templateType.name == 'SetAdmin' || this.template.templateType.name == 'SetUp') {
          this.sortComponent(this.template.variables);
        }
        this.document.splice(0, 1);
        this.loading = false;
      }
    }, error => this.onError(error));

    //Obtiene dispositivos asociados a un usuario
    this.templateService.getDevices().subscribe(dataDevices => {
      if (dataDevices != null) {
        this.devices = dataDevices;
      }
    }, error => this.onError(error));
  }

  // Separa por componentes para visualizar formulario y crea los requeridos necesarios en el formulario
  private sortComponent(variables: [VariableEntity]) {
    variables.forEach(variable => {
      switch (variable.container) {
        case "addressee":
          this.addressee[variable.orederNumer] = variable;
          break;
        case "signer":
          this.signer[variable.orederNumer] = variable;
          break;
        case "document":
          this.document[variable.orederNumer] = variable;
          break;
        case "form":
          this.form[variable.orederNumer] = variable;
          break;
        case "user":
          this.user[variable.orederNumer] = variable;
          break;
        case "template":
          this.document[variable.orederNumer] = variable;
          this.documentForm.addControl(variable.uuid, new FormControl(variable.value));
          if (variable.requiered) {
            this.documentForm.controls[variable.uuid].setValidators([Validators.required]);
          }
          break;
        case "signerDefault":
          this.signerDefault[variable.orederNumer] = variable;
          break;
        default:
          break;
      }
    });
    this.form.splice(0, 1);
    this.user.splice(0, 1);
    this.uploadDefault();
    this.signerDefault.splice(0, 1)
  }

  private uploadFileEvt = (imgFile: any) => {
    let promise = new Promise((resolve, reject) => {
      if (imgFile.target.files && imgFile.target.files[0]) {

        this.fileAttr = '';
        Array.from(imgFile.target.files).forEach((file: File) => {
          this.fileAttr += file.name;
        });
        this.reader.onload = function (e: any) {
          if (e.loaded < 1000000) {
            resolve(true);
          } else {
            resolve(false);
          }

        };
        this.reader.readAsDataURL(imgFile.target.files[0]);
        this.fileInput.nativeElement.value = "";


      } else {
        this.fileAttr = 'Elija un archivo';
      }

    });
    return promise;
  }

  converter(imgFile: any) {
    this.doneImageConvert = "loading"
    this.uploadFileEvt(imgFile).then((res) => {
      if (res == true) {
        let base64 = { 'content': this.reader.result.toString().replace("data:application/pdf;base64,", "") }
        this.documentsService.postDocumentConverter(base64).subscribe(dataConverter => {
          if (dataConverter.status == 200) {
            this.imageConverter = dataConverter.body;
            this.img.src = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
            this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
            this.lengthPageImagePdf = this.imageConverter.length
            this.doneImageConvert = "done"
            this.validFile = true;
          }
        }, error => {
          this.buttonDisable = false;
          this.doneImageConvert = "done"
          this.onError(error);
        });
      } else {
        this.showMsgSnack('El archivo no debe superar 1MB');
        this.buttonDisable = false;
        this.doneImageConvert = "false"
        this.validFile = false;

      }
    })
  }

  changePage(direction) {
    if (direction == "next") {
      if (this.pageImagePdf < this.lengthPageImagePdf - 1) {
        this.pageImagePdf = this.pageImagePdf + 1
        this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.img.src = this.imagepdf;
      }
    }
    else {
      if (this.pageImagePdf != 0) {
        this.pageImagePdf = this.pageImagePdf - 1
        this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.img.src = this.imagepdf;
      }
    }
  }

  //Remplaza el documento con la información registrada
  uploadDefault() {
    let date = new Date()
    this.user.forEach(element => {
      if (element.value != null && element.value != "" && element.value != undefined) {
        this.template.content = this.template.content.split(element.anchor).join(element.value)
      }
    });
    let formatDate = date.getDate().toString().length == 2 ? date.getDate().toString() : "0" + date.getDate()
    formatDate += "-" + (date.getMonth().toString().length == 2 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1))
    this.template.content = this.template.content.split("%document_date%").join(formatDate + "-" + date.getFullYear())
    this.template.content = this.template.content.split("id='" + '%signer_name_1%' + "' style='display: none'").join("");
    this.template.content = this.template.content.split('%signer_name_1%').join(this.signerAdressee.name)
    this.template.content = this.template.content.split('%signer_last_name_1%').join(this.signerAdressee.lastName)
    this.template.content = this.template.content.split('%signer_document_type_1%').join(this.signerAdressee.typeOfIdn)
    this.template.content = this.template.content.split('%signer_document_1%').join(this.signerAdressee.idn)
    this.template.content = this.template.content.split('%signer_phone_1%').join(this.signerAdressee.phone)
    this.template.content = this.template.content.split('%signer_mail_1%').join(this.signerAdressee.email)
    this.template.content = this.template.content.split('%signer_role_1%').join("Firmante")
    this.template.content = this.template.content.split('%addressee_name%').join(this.signerAdressee.name)
    this.template.content = this.template.content.split('%addressee_last_name%').join(this.signerAdressee.lastName)
    this.template.content = this.template.content.split('%addressee_document_type%').join(this.signerAdressee.typeOfIdn)
    this.template.content = this.template.content.split('%addressee_document%').join(this.signerAdressee.idn)
    this.template.content = this.template.content.split('%addressee_phone%').join(this.signerAdressee.phone)
    this.template.content = this.template.content.split('%addressee_mail%').join(this.signerAdressee.email)
    this.template.content = this.template.content.split('%addressee_role%').join("Firmante")
    this.document.forEach(variableDocument => {
      switch (variableDocument.type) {
        case 'string':
          if (variableDocument.value == undefined || variableDocument.value == "" || variableDocument.value == null) {
            this.template.content = this.template.content.split(variableDocument.anchor)
              .join("<input type='text' id=" + variableDocument.uuid
                + " placeholder=" + variableDocument.name
                + " style='width: 300px;background-color: #C8D6F3'>")
          } else {
            this.template.content = this.template.content.split(variableDocument.anchor)
              .join("<input type='text' id=" + variableDocument.uuid
                + " placeholder=" + variableDocument.name
                + " value=" + variableDocument.value
                + " style='width: 300px;background-color: #C8D6F3'>")
          }
          break;
        case 'dateInput':
          this.template.content = this.template.content.split(variableDocument.anchor)
            .join("<input type='date' id=" + variableDocument.uuid
              + " placeholder=" + variableDocument.name
              + " style='width: 300px;background-color: #C8D6F3'>")
          break;
        case 'select':
          let optionsSelect = JSON.parse(variableDocument.config)
          let htmlOption = "<option disabled selected>Selecciona una opción</option>"
          optionsSelect.forEach(optionInSelect => {
            htmlOption = htmlOption + "<option value='" + optionInSelect + "'>" + optionInSelect + "</option>"
          })
          this.template.content = this.template.content.split(variableDocument.anchor)
            .join("<select id=" + variableDocument.uuid + ">"
              + htmlOption
              + "</select>"
            )
          break;
        case 'check':
          this.template.content = this.template.content.split(variableDocument.anchor)
            .join("<input type='checkbox' id=" + variableDocument.uuid
              + ">")
          break;

        default:
          break;
      }
    })

    this.signerDefault.forEach(element => {
      switch (element.anchor) {
        case "%signer_document_type%":
          this.template.content = this.template.content.split('%signer_document_type%').join(this.signerAdressee.typeOfIdn)
          break;
        case "%signer_document%":
          this.template.content = this.template.content.split('%signer_document%').join(this.signerAdressee.idn)
          break;
        case "%signer_name%":
          this.template.content = this.template.content.split('%signer_name%').join(this.signerAdressee.name + " " + this.signerAdressee.lastName)
          break;
        case "%signer_mail%":
          this.template.content = this.template.content.split('%signer_mail%').join(this.signerAdressee.email)
          break;
        case "%signer_phone%":
          this.template.content = this.template.content.split('%signer_phone%').join(this.signerAdressee.phone)
          break;
      }
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  //sincroniza los datos obtenidos por formulario con los requeridos para enviar a firmar
  /////////////////////////////////////////////////////////////////////////////////////////

  //Sincroniza datos del destinatario para enviar a firmar
  private matchDataAddesse = () => {
    let promise = new Promise((resolve, reject) => {
      let variables = [new VariableDocument()]
      //Asocia variables de Addressee
      this.addressee.forEach(element => {
        switch (element.name) {
          case "addressee_document_type":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.typeOfIdn })
            break;
          case "addressee_document":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.idn })
            break;
          case "addressee_name":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.name })
            break;
          case "addressee_last_name":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.lastName })
            break;
          case "addressee_mail":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.email })
            break;
          case "addressee_phone":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.phone })
            break;
          default:
            break;
        }
      });
      variables.splice(0, 1)
      resolve(variables);
    });

    return promise;
  };

  //Sincroniza datos del firmante
  private matchDataSigner = () => {
    let promise = new Promise((resolve, reject) => {
      let variables = [new VariableDocument()]
      //Asocia variables de Addressee
      this.signer.forEach(element => {
        switch (element.name) {
          case "signer_document_type_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.typeOfIdn })
            break;
          case "signer_document_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.idn })
            break;
          case "signer_name_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.name })
            break;
          case "signer_last_name_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.lastName })
            break;
          case "signer_mail_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.email })
            break;
          case "signer_phone_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.phone })
            break;
          case "signer_role_1":
            variables.push({ "uuid": element.uuid, "value": "Firmante" })
            break;
          default:
            break;
        }

      });
      variables.splice(0, 1)
      resolve(variables);
    });

    return promise;
  };

  //Sincroniza datos adicionales del documento para enviar a firmar
  private matchDataDocument = () => {
    let promise = new Promise((resolve, reject) => {
      let variables = [new VariableDocument()]
      this.document.forEach(element => {
        if (element.type == "check") {
          variables.push({ "uuid": element.uuid, "value": document.getElementById(element.uuid)['checked'] })
        } else {
          if (document.getElementById(element.uuid)['value'] != "") {
            variables.push({ "uuid": element.uuid, "value": document.getElementById(element.uuid)['value'] })
          }
        }

      });
      variables.splice(0, 1)
      resolve(variables);
    });
    return promise;
  };

  //Sincroniza datos del formulario para enviar a firmar
  private matchDataForm = () => {
    let promise = new Promise((resolve, reject) => {
      let variables = [new VariableDocument()]
      this.form.forEach(element => {
        variables.push({ "uuid": element.uuid, "value": element.value })
      });
      variables.splice(0, 1)
      resolve(variables);
    });
    return promise;
  };

  //Sincroniza datos del cliente para enviar a firmar
  private matchDataUser = () => {
    let promise = new Promise((resolve, reject) => {
      let variables = [new VariableDocument()]
      this.user.forEach(element => {
        variables.push({ "uuid": element.uuid, "value": element.value })
      });
      variables.splice(0, 1)
      resolve(variables);
    });
    return promise;
  };

  uploadDocument() {
    this.template.content = this.templateContent;
    this.uploadDefault();
    this.form.forEach(element => {
      this.template.content = this.template.content.split(element.anchor).join(element.value);
    });

    this.user.forEach(element => {
      this.template.content = this.template.content.split(element.anchor).join(element.value);
    });
  }

  isValidFile() {
    if (this.template.templateType.name == 'File' && (this.reader.readyState != 2 || !this.validFile)) {
      return false;
    }

    return true;
  }

  checkSendEmail(value: any) {
    this.sendEmail = value.currentTarget.checked;
    this.showEmailSigned = !value.currentTarget.checked
  }

  checkOpenid(value: any) {
    this.sendOpenId = value.currentTarget.checked;
  }

  private validDocumentData = () => {
    let promise = new Promise((resolve, reject) => {
      let isValid = true
      this.document.forEach(element => {
        let elementHtml = document.getElementById(element.uuid)
        if (element.type == "dateInput") {

        }
        if (element.requiered) {
          if (element.type == "check") {
            if (!elementHtml['checked']) {
              elementHtml.parentNode['style']['color'] = 'red';
              isValid = false
            } else {
              elementHtml.parentNode['style']['color'] = 'black';
            }
          } else {
            if (elementHtml['value'] == "") {
              elementHtml['style']['background-color'] = "#ffbaba"
              isValid = false
            } else (
              elementHtml['style']['background-color'] = "#C8D6F3"
            )
          }
        }
      });
      resolve(isValid);
    });
    return promise;
  };

  onSubmit() {
    this.validDocumentData().then(dataIsValid => {
      if (dataIsValid && this.documentForm.valid && this.isValidFile()) {
        this.buttonDisable = true;

        Promise.all([this.matchDataAddesse(), this.matchDataSigner(), this.matchDataDocument(), this.matchDataForm(), this.matchDataUser()]).then(values => {
          let dataToSend = {
            "documentType": this.documentForm.controls['signatureType'].value,
            "templateUuid": this.idTemplate,
            "device": this.documentForm.controls['device'].value,
            "sendEmail": this.sendEmail,
            "openid": this.sendOpenId,
            "emailRemote": this.documentForm.controls['emailRemote'].value,
            "variables": Object.values(values[0]).concat(Object.values(values[1]), Object.values(values[2]), Object.values(values[3]), Object.values(values[4]))
          }

          if (this.template.templateType.name == 'File') {
            dataToSend["base64"] = this.reader.result.toString().replace("data:application/pdf;base64,", "");
            if (this.img.height > 297) {
              this.coordinatePosY = this.coordinatePosY
            }
            dataToSend["coordinates"] = { "page": this.pageImagePdf + 1, "posX": this.coordinatePosX, "posY": this.coordinatePosY + 7 }
          }

          //Envía documento a firmar
          this.showMsgSnack('Documento enviado a firmar');
          this.documentsService.postDocumentInProcess(this.idProcess, dataToSend).subscribe(dataDService => {
            if (dataDService.status == 200) {
              this.router.navigate(['/processes/' + this.idProcess]);
            }
          }, error => {
            this.buttonDisable = false;
            this.onError(error);
          });
        });

        return null;

      } else {
        this.buttonDisable = false;
        this.showMsgSnack('Por favor ingrese los datos requeridos.');
      }

    })
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error.');
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  dragEnd(event: CdkDragEnd) {
    this.coordinatePosX = event.source.getFreeDragPosition()['x'] < 0 ? 0 : event.source.getFreeDragPosition()['x'] * (210 / this.img.width)
    this.coordinatePosY = event.source.getFreeDragPosition()['y'] < 0 ? 0 : event.source.getFreeDragPosition()['y'] * (297 / this.img.height)
  }

}
