<div class="content">
  <img
    class="img-login"
    src="../../assets/img/fondo_login.jpg"
    alt="Bienvenido a SuperDocs"
  />
  <div class="wrapper-login">
    <header class="cabecera-login bg-ds">
      <img
        src="../../assets/img/SuperDocs.png"
        alt="Superdocs es un producto de DS Legal"
      />
    </header>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login">
      <app-spinner *ngIf="showSpinner; else viewFormxx"></app-spinner>
      <ng-template #viewFormxx>
        <div class="ex1">
          <p>
            SUPERDOCS es una herramienta digital de titularidad de la mercantil
            SILICON VALLEY GLOBAL SERVICES S.L., empresa constituida bajo la
            normativa española y con domicilio social en España.
          </p>
          <p>
            En cumplimiento de lo dispuesto en el Reglamento Europeo de
            Protección de Datos, Reglamento UE 2016/679 (en adelante, “RGPD”) y
            la LO 3/2018, de 5 de diciembre, de Protección de Datos Personales y
            garantía de los derechos digitales (en adelante, “LOPDGDD”), SILICON
            VALLEY GLOBAL SERVICES S.L. le informa que es el responsable del
            tratamiento de los datos personales que nos va a facilitar mediante
            el presente enlace.
          </p>
          <p>
            Se le informa que los datos personales que nos facilite serán
            cedidos a la empresa empleadora, que suscriba con Ud. el contrato de
            trabajo para el puesto que ha sido seleccionado, siendo la
            responsable directa del tratamiento de sus datos personales,
            asimismo, no cederá sus datos personales a terceros salvo obligación
            legal.
          </p>
          <p>
            La finalidad del tratamiento consiste en llevar a cabo el proceso de
            contratación tras haber sido seleccionado y la base de legitimación
            para dicho tratamiento, de conformidad con el art. 6.1.a) del RGPD,
            es el consentimiento.
          </p>
          <p>
            Los datos personales que pudiera facilitarnos en el cuestionario de
            salud serán tratados exclusivamente, con la finalidad de formalizar
            en el caso que proceda, la póliza de seguro de vida que la empresa
            pudiera suscribir a favor del trabajador. La base legal para el
            tratamiento de sus datos, además de por haber obtenido su previo
            consentimiento, es la ejecución del contrato de seguro según los
            términos y condiciones que figuran en el mismo.
          </p>
          <p>
            Al objeto de prestar los servicios de asesoramiento e
            intermediación, es necesaria la cesión de sus datos a terceros, en
            concreto a las entidades colaboradoras prestadoras de servicios con
            las que se formalice y mantenga la relación contractual. Al
            facilitarnos su información personal, nos autoriza expresamente a
            tratar y compartir su información con dichos colaboradores, en la
            medida que están directamente implicados en la prestación del
            producto contratado.
          </p>
          <p>
            Los datos personales se conservarán durante el tiempo que se
            mantenga la relación contractual. Una vez finalizada la relación
            contractual, SILICON VALLEY GLOBAL SERVICES S.L. y la empresa
            empleadora, mantendrán sus datos personales bloqueados durante los
            plazos de prescripción legalmente establecidos, transcurridos los
            cuales destruiremos sus datos.
          </p>
          <p>
            En caso de que usted mantenga una relación laboral y/o civil con una
            empresa fuera de España, se le informa que sus datos personales
            serán internacionalmente transferidos para ser almacenados,
            gestionados y tratados igualmente por SILICON VALLEY GLOBAL SERVICES
            S.L.
          </p>
          <p>
            Tales datos no serán cedidos a terceras personas[1], salvo en
            aquellos casos en los que exista una obligación legal, pudiendo
            ejercitar sobre los mismos en cualquier momento sus derechos
            POLIARSO (portabilidad, oposición, limitación, información, acceso,
            rectificación, supresión y oposición a ser objeto de decisiones
            individuales automatizadas) recogidos en los arts. 15 a 22 de la
            referida normativa ante el responsable del fichero, debiéndolo
            notificar por escrito a éste, acreditando su identidad, a la
            siguiente dirección de correo electrónico:
            <a href="mailto:derechosprotecciondatos@siliconvgs.es"
              >derechosprotecciondatos@siliconvgs.es.</a
            >
          </p>
          <p>
            Asimismo, se le informa de su derecho a reclamar ante la Autoridad
            de Control (Agencia Española de Protección de Datos:
            <a href="https://www.aepd.es/es">https://www.aepd.es/es</a>)
          </p>
        </div>
        <div class="row">
          <input type="checkbox" formControlName="checked" />
          <label> He leido y Acepto la Política de Privacidad</label>
        </div>
        <mat-error *ngIf="loginForm.controls.checked.hasError('required')">
          Para poder continuar debe Aceptar la Política de Privacidad
        </mat-error>
        <button class="boton">Entrar</button>
      </ng-template>
    </form>
    <div class="logo-power">
      <img
        src="../../assets/img/DSLegaltech.png"
        alt="power by unidad legaltech de DS Legal"
      />
    </div>
  </div>
</div>
