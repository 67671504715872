<div class="wrapper">
    <div class="cuerpo">
        <form name="entrar" [formGroup]="signerForm" (ngSubmit)="onSubmit()" class="formulario" autocomplete="off">
            <div class="row documento">
                <h2 class="tp-navandu"> INFORMACIÓN DEL DESTINATARIO </h2>
                <div class="flex-row">
                    <mat-form-field class="example-full-width">
                        <mat-label>Número de documento</mat-label>
                        <input type="text" matInput formControlName="idn" required>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Tipo de documento</mat-label>
                        <mat-select required formControlName="typeOfIdn">
                            <mat-option value="Alias">Alias</mat-option>
                            <mat-option value="DNI">DNI</mat-option>
                            <mat-option value="NIF">NIF</mat-option>
                            <mat-option value="NIE">NIE</mat-option>
                            <mat-option value="CIF">CIF</mat-option>
                            <mat-option value="PASS">PASS</mat-option>
                            <mat-option value="RUC">RUC</mat-option>
                            <mat-option value="Cedula">Cedula</mat-option>
                            <mat-option value="Otro">Otro</mat-option>
                        </mat-select>
                        <mat-error *ngIf="signerForm.controls.typeOfIdn.hasError('required')">
                            Tipo de documento <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="flex-row">
                    <mat-form-field>
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name" required>
                        <mat-error *ngIf="signerForm.controls.name.hasError('required')">
                            Nombre <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Apellidos</mat-label>
                        <input matInput formControlName="lastName" required>
                        <mat-error *ngIf="signerForm.controls.lastName.hasError('required')">
                            Apellido <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="flex-row filatres">
                    <div class="phone-group">
                        <mat-form-field class="prefix-section">
                            <mat-select required formControlName="countryPhone">
                              <mat-option *ngFor="let phonePrefix of phonePrefixes" [value]="phonePrefix.prefix">
                                {{phonePrefix.code}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        <mat-form-field class="phone">
                            <mat-label>Móvil para sms de firma</mat-label>
                            <input matInput formControlName="phone">
                            <mat-error *ngIf="signerForm.controls.phone.hasError('required')">
                                Móvil <strong>requerido</strong>
                            </mat-error>
                            <mat-error
                                *ngIf="signerForm.controls.phone.hasError('minlength') || signerForm.controls.phone.hasError('maxlength')">
                                Móvil <strong>debe tener 9 caracteres</strong>
                            </mat-error>
                            <mat-error *ngIf="signerForm.controls.phone.hasError('pattern')">
                                Móvil <strong>debe ser numérico</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <mat-form-field class="email">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Ex. pat@example.com" formControlName="email">
                        <mat-error
                            *ngIf="signerForm.controls.email.hasError('email') && !signerForm.controls.email.hasError('required')">
                            Por favor ingrese un correo válido
                        </mat-error>
                        <mat-error *ngIf="signerForm.controls.email.hasError('required')">
                            Correo <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>


            </div>
            <div class="enviar">

                <input type="submit" class="boton volver2" value="Guardar">

            </div>
        </form>

    </div>
</div>