import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AutenticationService } from '../../service/autentication.service';
import { GuestsService } from '../../service/guests.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { OpenIDClient } from "../../entity/libs/signer.entity";

@Component({
  selector: 'app-guest-login-guest',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class GuestLoginComponent implements OnInit {

  token: string;
  code: string;
  loginForm: FormGroup;
  showSpinner= true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private autenticationService: AutenticationService,
    private guestsService: GuestsService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      'checked': [false, Validators.requiredTrue]
    });

    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.code = this.activatedRoute.snapshot.queryParamMap.get('code');


    if (!this.token && !this.code) {
      localStorage.clear();
      this.onError('Token not found!');
    } else {
      if (this.token) {
        this.autenticationService.loginGuest({ token: this.token }).subscribe(data => {
          if (data.status == 200) {
            localStorage.setItem('token', data.headers.get('authorization'));
            this.guestsService.getSigner().subscribe(signer => {
              if (signer != null) {
                this.updateStyle(signer);
                localStorage.setItem('guest', JSON.stringify(signer));
                if (signer.openid == "vidchain") {
                  this.showMsgSnack('Redirigiendo a VidChain para validar credenciales');
                  this.loginWithVIDChain()
                } else {
                  //Si ha aceptado RGPD
                  if(signer.rgpd){
                    this.router.navigate(['/guest/documents']);
                  }else{
                    this.showSpinner=false;
                  }
                }
              }
            },
              error => {
                this.onError(error);
              }
            );


          }
        },
          error => {
            this.onError(error);
          }
        );
      } else {
        this.guestsService.getVIDCredentials(this.code).subscribe(credentials => {
          if (credentials) {
            //Si ha aceptado RGPD
            if(JSON.parse( localStorage.getItem('guest'))['rgpd']){
              this.router.navigate(['/guest/documents']);
            }else{
              this.showSpinner=false;
            }
          }else{
            this.showMsgSnack('Credenciales de acceso no coinciden');
            localStorage.clear();
            this.router.navigate(['/login']);
          }
        },
          error => {
            this.onError(error);
          }
        );
      }

    }



  }

  async loginWithVIDChain() {
    var client = OpenIDClient.getInstance().getClient();
    await client.callback();
    await client.getToken({
      scopes: {
        request: ["openid", "VidOtpCredential"]
      },
    });
  }

  onError(error) {
    localStorage.clear();
    console.log(error)
    this.showMsgSnack(error);
    this.router.navigate(['/login']);
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 30000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  updateStyle(user) {
    user.style = "ds";
  }

  onSubmit() {
    if (this.loginForm.valid) {
      try {
        let data = {
          "identifier": JSON.parse(localStorage.getItem('guest'))['uuid'],
          "rgpd": true
        }
        this.guestsService.putCheckRgpd(data).subscribe(dataUser => {   // data is of type HttpResponse<Object>
          if (dataUser.status == 200) {
            this.router.navigate(['/guest/documents']);
          }
        },
          error => {
            if (error.status == 401) {
            } else {
              var texto = error
              console.log(texto)
            }
          }
        )
      } catch (err) {
        
      }
    } else {
      console.log(this.loginForm)
    }
  }

}
