import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AutenticationService } from '../../service/autentication.service';
import { GuestsService } from '../../service/guests.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-newpassword',
  templateUrl: './newpassword.component.html',
  styleUrls: ['./newpassword.component.css']
})
export class NewpasswordComponent implements OnInit {

  token: string;
  unauthorized = false;
  serverError = false;
  coincide = true;
  hide = true;


  passwordForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
    ]),
    repassword: new FormControl('', [
      Validators.required,
    ]),

  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private autenticationService: AutenticationService,
    private _snackBar: MatSnackBar,
    private guestsService: GuestsService,
  ) { }

  ngOnInit(): void {
    localStorage.clear();
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    if (!this.token) {
      this.onError('Token not found!');
    }

    this.autenticationService.loginGuest({token: this.token}).subscribe(data => {
      if (data.status == 200) {
        localStorage.setItem('token', data.headers.get('authorization'));
      }else{
        this.router.navigate(['/login']);
      }
    },
      error => {
        this.onError(error);
      }
    )

  }

  onSubmit() {
    if (this.passwordForm.valid) {
      if (this.passwordForm.controls.password.value == this.passwordForm.controls.repassword.value) {
        this.coincide = true
        let data = {
          "password": this.passwordForm.controls.password.value,
          "repassword": this.passwordForm.controls.repassword.value
        }
        this.guestsService.putNewPasssword(data).subscribe(response => {
          if (response.status == 200) {
            this.showMsgSnack('Su contraseña ha cambiado');
            this.router.navigate(['/login']);
          }
        },
          error => {
            this.onError(error);
          }
        );
      }
      else {
        this.coincide = false
      }
    }
  }

  onError(error) {
    this.router.navigate(['/login']);
  }

  updateStyle(user) {
    user.style = "ds";
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

}
