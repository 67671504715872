import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { User } from '../models/user.model';
import { UserConsents } from '../models/user-consents.model';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private basePath = environment.uriApiAzure;
  private basePathClients = environment.uriApiAzureLogin;

  constructor(private http: HttpClient) { }

  public getUser(): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<User>(this.basePath + '/user', httpOptions);
  }

  public getUserConsents(username: String): Observable<UserConsents> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<UserConsents>(this.basePathClients + '/clients/users/username/' + username, httpOptions);
  }

  public putUser(user: User): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.put(this.basePath + '/user', JSON.stringify(user), { headers: headers, observe: 'response' });
  }

  public putUserConsents(user: UserConsents): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.put(this.basePathClients + '/clients/users/edit-name', JSON.stringify(user), { headers: headers, observe: 'response' });
  }

}
