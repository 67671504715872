import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkDragEnd } from "@angular/cdk/drag-drop";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { ApiDocumentsService } from '../../service/api-documents.service';
import { ApiTemplatesService } from 'src/app/service/api-templates.service'
import { SignersService } from '../../service/signers.service';
import { UsersService } from '../../service/users.service';
import { ValidationService } from '../../service/validation.service';
import { SignerEntity } from 'src/app/entity/signer.entity'
import { CoordinatesEntity } from 'src/app/entity/coordinates.entity'
import { SignerBatch } from 'src/app/models/signerBatch.model'
import { VariableEntity } from 'src/app/entity/variable.entity'
import { DocumentImage } from 'src/app/models/documentImage.model';
import { DocumentBatch } from 'src/app/models/documentBatch.model'
import { DocumentTypeModel } from 'src/app/models/documentType.model';
import { Document } from 'src/app/models/document.model'
import { Signer } from 'src/app/models/signer.model'
import { ContextMenuModel } from '../../interfaces/context-menu-model';
import { PhonePrefixService } from '../../service/phone-prefix.service';
import { PhonePrefix } from 'src/app/models/phonePrefix.model';

export interface Device {
  deviceDescription: string,
  deviceName: number
}

@Component({
  selector: 'app-own-document',
  templateUrl: './own-document.component.html',
  styleUrls: ['./own-document.component.css']
})
export class OwnDocumentComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  id: string;
  severalSigners = false;
  dataSource = new MatTableDataSource<SignerEntity>();
  displayedColumns: string[] = ['check', 'name', 'idn', 'email', 'phone'];
  buttonDisable = false;
  fileAttr = 'Elija un archivo';
  filteredOptions: Observable<SignerEntity[]>;
  filteredOptionsName: Observable<SignerEntity[]>;
  filteredOptionsLastName: Observable<SignerEntity[]>;
  filterOptionsSigners: [Observable<SignerEntity[]>] = [new Observable<SignerEntity[]>()]
  countSigners: number = 0;
  countCoordindates = 0;
  countSignersForms = 0;
  options: SignerEntity[];
  dataSigner: SignerEntity;
  newUser: boolean = true;
  signersToDelete: number[] = [];
  coorsToDelete: number[] = [];
  ELEMENT_DATA: SignerEntity[] = null;
  devices: Device[];
  signatureChanels: DocumentTypeModel[];
  signerBatch: SignerBatch[] = [new SignerBatch()];

  @ViewChild('fileInput') fileInput: ElementRef;
  imageConverter: [DocumentImage];
  doneImageConvert;
  imagepdf: string;
  pageImagePdf: number = 0;
  lengthPageImagePdf: number = 0;
  reader = new FileReader;
  img = new Image;
  coordinatePosX = 0;
  coordinatePosY = 0;
  coordinatePage = 0;
  validFile = false;
  document: [VariableEntity] = [new VariableEntity()];
  addressee: [VariableEntity] = [new VariableEntity()];
  signer: [VariableEntity] = [new VariableEntity()];
  form: [VariableEntity] = [new VariableEntity()];
  user: [VariableEntity] = [new VariableEntity()];
  signerAdressee: Signer;
  sendEmail: boolean = false;
  sendEmailCreator: boolean = true;
  orderedSignatures: boolean = true;
  phonePrefixes: PhonePrefix[];
  //variables para control de menu editar/fijarfirma
  isDisplayContextMenu: Array<{'visible': boolean, 'locked': boolean}> = [];
  indexDisplayContextMenu = 0;
  rightClickMenuItems: Array<ContextMenuModel> = [];
  //

  documentForm = new FormGroup({
    addressee_document_type: new FormControl('', [
      Validators.required,
    ]),
    addressee_document: new FormControl('', [
      Validators.required,
    ]),
    addressee_name: new FormControl('', [
      Validators.required,
    ]),
    addressee_last_name: new FormControl('', [
      Validators.required,
    ]),
    addressee_phone: new FormControl('', [
      Validators.required,
      Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      Validators.minLength(9),
      Validators.maxLength(9),
    ]),
    addressee_mail: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    addressee_country: new FormControl('+34', [
      Validators.required,
    ]),
    addresseeAsSigner: new FormControl<boolean>(false, [
      Validators.required,
    ]),
    signatureType: new FormControl('', [
      Validators.required,
    ]),
    device: new FormControl('', [
    ]),
    expedient: new FormControl('', [
    ]),
    uuidSigner: new FormControl('', [
    ]),
    signers: new FormArray([]),
    coordinates: new FormArray([]),
  });

  arraySigners:Array<{'x': number, 'y': number}> = [];

  constructor(private formBuilder: FormBuilder,
    private documentsService: ApiDocumentsService,
    private signersService: SignersService,
    private templateService: ApiTemplatesService,
    private userService: UsersService,
    private validationService: ValidationService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private phonePrefixService: PhonePrefixService,
    private _snackBar: MatSnackBar) {
    this.activatedRouter.params.subscribe(parametros => {
      this.id = parametros['id'];
    });
  }

  ngOnInit(): void {
    for (let i = 0; i < 30; i++) {
      this.isDisplayContextMenu.push({visible: false, locked: false});
    }
    //this.isDisplayContextMenu = Array(30).fill({visible: false, locked: false});
    this.documentForm.controls['addresseeAsSigner'].setValue(true);
    this.signersService.getSigners().subscribe(dataSigners => {   // data is of type HttpResponse<Object>
      if (dataSigners != null) {
        this.options = dataSigners.slice();

        this.ELEMENT_DATA = dataSigners;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;

        this.filteredOptions =
          this.documentForm.controls.addressee_document.valueChanges.pipe(
            startWith(''),
            map((val) => this._filter(val))
          );
          this.filteredOptionsName = this.documentForm.controls.addressee_name.valueChanges.pipe(
            startWith(''),
            map((val) => this._filterName(val))
          );
          this.filteredOptionsLastName = this.documentForm.controls.addressee_last_name.valueChanges.pipe(
            startWith(''),
            map((val) => this._filterLastName(val))
          );
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          var texto = error
        }
      })
    //Obtiene dispositivos asociados a un usuario
    this.templateService.getDevices().subscribe(dataDevices => {   // data is of type HttpResponse<Object>
      if (dataDevices != null) {
        this.devices = dataDevices;
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          var texto = error
        }
      });
    //Obtiene canales de firmas disponibles para el usuario logueado
    this.userService.getUser().subscribe(dataUser => {   // data is of type HttpResponse<Object>
      if (dataUser != null) {
        this.signatureChanels = dataUser.documentTypesDtos;
        this.phonePrefixService.getPhonePrefixes().subscribe(dataPhonePrefixes => {
          this.phonePrefixes = dataPhonePrefixes;
          this.documentForm.get('addressee_country').setValue(dataUser.phonePrefix.prefix);
        });
      }
    },
      error => {
        if (error.status == 401) {
        } else {
          var texto = error
        }
      }
    );
  }

  dragEnd(event: CdkDragEnd, index: number) {
    if(index == 0){
      this.coordinatePosX = event.source.getFreeDragPosition()['x']
      this.coordinatePosY = event.source.getFreeDragPosition()['y']
    }else{
      this.documentForm.get('coordinates').value[index-1].posX = event.source.getFreeDragPosition()['x'];
      this.documentForm.get('coordinates').value[index-1].posY = event.source.getFreeDragPosition()['y'];
    }
  }


  private validPhoneNumber = () => {
    let promise = new Promise((resolve, reject) => {
      //Valid addressee phone
      if (!this.validationService.validatePhoneByCountry(this.documentForm.controls['addressee_country'].value, this.documentForm.controls['addressee_phone'].value))
        this.documentForm.controls.addressee_phone.setErrors({ invalidNumber: true });
      //Vaid signers phone
      this.documentForm.controls.signers.value.forEach((signer, index) => {
        if (!this.validationService.validatePhoneByCountry(signer.signer_country, signer.signer_phone))
          this.documentForm.controls.signers['controls'][index]['controls'].signer_phone.setErrors({ invalidNumber: true })
      });
      resolve(true);
    });
    return promise;
  };


  onSubmit() {
    this.validPhoneNumber().then(dataIsValid => {
      if (this.severalSigners && this.isValidFile() && this.signerBatch.length > 1) {
        let documentBatch = new DocumentBatch();
        this.matchDataSeveralSigners().then(values => {
          documentBatch.documentType = Number(this.documentForm.controls['signatureType'].value);
          documentBatch.expedient = this.documentForm.controls['expedient'].value;
          documentBatch.signers = Object.values(values);
          documentBatch.templateUuid = this.id
          if (this.img.height > 297) {
            this.coordinatePosY = this.coordinatePosY
          }
          documentBatch.base64 = this.reader.result.toString().replace("data:application/pdf;base64,", "");
          documentBatch.sendEmail = this.sendEmail;
          documentBatch.sendEmailCreator = this.sendEmailCreator;
          documentBatch.coordinates = { "page": this.pageImagePdf + 1, "posX": this.coordinatePosX, "posY": this.coordinatePosY };

          //Envía documento a firmar
          this.documentsService.postDocumentBatchFile(documentBatch).subscribe(dataDService => {   // data is of type HttpResponse<Object>
            if (dataDService.status == 200) {
              this.showMsgSnack('Documento enviado a firmar');
              this.router.navigate(['/templates']);
            }
          },
            error => this.onError(error)
          )
        })
      } else if (this.documentForm.valid && this.isValidFile()) {
        this.buttonDisable = true
        Promise.all([this.matchDataSigners()]).then(values => {
          let document = new Document();
          document.templateUuid = this.id
          document.documentType = Number(this.documentForm.controls['signatureType'].value);
          document.signers = values[0];
          document.base64 = this.reader.result.toString().replace("data:application/pdf;base64,", "");
          document.sendEmail = this.sendEmail;
          document.sendEmailCreator = this.sendEmailCreator
          document.expedient = this.documentForm.controls['expedient'].value;
          document.orderedSignatures = this.orderedSignatures;
          this.documentsService.postDocument(document).subscribe(dataDService => {   // data is of type HttpResponse<Object>
            if (dataDService.status == 200) {
              this.showMsgSnack('Documento enviado a firmar');
              this.router.navigate(['/templates']);
            }
          },
            error => this.onError(error)
          )
        });

        return null;
      } else {
        this.buttonDisable = false;
        this.onError('Por favor ingrese los datos requeridos.');
      }
    })

  }

  private matchDataSigners = () => {
    let documentSigners = [new SignerEntity()];
    let promise = new Promise((resolve, reject) => {
      if (this.documentForm.controls['addresseeAsSigner'].value) {
        let signerData: SignerEntity = new SignerEntity();
        signerData.device = this.documentForm.controls['device'].value,
          signerData.name = this.documentForm.controls['addressee_name'].value,
          signerData.lastName = this.documentForm.controls['addressee_last_name'].value,
          signerData.idn = this.documentForm.controls['addressee_document'].value,
          signerData.typeOfIdn = this.documentForm.controls['addressee_document_type'].value,
          signerData.email = this.documentForm.controls['addressee_mail'].value,
          signerData.phone = this.documentForm.controls['addressee_country'].value + this.documentForm.controls['addressee_phone'].value,
          signerData.newSigner = this.newUser
        documentSigners.push(signerData)
      }
      this.documentForm.get('signers').value.forEach((element, index) => {
        let signerData: SignerEntity = new SignerEntity();
        signerData.device = this.documentForm.controls['device'].value,
          signerData.name = element.signer_name,
          signerData.lastName = element.signer_last_name,
          signerData.idn = element.signer_document,
          signerData.typeOfIdn = element.signer_documet_type,
          signerData.email = element.signer_mail,
          signerData.phone = element.signer_country + element.signer_phone,
          signerData.newSigner = element.newUser
        documentSigners.push(signerData)
      });
      documentSigners.splice(0, 1)

      let coordinatesArray = [new CoordinatesEntity()];
      let coordinate: CoordinatesEntity = new CoordinatesEntity();
      coordinate.posX = this.coordinatePosX < 0 ? 0 : this.coordinatePosX * (210 / 413);
      coordinate.posY = this.coordinatePosY < 0 ? 0 : this.coordinatePosY * (297 / 585);
      coordinate.page = this.coordinatePage == 0 ? this.coordinatePage + 1 : this.coordinatePage;
      coordinatesArray.push(coordinate);
      this.documentForm.get('coordinates').value.forEach((element, index) => {
        let coordinate: CoordinatesEntity = new CoordinatesEntity();
        coordinate.posX = element.posX < 0 ? 0 : element.posX * (210 / 413);
        coordinate.posY = element.posY < 0 ? 0 : element.posY * (297 / 585);
        coordinate.page = element.page == "" ? this.coordinatePage + 1 : element.page;
        coordinatesArray.push(coordinate);
      });
      coordinatesArray.splice(0, 1)

      documentSigners.forEach((element, index) => {
        element.coordinates = coordinatesArray[index];
      });

      resolve(documentSigners);
    });
    return promise;
  };

  checkSeveralSigner(value: any) {
    this.severalSigners = value.currentTarget.checked;
    if (value.currentTarget.checked) {
      this.documentForm.controls.addressee_country.clearValidators();
      this.documentForm.controls.addressee_document_type.clearValidators();
      this.documentForm.controls.addressee_document.clearValidators();
      this.documentForm.controls.addressee_name.clearValidators();
      this.documentForm.controls.addressee_last_name.clearValidators();
      this.documentForm.controls.addressee_phone.clearValidators();
      this.documentForm.controls.addressee_mail.clearValidators();
      this.documentForm.controls.addressee_country.updateValueAndValidity();
      this.documentForm.controls.addressee_document_type.updateValueAndValidity();
      this.documentForm.controls.addressee_document.updateValueAndValidity();
      this.documentForm.controls.addressee_name.updateValueAndValidity();
      this.documentForm.controls.addressee_last_name.updateValueAndValidity();
      this.documentForm.controls.addressee_phone.updateValueAndValidity();
      this.documentForm.controls.addressee_mail.updateValueAndValidity();
    } else {
      this.documentForm.controls.addressee_country.setValidators([Validators.required]);
      this.documentForm.controls.addressee_document_type.setValidators([Validators.required]);
      this.documentForm.controls.addressee_document.setValidators([Validators.required]);
      this.documentForm.controls.addressee_name.setValidators([Validators.required]);
      this.documentForm.controls.addressee_last_name.setValidators([Validators.required]);
      this.documentForm.controls.addressee_phone.setValidators([
        Validators.required,
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        Validators.minLength(9),
        Validators.maxLength(9),
      ]);
      this.documentForm.controls.addressee_mail.setValidators([Validators.required, Validators.email]);
      this.documentForm.controls.addressee_country.updateValueAndValidity();
      this.documentForm.controls.addressee_document_type.updateValueAndValidity();
      this.documentForm.controls.addressee_document.updateValueAndValidity();
      this.documentForm.controls.addressee_name.updateValueAndValidity();
      this.documentForm.controls.addressee_last_name.updateValueAndValidity();
      this.documentForm.controls.addressee_phone.updateValueAndValidity();
      this.documentForm.controls.addressee_mail.updateValueAndValidity();
      this.signerBatch = [new SignerBatch()];
      this.dataSource.data.filter(s => s.checked == true).forEach(obj => {
        obj.checked = false;
      })
    }
    let signerArray = this.documentForm.controls.signers as FormArray;
    signerArray.clear();
    let coordinatesArray = this.documentForm.controls.coordinates as FormArray;
    coordinatesArray.clear();
    this.arraySigners = [];
  }

  private onError(msg) {
    // this.showMsgSnack('Ha ocurrido un error.');
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: ['red-snackbar'],
    });
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  converter(imgFile: any) {
    this.doneImageConvert = "loading"
    this.uploadFileEvt(imgFile).then((res) => {
      if (res == true) {
        let base64 = { 'content': this.reader.result.toString().replace("data:application/pdf;base64,", "") }
        this.documentsService.postDocumentConverter(base64).subscribe(dataConverter => {
          if (dataConverter.status == 200) {
            this.imageConverter = dataConverter.body;
            this.img.src = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
            this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
            this.lengthPageImagePdf = this.imageConverter.length
            this.doneImageConvert = "done"
            this.validFile = true;
          }
        }, error => {
          this.buttonDisable = false;
          this.doneImageConvert = "done"
          this.onError(error);
        });
      } else {
        this.showMsgSnack('El archivo no debe superar 1MB');
        this.buttonDisable = false;
        this.doneImageConvert = "false"
        this.validFile = false;

      }
    })
  }

  checkSignser(event: any, value: any) {
    this.dataSource.data.filter(s => s.idn == value.idn)[0].checked = event.currentTarget.checked
    if (event.currentTarget.checked) {
      this.signerBatch.push(value)
    } else {
      let index = this.signerBatch.indexOf(value);
      this.signerBatch.splice(index, 1);
    }
  }

  changePage(direction) {
    if (direction == "next") {
      if (this.pageImagePdf < this.lengthPageImagePdf - 1) {
        this.pageImagePdf = this.pageImagePdf + 1
        this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.img.src = this.imagepdf;
      }
    }
    else {
      if (this.pageImagePdf != 0) {
        this.pageImagePdf = this.pageImagePdf - 1
        this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.img.src = this.imagepdf;
      }
    }
  }

  private uploadFileEvt = (imgFile: any) => {
    let promise = new Promise((resolve, reject) => {
      if (imgFile.target.files && imgFile.target.files[0]) {

        this.fileAttr = '';
        Array.from(imgFile.target.files).forEach((file: File) => {
          this.fileAttr += file.name;
        });
        this.reader.onload = function (e: any) {
          if (e.loaded < 1000000) {
            resolve(true);
          } else {
            resolve(false);
          }

        };
        this.reader.readAsDataURL(imgFile.target.files[0]);
        this.fileInput.nativeElement.value = "";


      } else {
        this.fileAttr = 'Elija un archivo';
      }

    });
    return promise;
  }

  private _filter(idn: string): SignerEntity[] {
    let optionFilter = this.options;
    const filterValue = idn.toLowerCase();
    const returnFilter = optionFilter.filter(option => option.idn.toLowerCase().indexOf(filterValue) === 0);
    if (returnFilter.length > 0) {
      if (idn == returnFilter[0].idn) {
        if (this.documentForm.controls['addressee_name'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
          this.documentForm.controls['addressee_name'].setValue(returnFilter[0].name);
        }
        if (this.documentForm.controls['addressee_last_name'].value != returnFilter[0].lastName) { // Sin esta condición se genera un bucle infinito
          this.documentForm.controls['addressee_last_name'].setValue(returnFilter[0].lastName);
        }
        this.documentForm.controls['addressee_country'].setValue(returnFilter[0].countryPhone);
        this.documentForm.controls['addressee_document_type'].setValue(returnFilter[0].typeOfIdn);
        this.documentForm.controls['addressee_phone'].setValue(returnFilter[0].phone);
        this.documentForm.controls['addressee_mail'].setValue(returnFilter[0].email);
        this.dataSigner = returnFilter[0];
        this.newUser = false;
      } else {
        this.newUser = true;
      }
    } else {
      this.newUser = true;
    }
    return returnFilter;
  }

  private _filterName(name: string): SignerEntity[] {
    let optionFilter = this.options;
    const filterValue = name.toLowerCase();
    const returnFilter = optionFilter.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    if (returnFilter.length > 0) {
      if (name == returnFilter[0].name) {
        if (this.documentForm.controls['addressee_document'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
          this.documentForm.controls['addressee_document'].setValue(returnFilter[0].idn);
        }
        if (this.documentForm.controls['addressee_last_name'].value != returnFilter[0].lastName) { // Sin esta condición se genera un bucle infinito
          this.documentForm.controls['addressee_last_name'].setValue(returnFilter[0].lastName);
        }
        this.documentForm.controls['addressee_country'].setValue(returnFilter[0].countryPhone);
        this.documentForm.controls['addressee_document_type'].setValue(returnFilter[0].typeOfIdn);
        this.documentForm.controls['addressee_phone'].setValue(returnFilter[0].phone);
        this.documentForm.controls['addressee_mail'].setValue(returnFilter[0].email);
        this.dataSigner = returnFilter[0];
        this.newUser = false;
      } else {
        this.newUser = true;
      }
    } else {
      this.newUser = true;
    }
    return returnFilter;
  }

  private _filterLastName(lastName: string): SignerEntity[] {
    let optionFilter = this.options;
    const filterValue = lastName.toLowerCase();
    const returnFilter = optionFilter.filter(option => option.lastName.toLowerCase().indexOf(filterValue) === 0);
    if (returnFilter.length > 0) {
      if (lastName == returnFilter[0].lastName) {
        if (this.documentForm.controls['addressee_document'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
          this.documentForm.controls['addressee_document'].setValue(returnFilter[0].idn);
        }
        if (this.documentForm.controls['addressee_name'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
          this.documentForm.controls['addressee_name'].setValue(returnFilter[0].name);
        }
        this.documentForm.controls['addressee_country'].setValue(returnFilter[0].countryPhone);
        this.documentForm.controls['addressee_document_type'].setValue(returnFilter[0].typeOfIdn);
        this.documentForm.controls['addressee_phone'].setValue(returnFilter[0].phone);
        this.documentForm.controls['addressee_mail'].setValue(returnFilter[0].email);
        this.dataSigner = returnFilter[0];
        this.newUser = false;
      } else {
        this.newUser = true;
      }
    } else {
      this.newUser = true;
    }
    return returnFilter;
  }

  buildSigner() {
    return this.formBuilder.group({
      id: '0',
      signer_role: '',
      signer_documet_type: '',
      signer_document: '',
      signer_name: '',
      signer_last_name: '',
      signer_mail: ['', [
        Validators.required,
        Validators.email,
      ]],
      signer_phone: ['', [Validators.required,
      Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      Validators.minLength(9),
      Validators.maxLength(9),]],
      signer_country: '+34',
      newUser: true
    });
  }

  buildCoordinates(index: number) {
    let y = 30 * index;
    y *= (297 / 585)
    return this.formBuilder.group({
      id: '0',
      page: '',
      posX: 0,
      posY: y
    });
  }

  addSigner() {
    if (this.doneImageConvert != 'done') {
      this.showMsgSnack('Cargar archivo');
    } else {
      const cc = this.countSigners;
      let signerArray = this.documentForm.controls.signers as FormArray;
      if (signerArray.length >= 29) {
        this.showMsgSnack("Cantidad de firmantes superado");
      } else {
        let signerForm = this.buildSigner();
        if (this.countSigners == 0) {
          this.filterOptionsSigners[0] = (signerForm.controls.signer_document.valueChanges
            .pipe(
              startWith(''),
              map(val => this._filterSigners(val,0))
            )
          )
        } else {
          this.filterOptionsSigners.push(signerForm.controls.signer_document.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value),
              map(idn => idn ? this._filterSigners(idn, cc) : this.options.slice())
            )
          )
        }
        signerArray.push(signerForm);
        this.countSigners++
        this.arraySigners.push({'x': 78*(this.countSigners%5), 'y': 30*Math.floor(this.countSigners/5)});
        this.addCoordinates(this.countSigners);
        this.select(Number(this.documentForm.value['signatureType']));
      }
    }
  }

  addCoordinates(index: number) {
    let coordinatesArray = this.documentForm.controls.coordinates as FormArray;
    let coordinatesForm = this.buildCoordinates(index);
    this.countCoordindates++
    coordinatesArray.push(coordinatesForm);
  }

  //Firmantes
  select(value: number) {
    //si no es un procesoBatch
    if (this.checkSeveralSigner) {
      //Si es firma Biométrica
      if (value == 3) {
        this.documentForm.get('device').clearValidators()
        this.documentForm.controls.device.updateValueAndValidity()
      } else {
        this.documentForm.get('device').setValidators([Validators.required]);
        this.documentForm.controls.device.updateValueAndValidity()
      }
    }
  }

  removeSigner(index: number) {
    let signers = this.documentForm.get('signers') as FormArray;
    let signerRemove = signers.at(index) as FormGroup;
    if (signerRemove.controls['id'].value != '0') {
      this.signersToDelete.push(<number>signerRemove.controls['id'].value);
      this.select(Number(this.documentForm.value['signatureType']))
    }
    signers.removeAt(index);
    this.removeCoordinates(index);
    this.countSigners--
  }

  removeCoordinates(index: number) {
    let coordinates = this.documentForm.get('coordinates') as FormArray;
    this.arraySigners.pop();
    let coordinatesRemove = coordinates.at(index) as FormGroup;
    if (coordinatesRemove.controls['id'].value != '0') {
      this.coorsToDelete.push(<number>coordinatesRemove.controls['id'].value);
    }
    coordinates.removeAt(index);
    this.countCoordindates--
  }


  getSigners(): FormArray {
    return this.documentForm.get("signers") as FormArray;
  }

  getCoordinates(): FormArray {
    return this.documentForm.get("coordinates") as FormArray;
  }

  isValidFile() {
    if (this.reader.readyState != 2 || !this.validFile) {
      return false;
    }
    return true;
  }

  //Crear Objeto firmatens para enviar a proceso Batch
  private matchDataSeveralSigners = () => {
    this.signerBatch.splice(0, 1)
    let documentSignersBatch = [new SignerBatch()];
    let promise = new Promise((resolve, reject) => {
      this.signerBatch.forEach((element, index) => {
        element.device = this.documentForm.controls['device'].value;
        documentSignersBatch.push(element)
      });
      documentSignersBatch.splice(0, 1);
      resolve(documentSignersBatch);
    });
    return promise;
  };

  checkSendEmail(value: any) {
    this.sendEmail = value.currentTarget.checked;
  }

  checkSendEmailToCreator(value: any) {
    this.sendEmailCreator = value.currentTarget.checked;
  }

  checkOrderedSignatures(value: any) {
    this.orderedSignatures = value.currentTarget.checked;
  }

  uploadSigner(index: number) {
    const myForm = (<FormArray>this.documentForm.get("signers")).at(index);
    let idn = myForm.value['signer_document'];
    let optionFilter = this.options;
    const filterValue = idn.toLowerCase();
    const returnFilter = optionFilter.filter(option => option.idn.toLowerCase().indexOf(filterValue) === 0);
    if (returnFilter.length > 0) {
      if (idn == returnFilter[0].idn) {
        myForm.patchValue({
          signer_documet_type: returnFilter[0].typeOfIdn,
          signer_name: returnFilter[0].name,
          signer_last_name: returnFilter[0].lastName,
          signer_mail: returnFilter[0].email,
          signer_phone: returnFilter[0].phone,
          newUser: false
        });
      } else {
        this.clearSigner(myForm);
      }
    }
    return returnFilter
  }

  _filterSigners(idn: string, index: number) {
    const myForm = (<FormArray>this.documentForm.get("signers")).at(index);
    let optionFilter = this.options;
    const filterValue = idn != null ? idn.toLowerCase() : "";
    const returnFilter = optionFilter.filter(option => option.idn.toLowerCase().indexOf(filterValue) === 0);
    if (returnFilter.length > 0) {
      if (idn == returnFilter[0].idn) {
        myForm.patchValue({
          signer_documet_type: returnFilter[0].typeOfIdn,
          signer_name: returnFilter[0].name,
          signer_last_name: returnFilter[0].lastName,
          signer_mail: returnFilter[0].email,
          signer_phone: returnFilter[0].phone,
          signer_country : returnFilter[0].countryPhone,
          newUser: false
        });
      } else {
        this.clearSigner(myForm);
      }
    }
    return returnFilter;
  }

  clearSigner(myForm) {
    myForm.patchValue({
      signer_documet_type: '',
      signer_name: "",
      signer_last_name: "",
      signer_mail: "",
      signer_phone: "",
      newUser: false
    });
  }


  displayContextMenu(event, index) {
    if(this.indexDisplayContextMenu != index){
      this.isDisplayContextMenu[this.indexDisplayContextMenu].visible =  false;
    }
    this.isDisplayContextMenu[index].visible =  true;
    this.indexDisplayContextMenu = index;

    this.rightClickMenuItems = [
      {
        menuText: 'Fijar',
        menuEvent: 'Handle refactor',
      },
      {
        menuText: 'Mover',
        menuEvent: 'Handle format',
      },
    ];
  }

  getRightClickMenuStyle() {
    return {
      position: 'absolute',
      top: `15px`,
      left: `58px`
    }
  }

  handleMenuItemClick(event, data) {

    switch (event.data) {
      case this.rightClickMenuItems[0].menuEvent:
        this.isDisplayContextMenu[data].locked = true
        if(data == 0){
          this.coordinatePage = this.pageImagePdf + 1;
        }else{
          this.documentForm.get('coordinates').value[data-1].page = this.pageImagePdf + 1;
        }
           break;
      case this.rightClickMenuItems[1].menuEvent:
        this.isDisplayContextMenu[data].locked = false
        break;
    }
  }

  @HostListener('click', ['$event.target']) onClick(e) {
    if(!e['className'].includes('cdk-drag element example-box')){
      this.isDisplayContextMenu[this.indexDisplayContextMenu].visible = false;
    }
  }

}
