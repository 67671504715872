<div class="wrapper">
  <div class="cuerpo">
    <div class="documento">
      <app-spinner *ngIf="document == undefined"></app-spinner>

      <div *ngIf="document != undefined">
        <h1 class="tp-navandu line-navandu">Documento {{ document.uuid }}</h1>

        <div class="box">
          <div class="group">
            <span class="label">
              Uuid plantilla:
            </span>
            <span class="data">
              {{ document.templateUuid }}
            </span>
          </div>

          <div class="group">
            <span class="label">
              Nombre plantilla:
            </span>
            <span class="data">
              {{ document.templateName }}
            </span>
          </div>

          <div class="group">
            <span class="label">
              Estado:
            </span>
            <span class="data {{document.status.name}}">
              {{ document.status.nameEs }}
            </span>
          </div>

          <div class="group" *ngIf="document.status.id == 6">
            <span class="label">
              Mensaje de error:
            </span>
            <span class="data {{document.status.name}}">
              {{ document.status.message | errormessage }}
            </span>
          </div>

          <div class="group" *ngIf="document.status.id == 3 && document.rejectReason">
            <span class="label">
              Motivo de rechazo:
            </span>
            <span class="data" *ngIf="flagString; else elseBlock">
              {{ document.rejectReason[0].reason }}
            </span>
            <ng-template #elseBlock>
              <span class="data">{{ document.rejectReason }}</span>
            </ng-template>
          </div>

          <div class="group">
            <span class="label">
              Fecha creación:
            </span>
            <span class="data">
              {{ document.creationDate }}
            </span>
          </div>

          <div class="group">
            <span class="label">
              Fecha firma:
            </span>
            <span class="data">
              {{ document.signatureDate }}
            </span>
          </div>

          <div class="group">
            <span class="label">
              Tipo de documento:
            </span>
            <span class="data">
              {{ document.documentType }}
            </span>
          </div>

          <div class="group">
            <span class="label">
              Nombre de fichero:
            </span>
            <span class="data">
              {{ document.filename }}
            </span>
          </div>

          <div class="group">
            <span class="label">
              Expediente:
            </span>
            <span class="data">
              {{ document.expedient }}
            </span>
          </div>
        </div>

        <div class="box" *ngFor="let signer of document.signers">
          <h3>Firmante {{ signer.name }} {{ signer.lastName }}</h3>

          <div class="group">
            <span class="label">
              Estado:
            </span>
            <span class="data {{signer.state.name}}" *ngIf="signer.state">
              {{ signer.state.nameEs }}
            </span>
          </div>

          <div class="group">
            <span class="label">
              Identificador:
            </span>
            <span class="data">
              {{ signer.idn }}
            </span>
          </div>

          <div class="group">
            <span class="label">
              Email:
            </span>
            <span class="data">
              {{ signer.email }}
            </span>
          </div>

          <div class="group">
            <span class="label">
              Móvil:
            </span>
            <span class="data">
              {{ signer.phone }}
            </span>
          </div>

          <div class="group">
            <span class="label">
              Dispositivo:
            </span>
            <span class="data">
              {{ signer.device }}
            </span>
          </div>
        </div>

        <div class="box" *ngIf="document.signers[0].vidForms.length > 0">
          <h3>Formularios</h3>
          <div *ngFor="let form of document.signers[0].vidForms">
            <div class="group">
              <span class="label">{{ form.title }}</span>
              <span clas="data">{{ form.response }}</span>
            </div>
          </div>
        </div>

        <div class="box" *ngIf="document.signers[0].forms.length > 0">
          <h3>Formularios</h3>
          <div *ngFor="let form of document.signers[0].forms">
            <div class="group">
              <span class="label">{{ form.text }}</span>
              <span clas="data">{{ form.value }}</span>
            </div>
          </div>
        </div>

        <div class="box" *ngIf="document.variableResponses.length  > 0">
          <h3>Variables</h3>
          <div *ngFor="let variable of document.variableResponses">
            <div class="group">
              <span class="label">{{ variable.name }}</span>
              <span clas="data">{{ variable.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a *ngIf="backLink != undefined" routerLink="{{backLink}}" class="volver">
      <span>Volver</span>
    </a>
  </div>
</div>
