import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { PageDocument } from 'src/app/models/pageDocument.model'
import { Document } from 'src/app/models/document.model'
import { Signer } from '../models/guest/signer.model';
import { GlobalVariable } from '../models/guest/globalvariable.model';

@Injectable({
  providedIn: 'root'
})
export class GuestsService {
  private basePath = environment.uriApiAzure;

  constructor(private http: HttpClient) { }

  public getSigner(): Observable<Signer> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Signer>(this.basePath + '/guest/signer', httpOptions);
  }

  public getDocuments(): Observable<PageDocument[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<PageDocument[]>(this.basePath + '/guest/documents', httpOptions);
  }

  public getDocumentByUuid(uuid): Observable<Document> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Document>(this.basePath + '/guest/document/' + uuid, httpOptions);
  }

  public putDocument(id, data): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.put(this.basePath + '/guest/document/' + id, JSON.stringify(data), { headers: headers, observe: 'response' });
  }

  public getGlobalVariables(id): Observable<Array<GlobalVariable>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Array<GlobalVariable>>(this.basePath + `/guest/document/${id}/globalvariables`, httpOptions);
  }

  public postChangePassword(data): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.post(this.basePath + '/guest/password/', JSON.stringify(data), { headers: headers, observe: 'response' });
  }

  public putNewPasssword(data): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.put(this.basePath + '/guest/newpassword/', JSON.stringify(data), { headers: headers, observe: 'response' });
  }

  public putCheckRgpd(data): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.put(this.basePath + '/guest/rgpd/', JSON.stringify(data), { headers: headers, observe: 'response' });
  }

  public getVIDCredentials(code): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Object>(this.basePath + `/guest/vidcredentials/${code}`, httpOptions);
  }


}
