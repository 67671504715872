import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {ApiTemplatesService} from 'src/app/service/api-templates.service'
import {Template} from 'src/app/models/template.model'
import {UserJS} from 'src/app/models/userJS.model'
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';

export interface Variable {
  id: number,
  name: string,
  type: string,
  anchor: string,
  uuid: string,
  value:string,
}

@Component({
  selector: 'app-viewtemplate',
  templateUrl: './viewtemplate.component.html',
  styleUrls: ['./viewtemplate.component.css']
})

export class ViewtemplateComponent implements OnInit {

  dataUser: UserJS = JSON.parse(localStorage.getItem("user"));
  id: string;
  template: Template;
  variablesUser = []

  constructor(private templateService: ApiTemplatesService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService
    ) {
      this.activatedRouter.params.subscribe(parametros => {
        this.id = parametros['id'];
      })
     }

  ngOnInit(): void {
    this.templateService.getTemplate(this.id).subscribe(dataTemplate => {
      if (dataTemplate != null) {
        this.template = dataTemplate;
        this.breadcrumbService.setElements([
          { name: 'Plantillas', link: '/templates' },
          { name: this.template.name, link: `/templates/${this.id}`}
        ]);
        for (let variable of this.template.variables) {
          if (variable.type != 'form') {
            this.postDataUsers(variable)
          } else {
            this.postDataForm(variable)
          }
        }
      }
    },
      error => {
        if(error.status == 401){
          this.router.navigate(['/login']);
          console.log(error)
        }else{
          var texto = error
          console.log(texto)
        }
      }

      //data => this.correctLoginn(data),
      //error => this.error = JSON.parse(error._body)
    )
  }

  postDataUsers(variable: Variable) {
    switch (variable.anchor) {
      case "%business_name%":
        this.variablesUser.push({ uuid: variable.uuid, value: this.dataUser.businessName })
        this.template.content = this.template.content.replace('%business_name%', this.dataUser.businessName)
        break;
      case "%email%":
        this.variablesUser.push({ uuid: variable.uuid, value: this.dataUser.email })
        this.template.content = this.template.content.replace('%email%', this.dataUser.email)
        break;
      case "%direction%":
        this.variablesUser.push({ uuid: variable.uuid, value: this.dataUser.direction })
        this.template.content = this.template.content.replace('%direction%', this.dataUser.direction)
        break;
      case "%url%":
        this.variablesUser.push({ uuid: variable.uuid, value: this.dataUser.url })
        this.template.content = this.template.content.replace('%url%', this.dataUser.url)
        break;
      case "%cif%":
        this.variablesUser.push({ uuid: variable.uuid, value: this.dataUser.cif })
        this.template.content = this.template.content.replace('%cif%', this.dataUser.cif)
        break;
      case "%phone%":
        this.variablesUser.push({ uuid: variable.uuid, value: this.dataUser.phone })
        this.template.content = this.template.content.replace('%phone%', this.dataUser.phone)
        break;
      case "%nif%":
        this.variablesUser.push({ uuid: variable.uuid, value: this.dataUser.nif })
        this.template.content = this.template.content.replace('%nif%', this.dataUser.nif)
        break;
      case "%logo%":
        this.variablesUser.push({ uuid: variable.uuid, value: this.dataUser.nif })
        this.template.content = this.template.content = this.template.content.replace(variable.anchor, variable.value)
        break;

      default:
        break;
    }

  }

  postDataForm(variable: Variable){
    this.template.content = this.template.content = this.template.content.replace(variable.anchor, variable.value)
  }

}
