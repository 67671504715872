import { CdkDragEnd } from "@angular/cdk/drag-drop";
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentImage } from 'src/app/models/documentImage.model';
import { DocumentTypeModel } from 'src/app/models/documentType.model';
import { Template } from 'src/app/models/template.model';
import { ApiTemplatesService } from 'src/app/service/api-templates.service';
import { ApiDocumentsService } from '../../service/api-documents.service';
import { UsersService } from '../../service/users.service';
import { VariableEntity } from '../../entity/variable.entity';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { ApiProcessService } from '../../service/api-process.service';
export interface Device {
  deviceDescription: string,
  deviceName: number
}

@Component({
  selector: 'app-resenddocumentprocess',
  templateUrl: './resenddocumentprocess.component.html',
  styleUrls: ['./resenddocumentprocess.component.css']
})

export class ResenddocumentprocessComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Elija un archivo';

  loading: boolean = true;
  documentForm:FormGroup = new FormGroup({
    signatureType: new FormControl<number>(1, [
      Validators.required,

    ]),
    emailRemote: new FormControl('', []),
    device: new FormControl('', []),
  });

  emailOptions: string[] = [];
  idProcess: string;
  idTemplate: string;
  idDocumento: string;
  documento;
  buttonDisable = false;
  template: Template = new Template();
  devices: Device[];
  signatureChanels: DocumentTypeModel[];
  signer: [VariableEntity] = [new VariableEntity()];
  showSignatureChanels: Boolean = true;
  imageConverter: [DocumentImage];
  doneImageConvert
  imagepdf: string
  pageImagePdf: number = 0
  lengthPageImagePdf: number = 0
  coordinatePosX = 0
  coordinatePosY = 0
  validFile = false;
  img = new Image;

  constructor(
    private templateService: ApiTemplatesService,
    private documentsService: ApiDocumentsService,
    private userService: UsersService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private processService: ApiProcessService,
    private breadcrumbService: BreadcrumbService,
    private _snackBar: MatSnackBar) {
    this.activatedRouter.params.subscribe(parametros => {
      this.idProcess = parametros['idProcess'];
      this.idDocumento = parametros['idDocumento'];
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.img.width = 1240;
    this.img.height = 1754;

    this.documentsService.getDocument(this.idDocumento).subscribe(document => {
      this.documento = document.body;
      this.converter(this.idDocumento);
      this.loading = false;

      this.documento.signers.forEach(signer => {
        if (!this.emailOptions.includes(signer.email)) {
          this.emailOptions.push(signer.email);
        }
      });
      this.processService.getProcessbyTemplateUUid(this.idProcess, this.documento.templateUuid).subscribe(dataProcessTemplate => {
            this.breadcrumbService.setElements([
              { name: 'Procesos', link: '/processes' },
              { name: dataProcessTemplate.processConfig.name, link: `/processes/${this.idProcess}` },
              { name: this.documento.templateName, link: `/processes/${this.idProcess}/resend/${this.idDocumento}` }
            ]);
          }
        );

      //Obtiene canales de firmas disponibles para el usuario logueado
      this.userService.getUser().subscribe(dataUser => {
        if (dataUser != null) {
          switch (this.documento.template.templateType.name) {
            case 'SetSigner':
              this.signatureChanels = [{ id: 3, name: "remote", active: true }];
              this.documentForm.controls.signatureType.setValue(3);
              break;
            case 'SetDown':
              this.signatureChanels = [{ id: 3, name: "remote", active: true }];
              this.documentForm.controls.signatureType.setValue(3);
              break;
            case 'SetUp':
              this.signatureChanels = [{ id: 1, name: "analog", active: true }];
              this.documentForm.controls.signatureType.setValue(1);
              this.showSignatureChanels = false;
              break;
            default:
              this.signatureChanels = dataUser.documentTypesDtos;
              break;
          }
        }
      }, error => this.onError(error));
  });
    //Obtiene dispositivos asociados a un usuario
    this.templateService.getDevices().subscribe(dataDevices => {
      if (dataDevices != null) {
        this.devices = dataDevices;
      }
    }, error => this.onError(error));

  }

  converter(uuid: string) {
    this.documentsService.postDocumentConverterUUID(uuid).subscribe(dataConverter => {
      if (dataConverter.status == 200) {
        this.imageConverter = dataConverter.body;
        this.img.src = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.lengthPageImagePdf = this.imageConverter.length
        this.doneImageConvert = "done"
        this.validFile = true;
      }
    }, error => {
      this.buttonDisable = false;
      this.doneImageConvert = "done"
      this.onError(error);
    });
  }

  changePage(direction) {
    if (direction == "next") {
      if (this.pageImagePdf < this.lengthPageImagePdf - 1) {
        this.pageImagePdf = this.pageImagePdf + 1
        this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.img.src = this.imagepdf;
      }
    }
    else {
      if (this.pageImagePdf != 0) {
        this.pageImagePdf = this.pageImagePdf - 1
        this.imagepdf = 'data:image/png;base64,' + this.imageConverter[this.pageImagePdf].content;
        this.img.src = this.imagepdf;
      }
    }
  }

  onSubmit() {
    let documentSignerDTOs = {
      "device": this.documentForm.controls['device'].value,
      "uuid": "",
      "idn": "",
      "typeOfIdn": "",
      "name": "",
      "email": this.documentForm.controls['emailRemote'].value,
      "phone": "",
      "externalUrl": "",
      "forms": null,
      "vidForms": null,
    }

    if (this.documento.template.templateType.name == 'File') {
      if (this.img.height > 297) {
        this.coordinatePosY = this.coordinatePosY
      }

      documentSignerDTOs["coordinates"] = { "page": this.pageImagePdf + 1, "posX": this.coordinatePosX, "posY": this.coordinatePosY + 7 }
    }

    this.resignVidForms(this.documento.uuid, documentSignerDTOs);

  }

  resignVidForms(documentUUID, dataToSend) {
    //Envía documento a firmar
    this.showMsgSnack('Documento reenviado');
    this.documentsService.postDocumentInProcessResign(documentUUID, dataToSend).subscribe(dataDService => {
      if (dataDService.status == 200) {
        this.router.navigate(['/processes/' + this.idProcess]);
      }
    }, error => {
      this.onError(error);
    });
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error.');
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  dragEnd(event: CdkDragEnd) {
    this.coordinatePosX = event.source.getFreeDragPosition()['x'] < 0 ? 0 : event.source.getFreeDragPosition()['x'] * (210 / this.img.width)
    this.coordinatePosY = event.source.getFreeDragPosition()['y'] < 0 ? 0 : event.source.getFreeDragPosition()['y'] * (297 / this.img.height)
  }

}
