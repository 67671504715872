import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ApiDocumentsService } from 'src/app/service/api-documents.service';
import { DocumentSigned } from 'src/app/models/documentSigned.model';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';

@Component({
  selector: 'app-document-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DocumentDetailsComponent implements OnInit {

  uuid: string;
  backLink: string;
  document: DocumentSigned;
  flagString: boolean = false;

  constructor(
    private activatedRouter: ActivatedRoute,
    private documentService: ApiDocumentsService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.activatedRouter.params.subscribe(params => {
      this.uuid = params.id;
    });
  }

  ngOnInit() {
    this.backLink = '/documents';
    this.documentService.getDocument(this.uuid).subscribe(data => {
      this.document = data.body;
      if (data.body.rejectReason != null) {
        if (this.flagString = data.body.rejectReason.toString().includes("[{")) {
          this.document.rejectReason = JSON.parse(this.document.rejectReason + '');
        }
      }
      this.documentService.getDocumentInfo(this.uuid).subscribe(data => {
        data.body.signers.forEach(signer => {
          this.document.signers.find(s => s.uuid == signer.uuid)['state'] = signer.status
        });
      })
      this.breadcrumbService.setElements([
        { name: 'Documentos', link: '/documents' },
        { name: this.document.templateName, link: `/documents/${this.uuid}/details` }
      ]);
    });
  }
}
