<div class="wrapper">

    <div class="cuerpo">

      <h1 class="tp-navandu line-navandu">PROCESOS</h1>
      <div class="input-field col s12 buscador">

        <input id="filter" type="text" (keyup)="applyFilter($event)">
        <label for="filter" data-error="Ingrese un dato valido" data-success=""> <i
            class="material-icons">search</i></label>
      </div>

      <app-spinner *ngIf="loading"></app-spinner>

      <table mat-table [dataSource]="dataSource" class="tabla tabla-procesos" *ngIf="!loading">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
          <td mat-cell *matCellDef="let element"> {{element.processConfig.name}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Descripción </th>
          <td mat-cell *matCellDef="let element"> {{element.processConfig.description}} </td>
        </ng-container>


        <ng-container matColumnDef="expedient">
          <th mat-header-cell *matHeaderCellDef> Expediente </th>
          <td mat-cell *matCellDef="let element"> {{element.expedient}} </td>
        </ng-container>


        <ng-container matColumnDef="signer">
          <th mat-header-cell *matHeaderCellDef> Firmante </th>
          <td mat-cell *matCellDef="let element"> {{element.signer.name + " " + element.signer.lastName}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Estado </th>
          <td mat-cell *matCellDef="let element"> {{element.processStatus.nameEs}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Acción </th>
          <td mat-cell *matCellDef="let element">
            <button (click)="view(element.uuid)" mat-icon-button
              aria-label="Example icon button with a vertical three dot icon">
              <div class="tooltip3">
                <mat-icon>remove_red_eye</mat-icon>
                <span class="tooltiptext3">Detalles</span>
              </div>
            </button>
            <button mat-icon-button class="refrescar" (click)="downloadProcessDocument(element.uuid, element.expedient)">
              <div class="tooltip3">
                  <mat-icon>download</mat-icon>
                  <span class="tooltiptext3">Descargar Documentos</span>
              </div>
          </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>
  </div>
