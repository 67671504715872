<div class="wrapper">
  <div class="cuerpo">
    <app-spinner *ngIf="loading"></app-spinner>

    <div *ngIf="!loading">
      <div class="row documento">
        <h2> INFORMACIÓN DEL USUARIO </h2>
        <form name="entrar" class="formulario" autocomplete="off" [formGroup]="accountForm" (ngSubmit)="onSubmit()">

          <div class="flex-row">
            <mat-form-field class="example-full-width">
              <mat-label>Cif</mat-label>
              <input type="text" matInput formControlName="cif" required>
              <mat-error *ngIf="accountForm.controls.cif.hasError('required')">
                Cif <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Nombre usuario</mat-label>
              <input matInput formControlName="name" required>
              <mat-error *ngIf="accountForm.controls.name.hasError('required')">
                Nombre de usuario <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex-row">
            <mat-form-field>
              <mat-label>Teléfono</mat-label>
              <input matInput formControlName="phone">
              <mat-error *ngIf="accountForm.controls.phone.hasError('required')">
                Móvil <strong>requerido</strong>
              </mat-error>
              <mat-error *ngIf="accountForm.controls.phone.hasError('minlength') || accountForm.controls.phone.hasError('maxlength')">
                Móvil <strong>debe tener 9 caracteres</strong>
              </mat-error>
              <mat-error *ngIf="accountForm.controls.phone.hasError('pattern')">
                Móvil debe iniciar con <strong>'+'</strong> y el <strong>indicativo</strong> del país, <strong>debe ser numérico</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="email">
              <mat-label>Correo</mat-label>
              <input matInput placeholder="Ex. pat@example.com" formControlName="email">
              <mat-error *ngIf="accountForm.controls.email.hasError('email') && !accountForm.controls.email.hasError('required')">
                Por favor ingrese un correo válido
              </mat-error>
              <mat-error *ngIf="accountForm.controls.email.hasError('required')">
                Correo <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex-row">
            <mat-form-field>
              <mat-label>Nombre empresa</mat-label>
              <input matInput formControlName="businessName">
              <mat-error *ngIf="accountForm.controls.businessName.hasError('required')">
                Nombre empresa <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="email">
              <mat-label>Dirección</mat-label>
              <input matInput formControlName="direction">
              <mat-error *ngIf="accountForm.controls.direction.hasError('required')">
                Dirección <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex-row">
            <mat-form-field class="email">
              <mat-label>Correo remitente</mat-label>
              <input matInput placeholder="Ex. pat@example.com" formControlName="emailSender">
              <mat-error *ngIf="accountForm.controls.emailSender.hasError('email') && !accountForm.controls.emailSender.hasError('required')">
                Por favor ingrese un correo válido
              </mat-error>
              <mat-error *ngIf="accountForm.controls.emailSender.hasError('required')">
                Correo remitente <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="email">
              <mat-label>Cuerpo de correo</mat-label>
              <input matInput placeholder="Ex. pat@example.com" formControlName="emailBody">
              <mat-error *ngIf="accountForm.controls.emailBody.hasError('email') && !accountForm.controls.emailBody.hasError('required')">
                Por favor ingrese un correo válido
              </mat-error>
              <mat-error *ngIf="accountForm.controls.emailBody.hasError('required')">
                Cuerpo de Correo <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex-row">
            <mat-form-field>
              <mat-label>Url</mat-label>
              <input matInput formControlName="url">
              <mat-error *ngIf="accountForm.controls.url.hasError('required')">
                Url <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Url logo</mat-label>
              <input matInput formControlName="urlLogo">
              <mat-error *ngIf="accountForm.controls.urlLogo.hasError('required')">
                Url logo <strong>requerido</strong>
              </mat-error>
              <mat-error *ngIf="accountForm.controls.urlLogo.hasError('pattern')">
                Url logo <strong>debe ser una url valida</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="enviar2">
            <input type="submit" class="boton volver2" value="Guardar" [disabled]="!this.accountForm.dirty" (mouseover)="click()">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="ver-firmante contentPassword" *ngIf="viewForm">
  <form name="entrar" class="formulario form-gen-doc changePassword" autocomplete="off" [formGroup]="passForm" (ngSubmit)="updatePassword()">
    <div class="row documento">
      <div class="flex-row filePassword">
        <mat-form-field class="example-full-width">
          <mat-label>Clave actual</mat-label>
          <input type="password" matInput formControlName="oldPassword" required>
          <mat-error *ngIf="passForm.controls.oldPassword.hasError('required')">
            Clave actual <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex-row filePassword">
        <mat-form-field class="example-full-width">
          <mat-label>Nueva clave</mat-label>
          <input type="password" matInput formControlName="newPassword" required>
          <mat-error *ngIf="passForm.controls.newPassword.hasError('required')">
            Nueva clave <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex-row filePassword">
        <mat-form-field class="example-full-width">
          <mat-label>Confirmar clave</mat-label>
          <input type="password" matInput formControlName="confirmPassword" required>
          <mat-error *ngIf="passForm.controls.confirmPassword.hasError('required')">
            Confirmación de clave <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="enviar2">
      <input type="button" (click)="cancel()" class="boton volver2" value="Cancelar">
      <input type="submit" class="boton volver2" value="Guardar">
    </div>

  </form>

</div>
