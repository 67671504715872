import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs'
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { UserConsents } from '../models/user-consents.model';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutenticationService {

  private basePath = environment.uriApiAzureLogin;
  private loggedIn = new BehaviorSubject<boolean>(false);
  private loggedInGuest = new BehaviorSubject<boolean>(false);
  public loggedUser = new BehaviorSubject<any>({});

  constructor(private http: HttpClient,
    private router: Router) { }

  login(userdata): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    return this.http.post(this.basePath + '/login', JSON.stringify(userdata), { headers: headers, observe: 'response' })
  }

  loginGuest(data): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    return this.http.post(this.basePath + '/loginguest', JSON.stringify(data), { headers: headers, observe: 'response' })
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  changePassword(userdata: UserConsents): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.put(this.basePath + '/clients/users/edit-password', JSON.stringify(userdata), { headers: headers, observe: 'response' })
  }

  get isLoggedInGuest() {
    return this.loggedInGuest.asObservable();
  }

  isLogged() {
    if (localStorage.getItem("user") != null) {
      this.loggedIn.next(true);
      this.loggedUser.next(JSON.parse(localStorage.getItem("user")));
      return true;
    } else {
      this.loggedIn.next(false);
      this.loggedUser.next(null);
      return false;
    }
  }

  isLoggedGuest() {
    if (localStorage.getItem("guest") != null) {
      this.loggedInGuest.next(true);
      this.loggedUser.next(JSON.parse(localStorage.getItem("guest")));
      return true;
    } else {
      this.loggedInGuest.next(false);
      this.loggedUser.next(null);
      return false;
    }
  }

  logout() {
    this.loggedIn.next(false);
    this.loggedInGuest.next(false);
    this.loggedUser.next(null);
    this.router.navigate(['/login']);
  }
}
