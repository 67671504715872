import {VariableEntity} from 'src/app/entity/variable.entity'
import {TemplateTypeEntity} from 'src/app/entity/templateType.entity'

export class Template {
    public id: number;
    public uuid: string;
    public name: string;
    public description: string;
    public variables: [VariableEntity];
    public templateType: TemplateTypeEntity;
    public self: boolean;
    public active: boolean;
    public content: string;
    public batch: boolean;
    public checked: boolean = false;
    public orderNumber: number;
    public numDocs: number;
}
