import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiDocumentsService } from 'src/app/service/api-documents.service'
import { Document } from 'src/app/models/document.model'
import { DocumentInfo } from 'src/app/models/documentInfo.model'
import { Pageable } from 'src/app/models/pageable.model'
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { file } from 'jszip';


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  loading: boolean = true;
  loadingDataTable: boolean = false;
  pageable = new Pageable();
  status = 0;
  viewRejectReson = false;
  viewDocumentInfo = false;
  msgRejectReason: [{ reason: string, signerName: string }];
  documentInformation: DocumentInfo;
  displayedColumns: string[] = ['expedient', 'documentAddressee', 'user', 'template', 'creationDate', 'signatureDate', 'view'];
  dataSource = new MatTableDataSource();
  textToSearch = "";

  constructor(
    private documentService: ApiDocumentsService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit() {
    this.breadcrumbService.setElements([]);
    this.loading = true;
    this.textToSearch = ""
    this.documentService.getDocuments().subscribe(dataDocument => {
      if (dataDocument != null) {
        let ELEMENT_DATA2: Document[] = dataDocument["content"];
        this.pageable = dataDocument["pageable"];
        this.pageable.first = dataDocument["first"]
        this.pageable.last = dataDocument["last"]
        this.pageable.totalPages = dataDocument["totalPages"] - 1
        this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
        this.loading = false;
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
          console.log(error)
        } else {
          var texto = error
          console.log(texto)
        }
      }

    )

  }

  resignVidForms(document) {
    this.router.navigate(['/documents/resend/' + document.uuid]);
  }

  applyFilter(event) {
    if (event == 13) {
      this.pageable.pageNumber = 0
      this.search()
    }
  }

  /////////////////////////////
  /////Paginator Functions/////
  /////////////////////////////
  first() {
    this.pageable.pageNumber = 0
    this.search()
  }
  next() {
    this.pageable.pageNumber = this.pageable.pageNumber + 1
    this.search()
  }
  previus() {
    this.pageable.pageNumber = this.pageable.pageNumber - 1
    this.search()
  }
  end() {
    this.pageable.pageNumber = this.pageable.totalPages
    this.search()
  }
  itemsByPage() {
    this.search()
  }

  /////////////////////////////
  ///////Sort Functions////////
  /////////////////////////////
  sortData(sort: MatSort) {
    switch (sort["active"]) {
      case "documentAddressee":
        this.pageable.order = "documentAddressee.name"
        break;
      case "template":
        this.pageable.order = "template.name"
        break;
      default:
        this.pageable.order = sort["active"]
        break;
    }
    this.pageable.direction = sort["direction"]
    this.search()
  }

  /////////////////////////////

  search() {
    this.loadingDataTable = true;
    this.dataSource = new MatTableDataSource();
    let filter = this.textToSearch
    if (this.status == 0) {
      this.documentService.getDocumentsFilters(filter, this.pageable).subscribe(dataDocument => {   // data is of type HttpResponse<Object>
        if (dataDocument != null) {
          let ELEMENT_DATA2: Document[] = dataDocument["content"];
          this.pageable.first = dataDocument["first"]
          this.pageable.last = dataDocument["last"]
          this.pageable.totalPages = dataDocument["totalPages"] - 1
          this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
          this.loadingDataTable = false;
        }
      },
        error => {
          if (error.status == 401) {
            this.router.navigate(['/login']);
            console.log(error)
          } else {
            var texto = error
            console.log(texto)
          }
        }
      )
    } else {
      this.documentService.getDocumentsbyStatusFilters(this.status, filter, this.pageable).subscribe(dataDocument => {   // data is of type HttpResponse<Object>
        if (dataDocument != null) {
          let ELEMENT_DATA2: Document[] = dataDocument["content"];
          this.pageable = dataDocument["pageable"];
          this.pageable.first = dataDocument["first"]
          this.pageable.last = dataDocument["last"]
          this.pageable.totalPages = dataDocument["totalPages"] - 1
          this.dataSource.data.values = new MatTableDataSource(ELEMENT_DATA2).data.values;
          this.loadingDataTable = false;
        }
      },
        error => {
          if (error.status == 401) {
            this.router.navigate(['/login']);
            console.log(error)
          } else {
            var texto = error
            console.log(texto)
          }
        }
      )

    }

  }

  filterStatus(event: Event) {
    this.loading = true;
    const value = Number((event.target as HTMLInputElement).value);
    this.status = value;
    if (value != 0) {
      if (this.textToSearch != null && this.textToSearch != "") {
        this.documentService.getDocumentsbyStatusFilters(value, this.textToSearch, this.pageable).subscribe(dataDocument => {   // data is of type HttpResponse<Object>
          if (dataDocument != null) {
            let ELEMENT_DATA2: Document[] = dataDocument["content"];
            this.pageable = dataDocument["pageable"];
            this.pageable.first = dataDocument["first"]
            this.pageable.last = dataDocument["last"]
            this.pageable.totalPages = dataDocument["totalPages"] - 1
            this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
            this.loading = false;
          }
        },
          error => {
            if (error.status == 401) {
              this.router.navigate(['/login']);
            } else {
              var texto = error
              console.log(texto)
            }
          }
        )
      } else {
        this.documentService.getDocumentsbyStatus(value).subscribe(dataDocument => {   // data is of type HttpResponse<Object>
          if (dataDocument != null) {
            let ELEMENT_DATA2: Document[] = dataDocument["content"];
            this.pageable = dataDocument["pageable"];
            this.pageable.first = dataDocument["first"]
            this.pageable.last = dataDocument["last"]
            this.pageable.totalPages = dataDocument["totalPages"] - 1
            this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
            this.loading = false;
          }
        },
          error => {
            if (error.status == 401) {
              this.router.navigate(['/login']);
            } else {
              var texto = error
              console.log(texto)
            }
          }
        )
      }

    } else {
      this.documentService.getDocuments().subscribe(dataDocument => {
        if (dataDocument != null) {
          let ELEMENT_DATA2: Document[] = dataDocument["content"];
          this.pageable = dataDocument["pageable"];
          this.pageable.first = dataDocument["first"]
          this.pageable.last = dataDocument["last"]
          this.pageable.totalPages = dataDocument["totalPages"] - 1
          this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
          this.loading = false;
        }
      },
        error => {
          if (error.status == 401) {
            this.router.navigate(['/login']);
          } else {
            var texto = error
            console.log(texto)
          }
        }
      )

    }
  }

  downloadDocument(id) {
    this.documentService.getDocument(id).subscribe(dataDocument => {
      if (dataDocument.status == 200) {
        this.download(dataDocument.body.pdfContent, dataDocument.body.filename, dataDocument.body.mimeType)
      }
    },
      error => {
        if (error.status == 401) {
          console.log(error)
        } else {
          var texto = error
          console.log(texto)
        }
      }
    )
  }

  downloadReport(id) {
    this.documentService.getDocumentReport(id).subscribe(dataDocument => {
      if (dataDocument.status == 200) {
        this.download(dataDocument.body.pdfContent, dataDocument.body.filename, dataDocument.body.mimeType)
      }
    },
      error => {
        if (error.status == 401) {
          console.log(error)
        } else {
          var texto = error
          console.log(texto)
        }
      }

    )
  }

  downloadVidForms(id) {
    this.documentService.getDocumentVidForms(id).subscribe(dataDocument => {
      const pom = document.createElement('a');
      const csvContent = dataDocument.body;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', 'forms.csv');
      pom.click();
    },
      error => {
        console.log(error);
      });
  }

  download(data, filename, mimeType) {
    filename = filename.replace('.', '')
    
    this._snackBar.open('Descargando documento...', '', {
      duration: 2000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
    if (!mimeType) {
      mimeType = 'application/pdf';
    }
    let extension = mimeType.split('/')[1];
    const blobURL = URL.createObjectURL(this.pdfBlobConversion(data, mimeType));
    var fileLink = document.createElement('a');
    fileLink.href = blobURL;
    fileLink.download = filename;
    fileLink.click();
  }

  view(id) {
    this.documentService.getDocument(id).subscribe(dataDocument => {
      if (dataDocument.status == 200) {
        this.printPreview(dataDocument.body.pdfContent, dataDocument.body.filename)
      }
    },
      error => {
        if (error.status == 401) {
          console.log(error)
        } else {
          var texto = error
          console.log(texto)
        }
      }
    )
  }

  viewReport(id) {
    this.documentService.getDocumentReport(id).subscribe(dataDocument => {
      if (dataDocument.status == 200) {
        this.printPreview(dataDocument.body.pdfContent, dataDocument.body.filename)
      }
    },
      error => {
        if (error.status == 401) {
          console.log(error)
        } else {
          var texto = error
          console.log(texto)
        }
      }
    )
  }

  printPreview(data, filename) {

    const blobURL = URL.createObjectURL(this.pdfBlobConversion(data, 'application/pdf'));
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement('script');
    function injectThis() {
      window.print();
    }
    theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
    theDoc.body.appendChild(theScript);
  }


  pdfBlobConversion(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  cancel() {
    this.viewRejectReson = false;
    this.viewDocumentInfo = false;
  }

  viewRejectReason(msg) {
    this.msgRejectReason = JSON.parse(msg);
    this.viewRejectReson = true;
  }

  documentInfo(uuid) {
    this.documentService.getDocumentInfo(uuid).subscribe(dataDocument => {
      if (dataDocument != null) {
        this.viewDocumentInfo = true;
        this.documentInformation = dataDocument.body
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
          console.log(error)
        } else {
          var texto = error
          console.log(texto)
        }
      }
    )
  }

  navigateDocument(uuid) {
    this.router.navigate([`/documents/${uuid}/details`]);
  }
}
