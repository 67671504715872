<div class="wrapper">
  <div class="cuerpo">
    <div class="documento">
      <app-spinner *ngIf="template == undefined"></app-spinner>

      <div *ngIf="template.content != undefined">
        <h1 class="tp-navandu line-navandu">{{template.name}}</h1>
        <div [innerHTML]="template.content"></div>
      </div>
    </div>
    <a routerLink="/templates" class="volver"><span>Volver</span> </a>
  </div>
</div>
