import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AutenticationService } from '../../service/autentication.service';
import { GuestsService } from '../../service/guests.service';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  passwordForm: FormGroup;
  token: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private autenticationService: AutenticationService,
    private guestsService: GuestsService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    localStorage.clear();
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.passwordForm = this.formBuilder.group({
      'username': ['', [
        Validators.required,
      ]
      ],
      'email': ['', [
        Validators.required,
        Validators.email,
      ]
      ]
    })
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      let data = {
        "userName" : this.passwordForm.controls.username.value,
        "email" : this.passwordForm.controls.email.value
      }
      this.guestsService.postChangePassword(data).subscribe(response => {
        if (response.status == 200) {
          this.showMsgSnack('Se ha enviado un mensaje a su correo');
          this.router.navigate(['/login']);
        }
      },
        error => {
          this.onError(error);
        }
      );
    }
  }

  onError(error) {
    this.router.navigate(['/login']);
  }

  updateStyle(user) {
    user.style = "ds";
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

}
