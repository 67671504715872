import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs'

import { environment } from '../../environments/environment';
import { Template } from 'src/app/models/template.model'

export interface Device {
  deviceDescription: string,
  deviceName: number
}
const jsonDevice = {
  user: "D82310699",
  password: "zEfTvzfm47E7R45Ly2WV"
};


@Injectable({
  providedIn: 'root'
})
export class ApiTemplatesService {

  private basePath = environment.uriApiAzure;

  constructor(private http: HttpClient) { }

  getTemplates(): Observable<Template[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Template[]>(this.basePath + '/templates', httpOptions);
  }

  getTemplate(id: string): Observable<Template> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Template>(this.basePath + '/template/' + id, httpOptions);
  }

  getDevices(): Observable<Device[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Device[]>(this.basePath + '/devices', httpOptions);
  }

}
