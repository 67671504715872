<div class="wrapper">
  <div class="cuerpo">

    <h1 class="tp-navandu line-navandu">GENERACIÓN DE DOCUMENTO</h1>

    <app-spinner *ngIf="loading"></app-spinner>

    <form name="entrar" [formGroup]="documentForm" class="formulario" autocomplete="off" *ngIf="!loading">
      <div class="  documento">

        <div class="info-destinatario" *ngIf="signerAdressee!=null">
          <div class="switch-button">
            <label class="varios-firmantes">¿Doble factor de autentificación?</label>
            <input type="checkbox" name="switch-button2" id="switch-label2" class="switch-button__checkbox" (click)="checkOpenid($event)">
            <label for="switch-label2" class="switch-button__label"></label>
            <label class="varios-firmantes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;¿Enviar correo
              con documento firmado?</label>
            <input type="checkbox" name="switch-button" id="switch-label" class="switch-button__checkbox" (click)="checkSendEmail($event)">
            <label for="switch-label" class="switch-button__label"></label>
          </div>
          <h2 class="tp-navandu"> INFORMACIÓN DEL DESTINATARIO </h2>

          <div class="flex-row info-dest">
            <mat-form-field class="email">
              <mat-label>Nombre</mat-label>
              <input matInput disabled>{{signerAdressee.name}} {{signerAdressee.lastName}}
            </mat-form-field>
            <mat-form-field class="email">
              <mat-label>Móvil</mat-label>
              <input matInput disabled>{{signerAdressee.phone}}
            </mat-form-field>
            <mat-form-field class="email">
              <mat-label>Email</mat-label>
              <input matInput disabled>{{signerAdressee.email}}
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="documento" *ngIf="showSignatureChanels || documentForm.controls['signatureType'].value == 2 || template.templateType.name == 'File' || doneImageConvert=='loading'">
        <div class="flex-row" *ngIf="showSignatureChanels">
          <mat-form-field>
            <mat-label>Tipo de firma</mat-label>
            <mat-select required formControlName="signatureType">
              <mat-option *ngFor="let chanel of signatureChanels" [value]="chanel.id">
                {{chanel.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="documentForm.controls.signatureType.value == 3 || !showEmailSigned">
            <mat-label>Email</mat-label>
            <mat-select required formControlName="emailRemote">
              <mat-option *ngFor="let optionEmailRemote of emailOptions" [value]="optionEmailRemote">
                {{optionEmailRemote}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="flex-row" *ngIf="documentForm.controls['signatureType'].value == 2">
          <mat-form-field>
            <mat-label>Dispositivo</mat-label>
            <mat-select formControlName="device">
              <mat-option *ngFor="let device of devices" [value]="device.deviceName">
                {{device.deviceDescription}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field *ngIf="template.templateType.name == 'File'">
          <div>
            <mat-toolbar>
              <input matInput [(ngModel)]="fileAttr" readonly name="name" [ngModelOptions]="{standalone: true}" />
              <input type="button" mat-flat-button color="primary" class="boton-up" value="Subir archivo">
            </mat-toolbar>

            <input type="file" #fileInput id="uploadFile" (change)="converter($event)" name="uploadFile" accept="application/pdf" />
            <div *ngIf="reader.readyState == 1">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </div>
        </mat-form-field>

        <app-spinner *ngIf="doneImageConvert=='loading'"></app-spinner>
        <div *ngIf="doneImageConvert == 'done'">
          <div class="select-state" *ngIf="!loading">
            <button mat-icon-button class="refrescar" (click)="changePage('previus')">
              <div class="tooltip3">
                <mat-icon>chevron_left</mat-icon>
                <span class="tooltiptext3">Anterior</span>
              </div>
            </button>
            <p>Página: {{pageImagePdf +1}} de {{lengthPageImagePdf}}</p>
            <button mat-icon-button class="refrescar" (click)="changePage('next')">
              <div class="tooltip3">
                <mat-icon>chevron_right</mat-icon>
                <span class="tooltiptext3">Siguiente</span>
              </div>
            </button>
          </div>
          <div class="example-boundary" [style.backgroundImage]="'url('+ imagepdf +')'">
            <div class="example-box" cdkDragBoundary=".example-boundary" cdkDrag (cdkDragEnded)="dragEnd($event)">
              Ubicación de firma
            </div>
          </div>
        </div>
      </div>

      <div class="documento" *ngIf="template.templateType.name == 'SetAdmin' || template.templateType.name == 'SetSigner' || template.templateType.name == 'SetUp'">
        <div *ngIf="template.content != undefined" class="borrador">
          <h1>{{template.name}}</h1>
          <div [innerHTML]="template.content | safeHtml"></div>
        </div>
      </div>

      <div class="enviar">
        <input type="submit" class="boton" value="Enviar" (click)="onSubmit()" [disabled]="buttonDisable">
      </div>
      <a routerLink="/processes/{{idProcess}}" class="volver"><span>Volver</span> </a>

    </form>
  </div>
</div>
