import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumb = new BehaviorSubject<Array<Object>>([]);

  constructor() {}

  getBreadcrumb() {
    return this.breadcrumb.asObservable();
  }

  setElements(elements) {
      this.breadcrumb.next(elements);
  }

  addElement(element) {
      const currentValue = this.breadcrumb.value;
      const updatedValue = [...currentValue, element];
      this.breadcrumb.next(updatedValue);
  }
}
