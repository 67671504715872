<div class="content">
    <img class="img-login" src="../../assets/img/fondo_login.jpg" alt="Bienvenido a SuperDocs" />
    <div class="wrapper-login">
        <header class="cabecera-login bg-ds">
            <img src="../../assets/img/SuperDocs.png" alt="Superdocs es un producto de DS Legal" />
        </header>

        <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="login">
            <div class="row">
                <mat-form-field>
                    <input [type]="hide ? 'password' : 'text'" matInput placeholder="Nueva Contraseña" formControlName="password" required class="validate">
                    <mat-error *ngIf="passwordForm.controls.password.hasError('required')">
                        Por favor teclee una contraseña
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field>
                    <input matInput [type]="hide ? 'password' : 'text'" placeholder="Repita la Contraseña" formControlName="repassword" required class="validate">
                    <mat-error *ngIf="passwordForm.controls.repassword.hasError('required')">
                        Por favor repita la contraseña
                    </mat-error>
                </mat-form-field>
            </div>
            <p class="mat-error" *ngIf="!coincide">Las contraseñas no coinciden</p>
            <p class="mat-error" *ngIf="unauthorized">El tocken generado ya ha sido utilizado o ha caducado</p>
            <p class="mat-error" *ngIf="serverError">Ha ocurrido un error en el servidor, si el problema persiste
                comuniquese con el administrador de sistema</p>
            <button class="boton">Enviar</button>
        </form>
        <div class="logo-power">
            <img src="../../assets/img/DSLegaltech.png" alt="power by unidad legaltech de DS Legal" />
        </div>
    </div>
</div>