import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  public validatePhoneByCountry(country, phone): boolean{
    switch (country) {
      case "+34":
        var regex = new RegExp('(6|7)([0-9]){8}');
        return regex.test(phone);
      case "+593":
        var regex = new RegExp('([0-9]){9}');
        return regex.test(phone);
      default:
        break;
    }
    return false;
  }

}
