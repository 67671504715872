<div class="content">
  <img class="img-login" src="../../assets/img/fondo_login.jpg" alt="Bienvenido a SuperDocs"/>
  <div class="wrapper-login">
    <header class="cabecera-login bg-ds">
      <img src="../../assets/img/SuperDocs.png" alt="Superdocs es un producto de DS Legal"/>
    </header>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login">
      <div class="row">
        <mat-form-field>
          <input matInput placeholder="Usuario" formControlName="username" required class="validate">
          <mat-error>
            Por favor teclee un usuario
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field >
          <input matInput [type]="hide ? 'password' : 'text'" placeholder="Contraseña" formControlName="password" required class="validate">
        </mat-form-field>
        <a routerLink="/guest/resetpassword" class="link">¿Olvidaste tu contraseña?</a>
      </div>
      <p class="mat-error" *ngIf="unauthorized">Usuario o contraseña no válidos</p>
      <p class="mat-error" *ngIf="serverError">Ha ocurrido un error en el servidor, si el problema persiste comuniquese con el administrador de sistema</p>
      <button class="boton">Entrar</button>
    </form>
    <div class="logo-power">
      <img src="../../assets/img/DSLegaltech.png"  alt="power by unidad legaltech de DS Legal"/> </div>
  </div>
</div>
