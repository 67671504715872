export class Pageable {
    offset: number
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    first: boolean;
    last: boolean
    totalPages: number;
    order: string;
    direction: string
}
