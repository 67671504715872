<header class="cabecera bg-{{(loggedUser$ | async).style}}" *ngIf="(isLoggedIn$ | async) || (isLoggedInGuest$ | async)">
  <div class="logos">
    <div class="logo">
      <img [src]="loggedUser$._value.urlLogo" alt="logotipo de la empresa" *ngIf="loggedUser$._value != null && loggedUser$._value.urlLogo; else elseBlock" />
      <ng-template #elseBlock>
        <img [src]="(loggedUser$ | async).logo" alt="logotipo de la empresa" />
      </ng-template>
    </div>
    <div class="app">
      <img src="../../assets/img/SuperDocs.png" alt="Superdocs es un producto de DS Legal" />

    </div>
  </div>
  <div class="menu-conexion">
    <div class="menu-principal">
      <label for="toggle" class="burger">&#9776;</label>
      <input type="checkbox" id="toggle">
      <div class="menu" *ngIf="isLoggedIn$ | async">
        <div class="tooltip">
          <a class="tp-{{(loggedUser$ | async).style}}" routerLink="/templates" routerLinkActive="active">
            <mat-icon>assignment</mat-icon>
          </a>
          <span class="tooltiptext">Plantillas</span>
        </div>
        <div class="tooltip">
          <a class="tp-{{(loggedUser$ | async).style}}" routerLink="/documents" routerLinkActive="active">
            <mat-icon>grading</mat-icon>
          </a>
          <span class="tooltiptext">Documentos</span>
        </div>
        <div class="tooltip procesos">
          <a class="tp-{{(loggedUser$ | async).style}}" routerLink="/processes" routerLinkActive="active">
            <mat-icon>developer_board</mat-icon>
          </a>
          <span class="tooltiptext">Procesos</span>
          <div class="submenu">
            <a class="tp-{{(loggedUser$ | async).style}}" routerLink="/processes/config" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <mat-icon>play_circle</mat-icon>Iniciar
            </a>
            <a class="tp-{{(loggedUser$ | async).style}}" routerLink="/processes" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <mat-icon>list_alt</mat-icon>Listar
            </a>
          </div>
        </div>

        <div class="tooltip">
          <a class="tp-{{(loggedUser$ | async).style}}" routerLink="/signers" routerLinkActive="active">
            <mat-icon>people_alt</mat-icon>
          </a>
          <span class="tooltiptext">Firmantes</span>
        </div>
        <div class="tooltip">
          <a class="tp-{{(loggedUser$ | async).style}}" routerLink="/stats" routerLinkActive="active">
            <mat-icon>assessment</mat-icon>
          </a>
          <span class="tooltiptext">Consumo</span>
        </div>
        <div class="tooltip procesos">
          <a class="tp-{{(loggedUser$ | async).style}}">
            <mat-icon>account_box</mat-icon>
          </a>
          <span class="tooltiptext">Mi cuenta</span>
          <div class="submenu">
            <a class="tp-{{(loggedUser$ | async).style}}" routerLink="/account" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <mat-icon>edit</mat-icon>Editar
            </a>
            <a class="tp-{{(loggedUser$ | async).style}}" routerLink="/account/true" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <mat-icon>key</mat-icon>Cambiar clave
            </a>
            <a class="tp-{{(loggedUser$ | async).style}}" (click)="onLogout()">
              <mat-icon>exit_to_app</mat-icon>Salir
            </a>
          </div>
        </div>
      </div>

      <div class="menu" *ngIf="isLoggedInGuest$ | async">
        <div class="tooltip">
          <a class="tp-{{(loggedUser$ | async).style}}" routerLink="/guest/documents" routerLinkActive="active">
            <mat-icon>grading</mat-icon>
          </a>
          <span class="tooltiptext">Documentos</span>
        </div>
        <div class="tooltip">
          <a class="tp-{{(loggedUser$ | async).style}}" (click)="onLogout()">
            <mat-icon>exit_to_app</mat-icon>
          </a>
          <span class="tooltiptext">Desconectar</span>
        </div>
      </div>
    </div>
    <div class="usuario-activo">
      <div class="iconLogged icon">
        <mat-icon>perm_identity</mat-icon>
      </div>
      <div class="iconLogged label">
        <span>{{loggedUser$._value.name}}</span>
      </div>
    </div>
  </div>
  <div class="breadcrumb" *ngIf="(isLoggedIn$ | async) || (isLoggedInGuest$ | async)">
    <span class="element" *ngFor="let element of (breadcrumb$ | async)">
      <a routerLink="{{element.link}}">{{element.name}}</a>
    </span>
  </div>
</header>
