<div class="wrapper">
  <div class="cuerpo">

    <h1 class="tp-navandu line-navandu">GENERACIÓN DE DOCUMENTO</h1>

    <app-spinner *ngIf="loading"></app-spinner>

    <form name="entrar" [formGroup]="documentForm" (ngSubmit)="onSubmit()" class="formulario form-gen-doc" autocomplete="off" *ngIf="!loading && !readyToSign">
      <div class="row documento">

        <div class="info-destinatario" *ngIf="signerAdressee!=null">
          <h2 class="tp-navandu"> INFORMACIÓN DEL DESTINATARIO </h2>

          <div class="flex-row info-dest">
            <mat-form-field>
              <mat-label>Nombre</mat-label>
              <input matInput disabled >{{signerAdressee.name}}
            </mat-form-field>
            <mat-form-field class="email">
              <mat-label>Móvil</mat-label>
              <input matInput disabled>{{signerAdressee.phone}}
            </mat-form-field>
            <mat-form-field class="email">
              <mat-label>Email</mat-label>
              <input matInput disabled>{{signerAdressee.email}}
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="documento">
        <div *ngIf="template.content != undefined" class="borrador">
          <h1>{{template.name}}</h1>
          <div [innerHTML]="template.content | safeHtml"></div>
        </div>
        <div class="enviar">
          <input type="submit" class="boton" value="Enviar" [disabled]="buttonDisable">
        </div>
      </div>
    </form>

    <div *ngIf="readyToSign" class="documento firmar">
      <h2>Documento generado ir a firmar</h2>
      <button (click)="sign()" class="boton">FIRMAR</button>
    </div>
  </div>
</div>
