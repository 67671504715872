import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Pageable } from 'src/app/models/pageable.model'
import { MatSnackBar } from '@angular/material/snack-bar';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { ApiProcessService } from 'src/app/service/api-process.service';
import { Process } from 'src/app/models/process.model'
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { Signer } from '../../models/guest/signer.model';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  signerForm = new FormGroup({
    expedient: new FormControl('', [
      Validators.required,
  ]),

  });

  loading: boolean = true;
  id = "";
  idnLast = "";
  pageable = new Pageable();
  status = 0;
  viewRejectReson = false;
  viewDocumentInfo = false;
  msgRejectReason: [{ reason: string, signerName: string }];
  viewForm = false;
  signerBatch: string;

  displayedColumns: string[] = ['name', 'description', 'expedient', 'signer', 'status', 'action'];

  //displayedColumns: string[] = ['user', 'template', 'creatinDate','signatureDate', 'state', 'view'];
  //dataSource = new MatTableDataSource(ELEMENT_DATA);
  dataSource = new MatTableDataSource();
  textToSearch = "";

  constructor(
    private processService: ApiProcessService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) { }


  ngOnInit(): void {
    this.breadcrumbService.setElements([]);
    this.loading = true;
    //Obtiene Procesos
    this.textToSearch = ""
    this.processService.getProcess().subscribe(dataProcess => {
      if (dataProcess != null) {
        let ELEMENT_DATA2: Process[] = dataProcess;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.loading = false;
        this.dataSource.filterPredicate = (data: Process, filter: string) => {
          return data.signer.name.toLocaleLowerCase().includes(filter) ||
           data.signer.lastName.toLocaleLowerCase().includes(filter) ||
           data.signer.idn.toLocaleLowerCase().includes(filter);
        }
      }
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          var texto = error
        }
      }

    )
  }

  filterPredicate

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  /////////////////////////////
  /////Paginator Functions/////
  /////////////////////////////

  first() {
    this.pageable.pageNumber = 0
    this.search()
  }
  next() {
    this.pageable.pageNumber = this.pageable.pageNumber + 1
    this.search()
  }
  previus() {
    this.pageable.pageNumber = this.pageable.pageNumber - 1
    this.search()
  }
  end() {
    this.pageable.pageNumber = this.pageable.totalPages
    this.search()
  }
  itemsByPage() {
    this.search()
  }
  //////////////////////////////

  filterStatus(event: Event) {
    const value = Number((event.target as HTMLInputElement).value);
    this.status = value;
    if (value != 0) {
      if (this.textToSearch != null && this.textToSearch != "") {
        this.processService.getProcessbyStatusFilters(value, this.textToSearch, this.pageable).subscribe(dataDocument => {   // data is of type HttpResponse<Object>
          if (dataDocument != null) {
            let ELEMENT_DATA2: Document[] = dataDocument["content"];
            this.pageable = dataDocument["pageable"];
            this.pageable.first = dataDocument["first"]
            this.pageable.last = dataDocument["last"]
            this.pageable.totalPages = dataDocument["totalPages"] - 1
            this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
          }
        },
          error => {
            if (error.status == 401) {
              this.router.navigate(['/login']);
            } else {
              var texto = error
            }
          }
        )
      } else {
        this.processService.getProcessbyStatus(value).subscribe(dataDocument => {   // data is of type HttpResponse<Object>
          if (dataDocument != null) {
            let ELEMENT_DATA2: Document[] = dataDocument["content"];
            this.pageable = dataDocument["pageable"];
            this.pageable.first = dataDocument["first"]
            this.pageable.last = dataDocument["last"]
            this.pageable.totalPages = dataDocument["totalPages"] - 1
            this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
          }
        },
          error => {
            if (error.status == 401) {
              this.router.navigate(['/login']);
            } else {
              var texto = error
            }
          }
        )
      }

    } else {
      this.processService.getProcess().subscribe(dataDocument => {   // data is of type HttpResponse<Object>
        if (dataDocument != null) {
          let ELEMENT_DATA2: Document[] = dataDocument["content"];
          this.pageable = dataDocument["pageable"];
          this.pageable.first = dataDocument["first"]
          this.pageable.last = dataDocument["last"]
          this.pageable.totalPages = dataDocument["totalPages"] - 1
          this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
        }
      },
        error => {
          if (error.status == 401) {
            this.router.navigate(['/login']);
          } else {
            var texto = error
          }
        }
      )

    }
  }

  search() {
    let filter = this.textToSearch
    if (this.status == 0) {
      this.processService.getProcessFilters(filter, this.pageable).subscribe(dataDocument => {   // data is of type HttpResponse<Object>
        if (dataDocument != null) {
          let ELEMENT_DATA2: Document[] = dataDocument["content"];
          this.pageable = dataDocument["pageable"];
          this.pageable.first = dataDocument["first"]
          this.pageable.last = dataDocument["last"]
          this.pageable.totalPages = dataDocument["totalPages"] - 1
          this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
        }
      },
        error => {
          if (error.status == 401) {
            this.router.navigate(['/login']);
          } else {
            var texto = error
          }
        }
      )
    } else {
      this.processService.getProcessbyStatusFilters(this.status, filter, this.pageable).subscribe(dataDocument => {   // data is of type HttpResponse<Object>
        if (dataDocument != null) {
          let ELEMENT_DATA2: Document[] = dataDocument["content"];
          this.pageable = dataDocument["pageable"];
          this.pageable.first = dataDocument["first"]
          this.pageable.last = dataDocument["last"]
          this.pageable.totalPages = dataDocument["totalPages"] - 1
          this.dataSource = new MatTableDataSource(ELEMENT_DATA2);
        }
      },
        error => {
          if (error.status == 401) {
            this.router.navigate(['/login']);
          } else {
            var texto = error
          }
        }
      )

    }

  }

  downloadProcessDocument(id,expedinet){
    //Descarga documentos por proceso
    this.processService.getDocumentsDownload(id).subscribe(documentsProcess => {
      this.getZipFile(documentsProcess, expedinet)
    },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        } else {
          var texto = error
          console.log(texto);
        }
      }
    )
  }

  getZipFile(data: any, expedient) {
    const blob = new Blob([data['body']], { type: 'application/zip' });

    const a: any = document.createElement('a');
    document.body.appendChild(a);

    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${expedient}.zip`;
    a.click();
    window.URL.revokeObjectURL(url);

  }


  onSubmit() {
    if (this.signerForm.valid) {


    } else {
      var texto = "Por favor ingrese los datos requeridos"
      this._snackBar.open(texto, "", {
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
      });
    }
  }

  view(id){
    this.router.navigate(['processes/'+id]);
  }

}
