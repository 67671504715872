import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';

import { ApiTemplatesService } from 'src/app/service/api-templates.service'
import { Template } from 'src/app/models/template.model'

import { Document } from 'src/app/models/document.model'
import { DocumentBatch } from 'src/app/models/documentBatch.model'
import { UserJS } from 'src/app/models/userJS.model'
import { VariableDocument } from 'src/app/models/variableDocument.model'
import { VariableEntity } from 'src/app/entity/variable.entity'
import { DocumentSignerRoleEntity } from 'src/app/entity/documentSignerRole.entity'
import { SignerEntity } from 'src/app/entity/signer.entity'
import { SignerBatch } from 'src/app/models/signerBatch.model'
import { ApiDocumentsService } from '../../service/api-documents.service';
import { SignersService } from '../../service/signers.service';
import { UsersService } from '../../service/users.service';
import { ValidationService } from '../../service/validation.service';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DocumentTypeModel } from 'src/app/models/documentType.model';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { PhonePrefix } from 'src/app/models/phonePrefix.model';
import { PhonePrefixService } from 'src/app/service/phone-prefix.service';

export interface Device {
    deviceDescription: string,
    deviceName: number
}

export interface Variable {
    id: number,
    name: string,
    type: string,
    anchor: string,
    uuid: string
    value: string
}

@Component({
    selector: 'app-signtemplate',
    templateUrl: './signtemplate.component.html',
    styleUrls: ['./signtemplate.component.css'],
})

export class SigntemplateComponent implements OnInit {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    displayedColumns: string[] = ['check', 'name', 'idn', 'email', 'phone'];
    dataSource = new MatTableDataSource<SignerEntity>();
    ELEMENT_DATA: SignerEntity[] = null;
    dataUser: UserJS = JSON.parse(localStorage.getItem("user"));
    imagenLogo = this.dataUser.logo

    newUser: boolean = true;
    severalSigners = false;
    biometricSigner = false;
    sendEmailCreator: boolean = true;

    id: string;

    buttonDisable = false;

    filteredOptions: Observable<SignerEntity[]>;
    filteredOptionsName: Observable<SignerEntity[]>;
    filteredOptionsLastName: Observable<SignerEntity[]>;

    filterOptionsSigners: [Observable<SignerEntity[]>] = [new Observable<SignerEntity[]>()]
    countSigners = 0;
    countSignersForms = 0;
    phonePrefixes: PhonePrefix[];

    documentForm = new FormGroup({
        addressee_document_type: new FormControl('', [
            Validators.required,

        ]),
        addressee_document: new FormControl('', [
            Validators.required,
        ]),
        addressee_name: new FormControl('', [
            Validators.required,
        ]),
        addressee_last_name: new FormControl('', [
            Validators.required,
        ]),
        addressee_phone: new FormControl('', [
            Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
            Validators.minLength(9),
            Validators.maxLength(9),
        ]),
        addressee_mail: new FormControl('', [
            Validators.required,
            Validators.email,
        ]),
        addresseeAsSigner: new FormControl<boolean>(false, [
            Validators.required,
        ]),
        addressee_country: new FormControl('+34', [
            Validators.required,
        ]),
        signatureType: new FormControl('', [
            Validators.required,
        ]),
        device: new FormControl('', [
        ]),
        uuidSigner: new FormControl('', [
        ]),
        expedient: new FormControl('', [
            Validators.required,
        ]),
        signers: new FormArray([]),
    });


    template: Template = new Template();
    templateContent;
    devices: Device[];
    signatureChanels: DocumentTypeModel[];
    options: SignerEntity[];
    signerBatch: SignerBatch[] = [new SignerBatch()];
    signersRole: DocumentSignerRoleEntity[];
    dataSigner: SignerEntity;
    variablesUser: [VariableDocument] = [new VariableDocument()];
    variablesForm: [VariableDocument] = [new VariableDocument()];

    addressee: [VariableEntity] = [new VariableEntity()];
    signer: [VariableEntity] = [new VariableEntity()];
    document: [VariableEntity] = [new VariableEntity()];
    form: [VariableEntity] = [new VariableEntity()];
    user: [VariableEntity] = [new VariableEntity()];
    signerDefault: [VariableEntity] = [new VariableEntity()];

    signersToDelete: number[] = [];

    procedimientos = {
        number: 'string',
    }


    constructor(private formBuilder: FormBuilder,
        private templateService: ApiTemplatesService,
        private documentsService: ApiDocumentsService,
        private signersService: SignersService,
        private userService: UsersService,
        private validationService: ValidationService,
        private activatedRouter: ActivatedRoute,
        private router: Router,
        private _snackBar: MatSnackBar,
        private breadcrumbService: BreadcrumbService,
        private phonePrefixService: PhonePrefixService,
    ) {
        this.activatedRouter.params.subscribe(parametros => {
            this.id = parametros['id'];
        })
    }

    ngOnInit(): void {
        this.signerBatch.splice(0, 1)
        this.paginator._intl.itemsPerPageLabel = 'Items por página'
        this.documentForm.controls['addresseeAsSigner'].setValue(true);
        //Obtiene template para poder mostrar el formulario necesario para firmar el documento
        this.templateService.getTemplate(this.id).subscribe(dataTemplate => {   // data is of type HttpResponse<Object>
            if (dataTemplate != null) {
                this.template = dataTemplate;
                this.templateContent = dataTemplate.content;
                this.documentForm.valueChanges.subscribe(data => {
                    this.uploadDocument()
                })
                this.sortComponent(this.template.variables);
                this.breadcrumbService.setElements([
                    { name: 'Plantillas', link: '/templates' },
                    { name: this.template.name, link: `/templates/${this.id}` },
                    { name: 'Firmar', link: `/templates/${this.id}/sign` }
                ]);
            }
        },
            error => {
                if (error.status == 401) {
                    this.router.navigate(['/login']);
                } else {
                    var texto = error
                }
            }
        )

        //Obtiene dispositivos asociados a un usuario
        this.templateService.getDevices().subscribe(dataDevices => {   // data is of type HttpResponse<Object>
            if (dataDevices != null) {
                this.devices = dataDevices;
            }
        },
            error => {
                if (error.status == 401) {
                    this.router.navigate(['/login']);
                } else {
                    var texto = error
                }
            }
        )

        //Obtiene firmantes registrados
        this.signersService.getSigners().subscribe(dataSigners => {   // data is of type HttpResponse<Object>
            if (dataSigners != null) {
                this.options = dataSigners.slice();

                this.ELEMENT_DATA = dataSigners;
                this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;


                this.filteredOptions =
                this.documentForm.controls.addressee_document.valueChanges.pipe(
                  startWith(''),
                  map((val) => this._filter(val))
                );
                this.filteredOptionsName = this.documentForm.controls.addressee_name.valueChanges.pipe(
                  startWith(''),
                  map((val) => this._filterName(val))
                );
                this.filteredOptionsLastName = this.documentForm.controls.addressee_last_name.valueChanges.pipe(
                  startWith(''),
                  map((val) => this._filterLastName(val))
                );
            }
        },
            error => {
                if (error.status == 401) {
                    this.router.navigate(['/login']);
                } else {
                    var texto = error
                }
            }
        )

        //Obtiene roles para los firmantes
        this.signersService.getDocumentSignerRole().subscribe(dataSignersRole => {   // data is of type HttpResponse<Object>
            if (dataSignersRole != null) {
                this.signersRole = dataSignersRole;
            }
        },
            error => {
                if (error.status == 401) {
                    this.router.navigate(['/login']);
                } else {
                    var texto = error
                }
            }
        )

        //Obtiene cantidad de documentos para # expediente
        this.signersService.getDocumentsCount().subscribe(dataCount => {   // data is of type HttpResponse<Object>
            if (dataCount != null) {
                this.documentForm.controls['expedient'].setValue(String(dataCount));
            }
        },
            error => {
                if (error.status == 401) {
                    this.router.navigate(['/login']);
                } else {
                    var texto = error
                }
            }
        )

        //Obtiene canales de firmas disponibles para el usuario logueado
        this.userService.getUser().subscribe(dataUser => {   // data is of type HttpResponse<Object>
            if (dataUser != null) {
                this.signatureChanels = dataUser.documentTypesDtos;
                this.phonePrefixService.getPhonePrefixes().subscribe(dataPhonePrefixes => {
                    this.phonePrefixes = dataPhonePrefixes;
                    this.documentForm.get('addressee_country').setValue(dataUser.phonePrefix.prefix);
                  });
            }
        },
            error => {
                if (error.status == 401) {
                } else {
                    var texto = error
                }
            }
        )
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    displayFn(user: SignerEntity): string {
        return user.idn
    }

    private _filter(idn: string): SignerEntity[] {
        let optionFilter = this.options;
        const filterValue = idn.toLowerCase();
        const returnFilter = optionFilter.filter(option => option.idn.toLowerCase().indexOf(filterValue) === 0);
        if (returnFilter.length > 0) {
          if (idn == returnFilter[0].idn) {
            if (this.documentForm.controls['addressee_name'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
              this.documentForm.controls['addressee_name'].setValue(returnFilter[0].name);
            }
            if (this.documentForm.controls['addressee_last_name'].value != returnFilter[0].lastName) { // Sin esta condición se genera un bucle infinito
              this.documentForm.controls['addressee_last_name'].setValue(returnFilter[0].lastName);
            }
            this.documentForm.controls['addressee_country'].setValue(returnFilter[0].countryPhone);
            this.documentForm.controls['addressee_document_type'].setValue(returnFilter[0].typeOfIdn);
            this.documentForm.controls['addressee_phone'].setValue(returnFilter[0].phone);
            this.documentForm.controls['addressee_mail'].setValue(returnFilter[0].email);
            this.dataSigner = returnFilter[0];
            this.newUser = false;
          } else {
            this.newUser = true;
          }
        } else {
          this.newUser = true;
        }
        return returnFilter;
      }

      private _filterName(name: string): SignerEntity[] {
        let optionFilter = this.options;
        const filterValue = name.toLowerCase();
        const returnFilter = optionFilter.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
        if (returnFilter.length > 0) {
          if (name == returnFilter[0].name) {
            if (this.documentForm.controls['addressee_document'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
              this.documentForm.controls['addressee_document'].setValue(returnFilter[0].idn);
            }
            if (this.documentForm.controls['addressee_last_name'].value != returnFilter[0].lastName) { // Sin esta condición se genera un bucle infinito
              this.documentForm.controls['addressee_last_name'].setValue(returnFilter[0].lastName);
            }
            this.documentForm.controls['addressee_country'].setValue(returnFilter[0].countryPhone);
            this.documentForm.controls['addressee_document_type'].setValue(returnFilter[0].typeOfIdn);
            this.documentForm.controls['addressee_phone'].setValue(returnFilter[0].phone);
            this.documentForm.controls['addressee_mail'].setValue(returnFilter[0].email);
            this.dataSigner = returnFilter[0];
            this.newUser = false;
          } else {
            this.newUser = true;
          }
        } else {
          this.newUser = true;
        }
        return returnFilter;
      }

      private _filterLastName(lastName: string): SignerEntity[] {
        let optionFilter = this.options;
        const filterValue = lastName.toLowerCase();
        const returnFilter = optionFilter.filter(option => option.lastName.toLowerCase().indexOf(filterValue) === 0);
        if (returnFilter.length > 0) {
          if (lastName == returnFilter[0].lastName) {
            if (this.documentForm.controls['addressee_document'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
              this.documentForm.controls['addressee_document'].setValue(returnFilter[0].idn);
            }
            if (this.documentForm.controls['addressee_name'].value != returnFilter[0].idn) { // Sin esta condición se genera un bucle infinito
              this.documentForm.controls['addressee_name'].setValue(returnFilter[0].name);
            }
            this.documentForm.controls['addressee_country'].setValue(returnFilter[0].countryPhone);
            this.documentForm.controls['addressee_document_type'].setValue(returnFilter[0].typeOfIdn);
            this.documentForm.controls['addressee_phone'].setValue(returnFilter[0].phone);
            this.documentForm.controls['addressee_mail'].setValue(returnFilter[0].email);
            this.dataSigner = returnFilter[0];
            this.newUser = false;
          } else {
            this.newUser = true;
          }
        } else {
          this.newUser = true;
        }
        return returnFilter;
      }

    _filterSigners(idn: string, index: number) {
        let optionFilter = this.options;
        const myForm = (<FormArray>this.documentForm.get("signers")).at(index);
        const filterValue = idn.toLowerCase();
        const returnFilter = optionFilter.filter(option => option.idn.toLowerCase().indexOf(filterValue) === 0);
        if (returnFilter.length > 0) {
            if (idn == returnFilter[0].idn) {
                myForm.patchValue({
                    signer_documet_type: returnFilter[0].typeOfIdn,
                    signer_name: returnFilter[0].name,
                    signer_last_name: returnFilter[0].lastName,
                    signer_mail: returnFilter[0].email,
                    signer_phone: returnFilter[0].phone,
                    signer_country: returnFilter[0].countryPhone,
                    newUser: false
                });
            } else {
                this.clearSigner(myForm);
            }
        } else {
            this.clearSigner(myForm);
        }
        return returnFilter;
    }

    // Separa por componentes para visualizar formulario y crea los requeridos necesarios en el formulario
    private sortComponent(variables: [VariableEntity]) {
        variables.forEach(variable => {
            switch (variable.container) {
                case "addressee":
                    this.addressee[variable.orederNumer] = variable;
                    break;
                case "signer":
                    this.signer[variable.orederNumer] = variable;
                    break;
                case "document":
                    this.document[variable.orederNumer] = variable;
                    break;
                case "form":
                    this.form[variable.orederNumer] = variable;
                    break;
                case "user":
                    this.user[variable.orederNumer] = variable;
                    break;
                case "signerDefault":
                    this.signerDefault[variable.orederNumer] = variable;
                    break;
                default:
                    break;
            }
        });
        this.addressee.splice(0, 1)
        this.signer.splice(0, 1)
        this.document.splice(0, 1)
        this.form.splice(0, 1)
        this.user.splice(0, 1)
        this.signerDefault.splice(0, 1)
        this.countSignersForms = this.signer.length / 8
        this.uploadDefault()
    }


    //Remplaza el documento con la información registrada

    uploadDefault() {
        let date = new Date()
        this.user.forEach(element => {
            if (element.value != null && element.value != "" && element.value != undefined) {
                this.template.content = this.template.content.split(element.anchor).join(element.value)
            }
        });
        let formatDate = date.getDate().toString().length == 2 ? date.getDate().toString() : "0" + date.getDate()
        formatDate += "-" + (date.getMonth().toString().length == 2 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1))
        this.template.content = this.template.content.split("%document_date%").join(formatDate + "-" + date.getFullYear())
        this.document.forEach(variableDocument => {
            switch (variableDocument.type) {
                case 'string':
                    if (variableDocument.value == undefined || variableDocument.value == "" || variableDocument.value == null) {
                        this.template.content = this.template.content.split(variableDocument.anchor)
                            .join("<input type='text' id=" + variableDocument.uuid
                                + " placeholder=" + variableDocument.name
                                + " style='width: 300px;background-color: #C8D6F3'>")
                    } else {
                        this.template.content = this.template.content.split(variableDocument.anchor)
                            .join("<input type='text' id=" + variableDocument.uuid
                                + " placeholder=" + variableDocument.name
                                + " value=" + variableDocument.value
                                + " style='width: 300px;background-color: #C8D6F3'>")
                    }
                    break;
                case 'dateInput':
                    this.template.content = this.template.content.split(variableDocument.anchor)
                        .join("<input type='date' id=" + variableDocument.uuid
                            + " placeholder=" + variableDocument.name
                            + " style='width: 300px;background-color: #C8D6F3'>")
                    break;
                case 'select':
                    let optionsSelect = JSON.parse(variableDocument.config)
                    let htmlOption = "<option disabled selected>Selecciona una opción</option>"
                    optionsSelect.forEach(optionInSelect => {
                        htmlOption = htmlOption + "<option value='" + optionInSelect + "'>" + optionInSelect + "</option>"
                    })
                    this.template.content = this.template.content.split(variableDocument.anchor)
                        .join("<select id=" + variableDocument.uuid + ">"
                            + htmlOption
                            + "</select>"
                        )
                    break;
                case 'check':
                    this.template.content = this.template.content.split(variableDocument.anchor)
                        .join("<input type='checkbox' id=" + variableDocument.uuid
                            + ">")
                    break;

                default:
                    break;
            }
        })
    }

    uploadSigners(auxIndex: number) {
        let documentSigners = [new SignerEntity()];
        this.documentForm.get('signers').value.forEach((element, index) => {
            let signerData: SignerEntity = new SignerEntity();
            signerData.device = this.documentForm.controls['device'].value,
                signerData.name = element.signer_name,
                signerData.lastName = element.signer_last_name,
                signerData.idn = element.signer_document,
                signerData.typeOfIdn = element.signer_documet_type,
                signerData.email = element.signer_mail,
                signerData.phone = element.signer_country + element.signer_phone,
                signerData.newSigner = element.newUser
            signerData.documentSignerRole = element.signer_role

            documentSigners.push(signerData)
            //Enviar a variables si es necesario
            if (this.signer.length > 0 && signerData.name != null && signerData.name != "" && signerData.name != undefined) {
                this.template.content = this.template.content.split("id='" + '%signer_name_'.concat(String(index + auxIndex), "%") + "' style='display: none'").join("");
                this.template.content = this.template.content.split('%signer_name_'.concat(String(index + auxIndex), "%")).join(signerData.name)
                this.template.content = this.template.content.split('%signer_last_name_'.concat(String(index + auxIndex), "%")).join(signerData.lastName)
                this.template.content = this.template.content.split('%signer_document_type_'.concat(String(index + auxIndex), "%")).join(signerData.typeOfIdn)
                this.template.content = this.template.content.split('%signer_document_'.concat(String(index + auxIndex), "%")).join(signerData.idn)
                this.template.content = this.template.content.split('%signer_phone_'.concat(String(index + auxIndex), "%")).join(signerData.phone)
                this.template.content = this.template.content.split('%signer_mail_'.concat(String(index + auxIndex), "%")).join(signerData.email)
                this.template.content = this.template.content.split('%signer_role_'.concat(String(index + auxIndex), "%")).join(signerData.documentSignerRole.name)
            }
        });
    }

    uploadDocument() {
        this.template.content = this.templateContent;
        this.uploadDefault();
        this.addressee.forEach(element => {
            this.template.content = this.template.content.replace(element.anchor, this.documentForm.controls[element.anchor.split('%').join('')].value)
        });

        this.signerDefault.forEach(element => {
            switch (element.anchor) {
                case "%signer_document_type%":
                    this.template.content = this.template.content.split('%signer_document_type%').join(this.documentForm.controls['addressee_document_type'].value)
                    break;
                case "%signer_document%":
                    this.template.content = this.template.content.split('%signer_document%').join(this.documentForm.controls['addressee_document'].value)
                    break;
                case "%signer_name%":
                    this.template.content = this.template.content.split('%signer_name%').join(this.documentForm.controls['addressee_name'].value + " " + this.documentForm.controls['addressee_last_name'].value)
                    break;
                case "%signer_mail%":
                    this.template.content = this.template.content.split('%signer_mail%').join(this.documentForm.controls['addressee_mail'].value)
                    break;
                case "%signer_phone%":
                    this.template.content = this.template.content.split('%signer_phone%').join(this.documentForm.controls['addressee_phone'].value)
                    break;
            }
        });

        if (this.documentForm.controls['addresseeAsSigner'].value) {
            this.template.content = this.template.content.split("id='" + '%signer_name_1%' + "' style='display: none'").join("");
            this.template.content = this.template.content.split('%signer_name_1%').join(this.documentForm.controls['addressee_name'].value)
            this.template.content = this.template.content.split('%signer_last_name_1%').join(this.documentForm.controls['addressee_last_name'].value)
            this.template.content = this.template.content.split('%signer_document_type_1%').join(this.documentForm.controls['addressee_document_type'].value)
            this.template.content = this.template.content.split('%signer_document_1%').join(this.documentForm.controls['addressee_document'].value)
            this.template.content = this.template.content.split('%signer_phone_1%').join(this.documentForm.controls['addressee_phone'].value)
            this.template.content = this.template.content.split('%signer_mail_1%').join(this.documentForm.controls['addressee_mail'].value)
            this.template.content = this.template.content.split('%signer_role_1%').join(this.signersRole[0].name)
            this.uploadSigners(2);
        } else {
            this.uploadSigners(1);
        }

        this.form.forEach(element => {
            if (element.value != null && element.value != "" && element.value != undefined) {
                this.template.content = this.template.content.split(element.anchor).join(element.value)
            }
        });

        this.user.forEach(element => {
            if (element.value != null && element.value != "" && element.value != undefined) {
                this.template.content = this.template.content.split(element.anchor).join(element.value)
            }
        });


    }


    //Firmantes
    select(value) {
        this.biometricSigner = value == 2 ? true : false;
        //si no es un procesoBatch
        if (!this.checkSeveralSigner) {
            //Si es firma Biométrica
            if (value == 2) {
                this.documentForm.get('addressee_phone').clearValidators()
                this.documentForm.get('addressee_mail').clearValidators()
                this.documentForm.controls.addressee_mail.updateValueAndValidity()
                this.documentForm.controls.addressee_phone.updateValueAndValidity()
                this.documentForm.controls.signers.value.forEach(signer => {
                    this.documentForm.controls.signers['controls'][signer.id]['controls']['signer_mail'].clearValidators()
                    this.documentForm.controls.signers['controls'][signer.id]['controls']['signer_phone'].clearValidators()
                    this.documentForm.controls.signers['controls'][signer.id]['controls']['signer_mail'].updateValueAndValidity()
                    this.documentForm.controls.signers['controls'][signer.id]['controls']['signer_phone'].updateValueAndValidity()
                });
            } else {
                if (this.documentForm.value['addresseeAsSigner']) {
                    this.documentForm.get('addressee_mail').setValidators([Validators.required, Validators.email]);
                    this.documentForm.get('addressee_phone').setValidators([Validators.required,
                    Validators.pattern(/^-?(0|[1-9]\d*)?$/),
                    Validators.minLength(9),
                    Validators.maxLength(9)]);
                    this.documentForm.controls.addressee_mail.updateValueAndValidity()
                    this.documentForm.controls.addressee_phone.updateValueAndValidity()
                }
                this.documentForm.controls.signers.value.forEach(signer => {
                    this.documentForm.controls.signers['controls'][signer.id]['controls']['signer_mail'].setValidators([Validators.required, Validators.email])
                    this.documentForm.controls.signers['controls'][signer.id]['controls']['signer_phone'].setValidators([Validators.required,
                    Validators.pattern(/^-?(0|[1-9]\d*)?$/),
                    Validators.minLength(9),
                    Validators.maxLength(9)])
                    this.documentForm.controls.signers['controls'][signer.id]['controls']['signer_mail'].updateValueAndValidity()
                    this.documentForm.controls.signers['controls'][signer.id]['controls']['signer_phone'].updateValueAndValidity()
                });
            }

        }
    }

    checkSignser(event: any, value: any) {
        this.dataSource.data.filter(s => s.idn == value.idn)[0].checked = event.currentTarget.checked
        if (event.currentTarget.checked) {
            this.signerBatch.push(value)
        } else {
            let index = this.signerBatch.indexOf(value);
            this.signerBatch.splice(index, 1);
        }
    }

    checkSendEmailToCreator(value: any) {
        this.sendEmailCreator = value.currentTarget.checked;
      }

    checkSeveralSigner(value: any) {
        this.severalSigners = value.currentTarget.checked;
        if (value.currentTarget.checked) {
            this.removeAllSigners();
            this.documentForm.controls.addressee_country.clearValidators();
            this.documentForm.controls.addressee_document_type.clearValidators();
            this.documentForm.controls.addressee_document.clearValidators();
            this.documentForm.controls.addressee_name.clearValidators();
            this.documentForm.controls.addressee_last_name.clearValidators();
            this.documentForm.controls.addressee_phone.clearValidators();
            this.documentForm.controls.addressee_mail.clearValidators();
            this.documentForm.controls.addressee_country.updateValueAndValidity();
            this.documentForm.controls.addressee_document_type.updateValueAndValidity();
            this.documentForm.controls.addressee_document.updateValueAndValidity();
            this.documentForm.controls.addressee_name.updateValueAndValidity();
            this.documentForm.controls.addressee_last_name.updateValueAndValidity();
            this.documentForm.controls.addressee_phone.updateValueAndValidity();
            this.documentForm.controls.addressee_mail.updateValueAndValidity();
        } else {
            this.documentForm.controls.addressee_country.setValidators([Validators.required]);
            this.documentForm.controls.addressee_document_type.setValidators([Validators.required]);
            this.documentForm.controls.addressee_document.setValidators([Validators.required]);
            this.documentForm.controls.addressee_name.setValidators([Validators.required]);
            this.documentForm.controls.addressee_last_name.setValidators([Validators.required]);
            this.documentForm.controls.addressee_phone.setValidators([
                Validators.required,
                Validators.pattern(/^-?(0|[1-9]\d*)?$/),
                Validators.minLength(9),
                Validators.maxLength(9),
            ]);
            this.documentForm.controls.addressee_mail.setValidators([Validators.required, Validators.email]);
            this.documentForm.controls.addressee_country.updateValueAndValidity();
            this.documentForm.controls.addressee_document_type.updateValueAndValidity();
            this.documentForm.controls.addressee_document.updateValueAndValidity();
            this.documentForm.controls.addressee_name.updateValueAndValidity();
            this.documentForm.controls.addressee_last_name.updateValueAndValidity();
            this.documentForm.controls.addressee_phone.updateValueAndValidity();
            this.documentForm.controls.addressee_mail.updateValueAndValidity();
        }

    }

    checkValue(value: any) {
        if (value.currentTarget.checked) {
            this.removeSigner(0);
            if (Number(this.documentForm.value['signatureType']) != 2) {
                this.documentForm.get('addressee_mail').setValidators([Validators.required, Validators.email]);
                this.documentForm.get('addressee_phone').setValidators([
                    Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/),
                    Validators.minLength(9),
                    Validators.maxLength(9)]);
                this.documentForm.controls.addressee_mail.updateValueAndValidity();
                this.documentForm.controls.addressee_phone.updateValueAndValidity();
            }
        } else {
            if (this.documentForm.controls['signers']['value']['length'] == 0) {
                this.addSigner();
                this.documentForm.controls.addressee_mail.clearValidators();
                this.documentForm.controls.addressee_phone.clearValidators();
                this.documentForm.controls.addressee_mail.updateValueAndValidity();
                this.documentForm.controls.addressee_phone.updateValueAndValidity();
            }
        }
    }

    buildSigner() {
        return this.formBuilder.group({
            id: '0',
            signer_role: '',
            signer_documet_type: '',
            signer_document: '',
            signer_name: '',
            signer_last_name: '',
            signer_mail: ['', [
                Validators.required,
                Validators.email,
            ]],
            signer_phone: ['', [Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
            Validators.minLength(9),
            Validators.maxLength(9),]],
            signer_country: '+34',
            newUser: true
        });
    }

    addSigner() {
        const cc = this.countSigners;
        let signerArray = this.documentForm.controls.signers as FormArray;
        let signerForm = this.buildSigner();
        if (this.countSigners == 0) {
            this.filterOptionsSigners[0] = (signerForm.controls.signer_document.valueChanges
                .pipe(
                    startWith(''),
                    map(val => this._filterSigners(val, 0))
                )
            )
        } else {
            this.filterOptionsSigners.push(signerForm.controls.signer_document.valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value),
                    map(idn => idn ? this._filterSigners(idn, cc) : this.options.slice())
                )
            )
        }
        signerArray.push(signerForm);
        this.countSigners++
        this.select(this.documentForm.value['signatureType'])
    }

    removeSigner(index: number) {
        let signers = this.documentForm.get('signers') as FormArray;
        let signerRemove = signers.at(index) as FormGroup;
        if (signerRemove.controls['id'].value != '0') {
            this.signersToDelete.push(<number>signerRemove.controls['id'].value);
            this.select(this.documentForm.value['signatureType'])
        }
        signers.removeAt(index);
        this.countSigners--

    }

    removeAllSigners() {
        let signers = this.documentForm.get('signers') as FormArray;
        for (let index = 0; index < this.countSigners; index++) {
            let signerRemove = signers.at(index) as FormGroup;
            if (signerRemove.controls['id'].value != '0') {
                this.signersToDelete.push(<number>signerRemove.controls['id'].value);
                this.select(this.documentForm.value['signatureType'])
            }
            signers.removeAt(index);
        }
        this.countSigners = 0;
    }


    getSigners(): FormArray {
        return this.documentForm.get("signers") as FormArray
    }

    clearSigner(myForm) {
        myForm.patchValue({
            signer_documet_type: '',
            signer_name: "",
            signer_last_name: "",
            signer_mail: "",
            signer_phone: "",
            newUser: false
        });
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    //sincroniza los datos obtenidos por formulario con los requeridos para enviar a firmar
    /////////////////////////////////////////////////////////////////////////////////////////

    //Sincroniza datos del destinatario para enviar a firmar
    private matchDataAddesse = () => {
        let promise = new Promise((resolve, reject) => {
            let variables = [new VariableDocument()]
            //Asocia variables de Addressee
            this.addressee.forEach(element => {
                variables.push({
                    "uuid": element.uuid,
                    "value": this.documentForm.controls[element.anchor.split('%').join('')] != undefined ? this.documentForm.controls[element.anchor.split('%').join('')].value : null
                })
            });
            variables.splice(0, 1)
            resolve(variables.filter(option => option.uuid != undefined));
        });
        return promise;

    };


    //Sincroniza datos de los firmantes para enviar a firmar
    private matchDataSigners = () => {
        let documentSigners = [new SignerEntity()];
        let variables = [new VariableDocument()]
        let promise = new Promise((resolve, reject) => {
            if (this.documentForm.controls['addresseeAsSigner'].value) {
                let signerData: SignerEntity = new SignerEntity();
                signerData.device = this.documentForm.controls['device'].value,
                    signerData.name = this.documentForm.controls['addressee_name'].value,
                    signerData.lastName = this.documentForm.controls['addressee_last_name'].value,
                    signerData.idn = this.documentForm.controls['addressee_document'].value,
                    signerData.typeOfIdn = this.documentForm.controls['addressee_document_type'].value,
                    signerData.email = this.documentForm.controls['addressee_mail'].value,
                    signerData.phone = this.documentForm.controls['addressee_country'].value + this.documentForm.controls['addressee_phone'].value,
                    signerData.newSigner = this.newUser
                documentSigners.push(signerData)
                variables.push(
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_name_1') != undefined ? this.signer.find(signer => signer.name === 'signer_name_1').uuid : null,
                        "value": signerData.name
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_last_name_1') != undefined ? this.signer.find(signer => signer.name === 'signer_last_name_1').uuid : null,
                        "value": signerData.lastName
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_document_type_1') != undefined ? this.signer.find(signer => signer.name === 'signer_document_type_1').uuid : null,
                        "value": signerData.typeOfIdn
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_document_1') != undefined ? this.signer.find(signer => signer.name === 'signer_document_1').uuid : null,
                        "value": signerData.idn
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_phone_1') != undefined ? this.signer.find(signer => signer.name === 'signer_phone_1').uuid : null,
                        "value": signerData.phone
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_mail_1') != undefined ? this.signer.find(signer => signer.name === 'signer_mail_1').uuid : null,
                        "value": signerData.email
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_role_1') != undefined ? this.signer.find(signer => signer.name === 'signer_role_1').uuid : null,
                        "value": "Firmante"
                    }
                )
            }
            this.documentForm.get('signers').value.forEach((element, index) => {
                let countSigner = this.documentForm.controls['addresseeAsSigner'].value ? 2 : 1;
                let signerData: SignerEntity = new SignerEntity();
                signerData.device = this.documentForm.controls['device'].value,
                    signerData.name = element.signer_name,
                    signerData.lastName = element.signer_last_name,
                    signerData.idn = element.signer_document,
                    signerData.typeOfIdn = element.signer_documet_type,
                    signerData.documentSignerRole = element.signer_role,
                    signerData.email = element.signer_mail,
                    signerData.phone = element.signer_country + element.signer_phone,
                    signerData.newSigner = element.newUser
                documentSigners.push(signerData)
                variables.push(
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_name_'.concat(String(index + countSigner))) != undefined ? this.signer.find(signer => signer.name === 'signer_name_'.concat(String(index + countSigner))).uuid : null,
                        "value": signerData.name
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_last_name_'.concat(String(index + countSigner))) != undefined ? this.signer.find(signer => signer.name === 'signer_last_name_'.concat(String(index + countSigner))).uuid : null,
                        "value": signerData.lastName
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_document_type_'.concat(String(index + countSigner))) != undefined ? this.signer.find(signer => signer.name === 'signer_document_type_'.concat(String(index + countSigner))).uuid : null,
                        "value": signerData.typeOfIdn
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_document_'.concat(String(index + countSigner))) != undefined ? this.signer.find(signer => signer.name === 'signer_document_'.concat(String(index + countSigner))).uuid : null,
                        "value": signerData.idn
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_phone_'.concat(String(index + countSigner))) != undefined ? this.signer.find(signer => signer.name === 'signer_phone_'.concat(String(index + countSigner))).uuid : null,
                        "value": signerData.phone
                    },
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_mail_'.concat(String(index + countSigner))) != undefined ? this.signer.find(signer => signer.name === 'signer_mail_'.concat(String(index + countSigner))).uuid : null,
                        "value": signerData.email
                    }
                    ,
                    {
                        "uuid": this.signer.find(signer => signer.name === 'signer_role_'.concat(String(index + countSigner))) != undefined ? this.signer.find(signer => signer.name === 'signer_role_'.concat(String(index + countSigner))).uuid : null,
                        "value": signerData.documentSignerRole.name
                    }
                )
            });
            documentSigners.splice(0, 1)
            variables.splice(0, 1)
            resolve([documentSigners, variables.filter(option => option.uuid != undefined)]);
        });
        return promise;
    };

    //Sincroniza datos adicionales del documento para enviar a firmar
    private matchDataDocument = () => {
        let promise = new Promise((resolve, reject) => {
            let variables = [new VariableDocument()]
            this.document.forEach(element => {
                if (element.type == "check") {
                    variables.push({ "uuid": element.uuid, "value": document.getElementById(element.uuid)['checked'] })
                } else {
                    if (document.getElementById(element.uuid)['value'] != "") {
                        variables.push({ "uuid": element.uuid, "value": document.getElementById(element.uuid)['value'] })
                    }
                }

            });
            variables.splice(0, 1)
            resolve(variables);
        });
        return promise;
    };

    //Sincroniza datos del formulario para enviar a firmar
    private matchDataForm = () => {
        let promise = new Promise((resolve, reject) => {
            let variables = [new VariableDocument()]
            this.form.forEach(element => {
                variables.push({ "uuid": element.uuid, "value": element.value })
            });
            variables.splice(0, 1)
            resolve(variables);
        });
        return promise;
    };

    //Sincroniza datos del cliente para enviar a firmar
    private matchDataUser = () => {
        let promise = new Promise((resolve, reject) => {
            let variables = [new VariableDocument()]
            this.user.forEach(element => {
                variables.push({ "uuid": element.uuid, "value": element.value })
            });
            variables.splice(0, 1)
            resolve(variables);
        });
        return promise;
    };

    //Sincroniza datos del destinatario para enviar a firmar
    private matchDataAddesseBatch = (signer: SignerBatch) => {
        let promise = new Promise((resolve, reject) => {
            let variables = [new VariableDocument()]
            //Asocia variables de Addressee
            this.addressee.forEach(element => {
                switch (element.anchor.split('%').join('')) {
                    case "addressee_document_type":
                        variables.push({ "uuid": element.uuid, "value": signer.typeOfIdn })
                        break;
                    case "addressee_document":
                        variables.push({ "uuid": element.uuid, "value": signer.idn })
                        break;
                    case "addressee_name":
                        variables.push({ "uuid": element.uuid, "value": signer.name })
                        break;
                    case "addressee_last_name":
                        variables.push({ "uuid": element.uuid, "value": signer.lastName })
                        break;
                    case "addressee_mail":
                        variables.push({ "uuid": element.uuid, "value": signer.email })
                        break;
                    case "addressee_phone":
                        variables.push({ "uuid": element.uuid, "value": signer.phone })
                        break;
                    default:
                        break;
                }
            });
            variables.splice(0, 1)
            resolve(variables);
        });
        return promise;

    };

    //Crear Objeto firmatens para enviar a proceso Batch
    private matchDataSeveralSigners = () => {
        let validation = [];
        validation.splice(0, 1)
        let documentSignersBatch = [new SignerBatch()];
        let promise = new Promise((resolve, reject) => {
            Promise.all([this.matchDataDocument(), this.matchDataForm(), this.matchDataUser()]).then(values => {
                let variables: VariableDocument[] = Object.values(values[0]).concat(Object.values(values[1]), Object.values(values[2]));
                this.signerBatch.forEach((element, index) => {
                    this.matchDataAddesseBatch(element).then(values => {
                        element.variables = variables.concat(Object.values(values), this.variablesInSigner(element))
                        element.device = this.documentForm.controls['device'].value;
                        if(!this.biometricSigner && (element.email == "" || element.email == null || element.phone == "" || element.phone == null)){
                            validation.push(element)
                        }
                        documentSignersBatch.push(element)
                        if(this.signerBatch.length -1 == index){
                            if(validation.length == 0){
                                documentSignersBatch.splice(0, 1)
                                resolve(documentSignersBatch);
                            }else{
                                reject(validation)
                            }
                        }

                    })
                })


            })
        });
        return promise;
    };

    private variablesInSigner(signer: SignerBatch): VariableDocument[] {
        let variables = [new VariableDocument()]
        variables.push(
            {
                "uuid": this.signer.find(signer => signer.name === 'signer_name_1') != undefined ? this.signer.find(signer => signer.name === 'signer_name_1').uuid : null,
                "value": signer.name
            },
            {
                "uuid": this.signer.find(signer => signer.name === 'signer_last_name_1') != undefined ? this.signer.find(signer => signer.name === 'signer_last_name_1').uuid : null,
                "value": signer.lastName
            },
            {
                "uuid": this.signer.find(signer => signer.name === 'signer_document_type_1') != undefined ? this.signer.find(signer => signer.name === 'signer_document_type_1').uuid : null,
                "value": signer.typeOfIdn
            },
            {
                "uuid": this.signer.find(signer => signer.name === 'signer_document_1') != undefined ? this.signer.find(signer => signer.name === 'signer_document_1').uuid : null,
                "value": signer.idn
            },
            {
                "uuid": this.signer.find(signer => signer.name === 'signer_phone_1') != undefined ? this.signer.find(signer => signer.name === 'signer_phone_1').uuid : null,
                "value": signer.phone
            },
            {
                "uuid": this.signer.find(signer => signer.name === 'signer_mail_1') != undefined ? this.signer.find(signer => signer.name === 'signer_mail_1').uuid : null,
                "value": signer.email
            },
            {
                "uuid": this.signer.find(signer => signer.name === 'signer_role_1') != undefined ? this.signer.find(signer => signer.name === 'signer_role_1').uuid : null,
                "value": "Firmante"
            }
        )
        variables.splice(0, 1)
        return variables;
    }


    private onError(error) {
        this.showMsgSnack('Ha ocurrido un error');
    }


    private showMsgSnack(msg) {
        this._snackBar.open(msg, '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
        });
    }

    private showMsgSnackError(msg) {
        this._snackBar.open(msg, '', {
            duration: 7000,
            panelClass: ['red-snackbar'],
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
        });
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    //Fin sincroniza los datos obtenidos por formulario con los requeridos para enviar a firmar
    /////////////////////////////////////////////////////////////////////////////////////////


    private validDocumentData = () => {
        let promise = new Promise((resolve, reject) => {
            if(!this.biometricSigner && !this.severalSigners){
                this.validPhoneNumber();
            }
            let isValid = true
            this.document.forEach(element => {
                let elementHtml = document.getElementById(element.uuid)
                if (element.type == "dateInput") {

                }
                if (element.requiered) {
                    if (element.type == "check") {
                        if (!elementHtml['checked']) {
                            elementHtml.parentNode['style']['color'] = 'red';
                            isValid = false
                        } else {
                            elementHtml.parentNode['style']['color'] = 'black';
                        }
                    } else {
                        if (elementHtml['value'] == "") {
                            elementHtml['style']['background-color'] = "#ffbaba"
                            isValid = false
                        } else (
                            elementHtml['style']['background-color'] = "#C8D6F3"
                        )
                    }
                }
            });
            resolve(isValid);
        });
        return promise;
    };

    private validPhoneNumber() {
        //Valid addressee phone
        if (!this.validationService.validatePhoneByCountry(this.documentForm.controls['addressee_country'].value, this.documentForm.controls['addressee_phone'].value))
            this.documentForm.controls.addressee_phone.setErrors({ invalidNumber: true });
        //Vaid signers phone
        this.documentForm.controls.signers.value.forEach((signer, index) => {
            if (!this.validationService.validatePhoneByCountry(signer.signer_country, signer.signer_phone))
                this.documentForm.controls.signers['controls'][index]['controls'].signer_phone.setErrors({ invalidNumber: true })
        });
    }

    onSubmit() {
        this.validDocumentData().then(dataIsValid => {
            if (dataIsValid && this.documentForm.valid) { //this.documentForm.valid
                this.buttonDisable = true;
                if (this.severalSigners) {
                    let documentBatch = new DocumentBatch();
                    this.matchDataSeveralSigners().then(values => {
                        documentBatch.documentType = Number(this.documentForm.controls['signatureType'].value);
                        documentBatch.signers = Object.values(values);
                        documentBatch.templateUuid = this.id
                        documentBatch.sendEmailCreator = this.sendEmailCreator;
                        documentBatch.expedient = this.documentForm.controls['expedient'].value;
                        //Envía documento a firmar
                        this.documentsService.postDocumentBatch(documentBatch).subscribe(dataDService => {   // data is of type HttpResponse<Object>
                            if (dataDService.status == 200) {
                                this.showMsgSnack('Documento enviado a firmar');
                                this.router.navigate(['/templates']);
                            }
                        },
                            error => this.onError(error)
                        )
                    },
                    (invalidSigners) => {
                        this.buttonDisable = false;
                        invalidSigners.forEach(invalidsIgner => {
                            this.showMsgSnackError(`Valide que el firmante ${invalidsIgner.name} ${invalidsIgner.lastName} tiene registrado teléfono y correo para firma remota`);
                        });
                        return null;
                     }
                    )

                } else {
                    let document = new Document();
                    Promise.all([this.matchDataAddesse(), this.matchDataDocument(), this.matchDataSigners(), this.matchDataForm(), this.matchDataUser()]).then(values => {
                        document.documentType = Number(this.documentForm.controls['signatureType'].value);
                        document.variables = Object.values(values[0]).concat(Object.values(values[1]), Object.values(values[2][1]), Object.values(values[3]), Object.values(values[4]));
                        document.signers = values[2][0];
                        document.templateUuid = this.id
                        document.sendEmailCreator = this.sendEmailCreator
                        document.expedient = this.documentForm.controls['expedient'].value;
                        this.documentsService.postDocument(document).subscribe(dataDService => {
                            if (dataDService.status == 200) {
                                this.showMsgSnack('Documento enviado a firmar');
                                this.router.navigate(['/templates']);
                            }
                        },
                            error => this.onError(error)
                        )

                    })
                }
                return null;
            } else {
                this.buttonDisable = false;
                this.showMsgSnack('Por favor ingrese los datos requeridos');
                var texto = "Por favor ingrese los datos requeridos"
            }
        })
    }

}
