import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AutenticationService } from './autentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class IsLoginService implements CanActivate {

  constructor(private authService: AutenticationService, private router: Router) { }

    canActivate() {
        if (!this.authService.isLogged()) {
            this.router.navigate(['/login']);
            return false;
        }

        return true;
    }
}
