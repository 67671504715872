import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AutenticationService } from 'src/app/service/autentication.service';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;
  isLoggedIn2$: Observable<any>;
  isLoggedInGuest$: Observable<boolean>;
  loggedUser$: Observable<any>;
  breadcrumb$: Observable<Array<Object>>;
  flagLoggin: boolean;

  constructor(
    private authService: AutenticationService,
    private breadcrumbService: BreadcrumbService,
    private _snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isLoggedInGuest$ = this.authService.isLoggedInGuest;
    this.loggedUser$ = this.authService.loggedUser;
    this.breadcrumb$ = this.breadcrumbService.getBreadcrumb();
  }

  onLogout() {
    this.authService.logout();
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }
}
