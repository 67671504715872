import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/service/users.service';
import { DocumentImage } from 'src/app/models/documentImage.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user.model';
import { UserConsents } from 'src/app/models/user-consents.model';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { AutenticationService } from '../service/autentication.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Elija un logo';
  reader = new FileReader;
  imageConverter: [DocumentImage];
  img = new Image;
  imagepdf: string;
  pageImagePdf: number = 0;
  lengthPageImagePdf: number = 0;
  validFile = false;
  buttonDisable = false;
  doneImageConvert;
  user: User;
  userConsent: UserConsents;
  loading: boolean = true;
  viewForm = false;

  passForm = new FormGroup({
    oldPassword: new FormControl('', [
      Validators.required,
    ]),
    newPassword: new FormControl('', [
      Validators.required
    ]),
    confirmPassword: new FormControl('', [
      Validators.required
    ])
  });

  accountForm = new FormGroup({
    cif: new FormControl('', [
      Validators.required,
    ]),
    name: new FormControl('', [
      Validators.required,
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\+{1}(0|[1-9]\d*)?$/),
      Validators.minLength(9),
      Validators.maxLength(15),
    ]),
    email: new FormControl('', []),
    emailSender: new FormControl('', []),
    emailBody: new FormControl('', []),
    businessName: new FormControl('', []),
    direction: new FormControl('', []),
    url: new FormControl('', [
      Validators.pattern(/^(https):\/\/[\w\-]+(\.[\w\-]+)+[\/#?]?[\w\/\.-]+$/),
    ]),
    urlLogo: new FormControl('', [
      Validators.pattern(/^(https):\/\/[\w\-]+(\.[\w\-]+)+[\/#?]?[\w\/\.-]+$/),
    ])
  });

  constructor(private formBuilder: FormBuilder,
    private userService: UsersService,
    private _snackBar: MatSnackBar,
    private activatedRouter: ActivatedRoute,
    private autenticationService: AutenticationService,
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {
    this.activatedRouter.params.subscribe(parametros => {
      this.viewForm = parametros['viewForm'];
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setElements([]);
    this.loading = true;

    this.userService.getUser().subscribe(user => {
      if (user != null) {
        this.user = user;
        this.accountForm.controls['cif'].setValue(this.user.cif);
        this.accountForm.controls['businessName'].setValue(this.user.businessName);
        this.accountForm.controls['phone'].setValue(this.user.phone);
        this.accountForm.controls['email'].setValue(this.user.email);
        this.accountForm.controls['emailSender'].setValue(this.user.emailSender);
        this.accountForm.controls['emailBody'].setValue(this.user.emailBody);
        this.accountForm.controls['url'].setValue(this.user.url);
        this.accountForm.controls['direction'].setValue(this.user.direction);
        this.accountForm.controls['urlLogo'].setValue(this.user.urlLogo);

        this.userService.getUserConsents(this.user.username).subscribe(resp => {
          if (resp != null) {
            this.user.name = resp.name;
            this.accountForm.controls['name'].setValue(this.user.name);
            this.loading = false;
          }
        },
          error => {
            if (error.status == 401) {
            } else {
              this.showMsgSnack("Error obteniendo nombre de usuario");
            }
          });
      }
    },
      error => {
        if (error.status == 401) {
        } else {
          this.showMsgSnack("Error obteniendo datos del usuario");
        }
      });
  }

  onSubmit() {
    if (this.accountForm.valid && this.accountForm.dirty) {
      let userData = new User(this.accountForm.value);
      this.userService.putUser(userData).subscribe(dataDService => {
        if (dataDService.status == 200) {
          this.userConsent = new UserConsents();
          this.userConsent.name = this.accountForm.controls['name'].value;
          this.userConsent.username = this.user.username;
          this.userConsent.password = "****";
          this.userService.putUserConsents(this.userConsent).subscribe(resp => {
            if (resp.status == 200) {
              this.accountForm.markAsPristine();
              let user: any = JSON.parse(localStorage.getItem("user"));
              user.urlLogo = this.accountForm.controls.urlLogo.value;
              localStorage.setItem('user', JSON.stringify(user));
              window.location.reload();
              this._snackBar.open("Datos actualizados correctamente", "", {
                duration: 2000,
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
              });
            }
          },
            error => {
              this.showMsgSnack(error);
            }
          );
        }
      },
        error => {
          this.showMsgSnack(error);
        }
      )
    } else {
      this.showMsgSnack('Por favor ingrese los datos requeridos.');
    }
  }


  private updatePassword() {
    if (this.passForm.valid) {
      if (this.passForm.controls['newPassword'].value === this.passForm.controls['confirmPassword'].value) {
        this.userConsent = new UserConsents();
        this.userConsent.password = this.passForm.controls['oldPassword'].value;
        this.userConsent.username = this.user.username;
        this.autenticationService.login(this.userConsent).subscribe(dataUser => {
          if (dataUser.status == 200) {
            this.userConsent.password = this.passForm.controls['newPassword'].value;
            this.userConsent.name = this.user.name;
            this.autenticationService.changePassword(this.userConsent).subscribe(resp => {
              if (resp.status == 200) {
                this.showMsgSnack("Clave cambiada exitosamente");
                this.router.navigate(['/account']);
              }
            }, error => {
              this.showMsgSnack("Error cambiando la clave");
            });
          }
        },
          error => {
            if (error.status == 401) {
              this.showMsgSnack("Clave incorrecta");
            } else {
              this.showMsgSnack("Error validando credenciales");
            }
          }
        )
      } else {
        this.showMsgSnack("La nueva clave no coincide con la confirmación de clave!");
      }
    } else {
      this.showMsgSnack("Por favor ingrese los datos requeridos.");
    }
  }

  private editarPassword() {
    this.viewForm = true;
  }

  cancel() {
    this.viewForm = false;
    this.router.navigate(['/account']);
  }

  private click() {
    if (!this.accountForm.dirty) {
      this.showMsgSnack('No se han realizado cambios sobre los datos.');
    }
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error.');
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }
}
