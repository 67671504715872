<div class="content">
    <img class="img-login" src="../../assets/img/fondo_login.jpg" alt="Bienvenido a SuperDocs"/>
    <div class="wrapper-login">
      <header class="cabecera-login bg-ds">
        <img src="../../assets/img/SuperDocs.png" alt="Superdocs es un producto de DS Legal"/>
      </header>
      <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="login">
        <div class="row">
            <mat-form-field>
                <input matInput placeholder="Usuario" formControlName="username" required class="validate">
                <mat-error>
                  Por favor teclee un usuario
                </mat-error>
              </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput placeholder="Ex. pat@example.com" formControlName="email" required class="validate">
                <mat-error
                    *ngIf="passwordForm.controls.email.hasError('email') && !passwordForm.controls.email.hasError('required')">
                    Por favor ingrese un correo válido
                </mat-error>
                <mat-error *ngIf="passwordForm.controls.email.hasError('required')">
                    Correo <strong>requerido</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <button class="boton">Enviar</button>
    </form>
      <div class="logo-power">
        <img src="../../assets/img/DSLegaltech.png"  alt="power by unidad legaltech de DS Legal"/> </div>
    </div>
  </div>
  