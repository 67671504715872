import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errormessage'
})
export class ErrormessagePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value.startsWith("{", 0)){
      let val = JSON.parse(value);
      return val.error;
    }else{
      return value;
    }

  }

}
