<div class="wrapper">
  <div class="cuerpo">

    <h1 class="tp-navandu line-navandu">REENVIAR DOCUMENTO</h1>

    <app-spinner *ngIf="loading"></app-spinner>

    <form name="entrar" [formGroup]="documentForm" class="formulario" autocomplete="off" *ngIf="!loading">

      <div class="documento" *ngIf="showSignatureChanels || documentForm.controls['signatureType'].value == 2 ||template.templateType.name == 'File'|| template.templateType.name == 'SetFileAdmin' || doneImageConvert=='loading'">

        <div class="flex-row" *ngIf="documento.documentType == 'biometric'">
          <mat-form-field>
            <mat-label>Dispositivo</mat-label>
            <mat-select formControlName="device">
              <mat-option *ngFor="let device of devices" [value]="device.deviceName">
                {{device.deviceDescription}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="documento sign">
          <app-spinner *ngIf="doneImageConvert=='loading'"></app-spinner>
          <div *ngIf="doneImageConvert == 'done'">
            <div class="select-state" *ngIf="!loading">
              <button mat-icon-button class="refrescar" (click)="changePage('previus')" type="button">
                <div class="tooltip3">
                  <mat-icon>chevron_left</mat-icon>
                  <span class="tooltiptext3">Anterior</span>
                </div>
              </button>
              <p>Página: {{pageImagePdf +1}} de {{lengthPageImagePdf}}</p>
              <button mat-icon-button class="refrescar" (click)="changePage('next')" type="button">
                <div class="tooltip3">
                  <mat-icon>chevron_right</mat-icon>
                  <span class="tooltiptext3">Siguiente</span>
                </div>
              </button>
            </div>
            <div class="example-boundary" [style.backgroundImage]="'url('+ imagepdf +')'">
              <div class="element example-box" cdkDragBoundary=".example-boundary" cdkDrag
                (cdkDragEnded)="dragEnd($event, 0)" id="coordinate" (click)="displayContextMenu($event, 0)"
                [cdkDragDisabled]="isDisplayContextMenu[0].locked">
                Firma 1
                <app-context-menu *ngIf="isDisplayContextMenu[0].visible" [ngStyle]="getRightClickMenuStyle()"
                  [contextMenuItems]="rightClickMenuItems" (onContextMenuItemClick)="handleMenuItemClick($event, 0)">
                </app-context-menu>
              </div>
              <div *ngFor="let firmante of arraySigners; let i = index">
                <div class="element example-box" cdkDragBoundary=".example-boundary" cdkDrag [cdkDragFreeDragPosition]="firmante"
                  (cdkDragEnded)="dragEnd($event, i+1)" id="coordinate[{{i}}]" (click)="displayContextMenu($event, i+1)"
                  [cdkDragDisabled]="isDisplayContextMenu[i+1].locked">Firma {{i +2}}
                  <app-context-menu *ngIf="isDisplayContextMenu[i+1].visible" [ngStyle]="getRightClickMenuStyle()"
                    [contextMenuItems]="rightClickMenuItems" (onContextMenuItemClick)="handleMenuItemClick($event, i+1)">
                  </app-context-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="enviar">
        <input type="submit" class="boton" value="Reenviar" (click)="onSubmit()" [disabled]="buttonDisable">
      </div>
      <a routerLink="/documents" class="volver"><span>Volver</span> </a>

    </form>
  </div>
</div>
