import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';

import { GuestsService } from 'src/app/service/guests.service'
import { Document } from 'src/app/models/document.model'
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class GuestSetupComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Elija un archivo';

  uuid: string
  loading: boolean;
  document: Document;
  documentName: string;
  externalUrl: string;
  reader = new FileReader;
  instructions = "";

  documentForm = new FormGroup({
    file: new FormControl('', [
      Validators.required,
    ])
  });

  constructor(private guestsService: GuestsService,
    private activatedRouter: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private router: Router,
    private breadcrumbService: BreadcrumbService) {
      this.activatedRouter.params.subscribe(parametros => {
        this.uuid = parametros['id'];
      });
    }

  ngOnInit(): void {
    this.loading = true;
    this.guestsService.getDocumentByUuid(this.uuid).subscribe(data => {
      if (data != null) {
        this.document = data;
        this.instructions = data.content;
        this.breadcrumbService.setElements([
          { name: 'Documentos', link: '/guest/documents' },
          { name: data.templateName, link: `/guest/document/${this.uuid}/setup` }
        ]);
        this.updateDocumentName();
        this.loading = false;
      }
    }, error => this.onError(error));
  }

  uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name;
      });

      this.reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          let imgBase64Path = e.target.result;
        };
      };
      this.reader.readAsDataURL(imgFile.target.files[0]);
      this.fileInput.nativeElement.value = '';
    } else {
      this.fileAttr = 'Elija un archivo';
    }
  }

  onSubmit() {
    if (this.reader.readyState == 2) {
      let data = <string>this.reader.result;
      let [dataType, base64result] = data.split(';base64,');
      dataType = dataType.split('data:')[1];
      let datatosend = { 'base64': base64result, 'base64Type': dataType };
      this.showMsgSnack('Enviando documento...');
      this.guestsService.putDocument(this.uuid, datatosend).subscribe(data => {
        if (data.status == 200) {
          this.loading = true;
          this.router.navigate(['/guest/documents']);
        }
      }, error => this.onError(error));

    } else {
      this.showMsgSnack('El documento no se ha cargado');
    }
  }

  private updateDocumentName() {
    if (this.document.variableResponses.length > 0) {
      this.documentName = this.document.variableResponses[0].value;
    }
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error');
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

}
