import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs'

import { Pageable } from 'src/app/models/pageable.model'
import { ProcessConfig } from 'src/app/models/processConfig.model'
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiProcessConfigService {

  private basePath = environment.uriApiAzure;

  constructor(private http: HttpClient) { }


  public getProcessConfig(): Observable<ProcessConfig[]> {   //PageDocument
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<ProcessConfig[]>(this.basePath + '/process_config', httpOptions);
  }


  postProcessConfig(uuid : string, data): Observable<HttpResponse<ProcessConfig>> {
    let headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("token")
      })
    return this.http.post<ProcessConfig>(this.basePath + '/process_config/' + uuid + '/process', data, { headers: headers, observe: 'response' });
  }

}
