<div class="wrapper">

  <div class="cuerpo">


    <h1 class="tp-navandu line-navandu">GENERACIÓN DE DOCUMENTO</h1>

    <form name="entrar" [formGroup]="documentForm" (ngSubmit)="onSubmit()" class="formulario" autocomplete="off">
      <div class="row documento">

        <div class="switch-button">
          <label class="varios-firmantes">¿Enviar a varios firmantes?</label>
          <input type="checkbox" name="switch-button" id="switch-label" class="switch-button__checkbox"
            (click)="checkSeveralSigner($event)">
          <label for="switch-label" class="switch-button__label"></label>
          <label class="varios-firmantes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;¿Enviar alerta documento firmado (usuario)?</label>
          <input type="checkbox" name="switch-button" id="switch-label4" class="switch-button__checkbox"
          (click)="checkSendEmailToCreator($event)" checked="true">
          <label for="switch-label4" class="switch-button__label"></label>
        </div>

        <div class="info-destinatario" *ngIf="!severalSigners">
          <h2 class="tp-navandu"> INFORMACIÓN DEL DESTINATARIO </h2>
          <div class="flex-row">
            <mat-form-field class="example-full-width">
              <mat-label>Número de documento</mat-label>
              <input type="text" matInput formControlName="addressee_document" required [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.idn">
                  {{option.idn}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Tipo de documento</mat-label>
              <mat-select required formControlName="addressee_document_type">
                <mat-option value="Alias">Alias</mat-option>
                <mat-option value="DNI">DNI</mat-option>
                <mat-option value="NIF">NIF</mat-option>
                <mat-option value="NIE">NIE</mat-option>
                <mat-option value="CIF">CIF</mat-option>
                <mat-option value="PASS">PASS</mat-option>
                <mat-option value="RUC">RUC</mat-option>
                <mat-option value="Cedula">Cedula</mat-option>
                <mat-option value="Otro">Otro</mat-option>
              </mat-select>
              <mat-error *ngIf="documentForm.controls.addressee_document_type.hasError('required')">
                Tipo de documento <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex-row">
            <mat-form-field class="example-full-width">
              <mat-label>Nombre</mat-label>
              <input type="text" matInput formControlName="addressee_name" required [matAutocomplete]="autoName">
              <mat-autocomplete #autoName="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsName | async" [value]="option.name">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Apellidos</mat-label>
              <input type="text" matInput formControlName="addressee_last_name" required  [matAutocomplete]="autoLastName">
              <mat-autocomplete #autoLastName="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsLastName | async" [value]="option.lastName">
                  {{option.lastName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="documentForm.controls.addressee_last_name.hasError('required')">
                Apellido <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex-row filatres">
            <div class="phone-group">
              <mat-form-field class="prefix-section">
                <mat-select required formControlName="addressee_country">
                  <mat-option *ngFor="let phonePrefix of phonePrefixes" [value]="phonePrefix.prefix">
                    {{phonePrefix.code}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="phone">
                <mat-label>Móvil para sms de firma</mat-label>
                <input matInput formControlName="addressee_phone"
                  [required]="documentForm.controls.addressee_phone.hasError('required')">
                <mat-error *ngIf="documentForm.controls.addressee_phone.hasError('required')">
                  Móvil <strong>requerido</strong>
                </mat-error>
                <mat-error
                  *ngIf="documentForm.controls.addressee_phone.hasError('minlength') || documentForm.controls.addressee_phone.hasError('maxlength')">
                  Móvil <strong>debe tener 9 caracteres</strong>
                </mat-error>
                <mat-error *ngIf="documentForm.controls.addressee_phone.hasError('pattern')">
                  Móvil <strong>debe ser numérico</strong>
                </mat-error>
                <mat-error *ngIf="documentForm.controls.addressee_phone.hasError('invalidNumber')">
                  Móvil <strong>no valido</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <mat-form-field class="email">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Ex. pat@example.com" formControlName="addressee_mail"
                [required]="documentForm.controls.addressee_mail.hasError('required')">
              <mat-error
                *ngIf="documentForm.controls.addressee_mail.hasError('email') && !documentForm.controls.addressee_mail.hasError('required')">
                Por favor ingrese un correo válido
              </mat-error>
              <mat-error *ngIf="documentForm.controls.addressee_mail.hasError('required')">
                Correo <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="condicion">
            <mat-label>¿El destinatario es el mismo que firma?
            </mat-label>
            <input type="checkbox" formControlName="addresseeAsSigner" checked (click)="checkValue($event)">
          </div>

          <div class="tooltip2 iconomas"
            *ngIf="documentForm.controls.addresseeAsSigner.value && countSignersForms>countSigners+1">
            <a class="tp-navandu" (click)="addSigner()">
              <mat-icon>add_circle</mat-icon>
            </a>
            <span class="tooltiptext2">Añadir firmante</span>
          </div>
        </div>


        <div class="listado-firmantes" [hidden]="!severalSigners">
          <h2>LISTADO DE FIRMANTES PARA ENVIAR DOCUMENTO</h2>
          <div class="input-field col s12 buscador">

            <input id="filter" type="text" (keyup)="applyFilter($event)">
            <label for="filter" data-error="Ingrese un dato valido" data-success=""> <i
                class="material-icons">search</i></label>
          </div>

          <table mat-table [dataSource]="dataSource" class="tabla">

            <!-- check Column -->
            <ng-container matColumnDef="check">
              <th mat-header-cell *matHeaderCellDef> Acción </th>
              <td mat-cell *matCellDef="let element">
                <input type="checkbox" name="switch-button" id="switch-label" class="switch-button__checkbox"
                  (click)="checkSignser($event, element)" [checked]="element.checked">
              </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Nombre</th>
              <td mat-cell *matCellDef="let element"> {{element.name + " " + element.lastName}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="idn">
              <th mat-header-cell *matHeaderCellDef> Documento </th>
              <td mat-cell *matCellDef="let element"> {{element.idn}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Correo </th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Teléfono </th>
              <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

        </div>



      </div>

      <div formArrayName="signers"
        *ngFor="let signer of getSigners()['controls']; let j = index; let last = last; let first = first">
        <div class="row documento">
          <div [formGroupName]="j">
            <h2 *ngIf="documentForm.controls.addresseeAsSigner.value; else notSigner" class="tp-navandu"> INFORMACIÓN
              DEL FIRMANTE {{j + 2}}</h2>
            <ng-template #notSigner>
              <h2 class="tp-navandu"> INFORMACIÓN DEL FIRMANTE {{j + 1}}</h2>
            </ng-template>
            <div class="flex-row">
              <mat-form-field>
                <mat-label>En calidad de</mat-label>
                <mat-select required formControlName="signer_role">
                  <mat-option *ngFor="let role of signersRole" [value]="role">
                    {{role.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="doc-group">
                <mat-form-field class="num-doc">
                  <mat-label>Número de documento</mat-label>
                  <input type="text" matInput formControlName="signer_document" required [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filterOptionsSigners[j] | async" [value]="option.idn">
                      {{option.idn}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="tipo-doc">
                  <mat-label>Tipo de documento</mat-label>
                  <mat-select required formControlName="signer_documet_type">
                    <mat-option value="Alias">Alias</mat-option>
                    <mat-option value="DNI">DNI</mat-option>
                    <mat-option value="NIF">NIF</mat-option>
                    <mat-option value="NIE">NIE</mat-option>
                    <mat-option value="CIF">CIF</mat-option>
                    <mat-option value="PASS">PASS</mat-option>
                    <mat-option value="RUC">RUC</mat-option>
                    <mat-option value="Cedula">Cedula</mat-option>
                    <mat-option value="Otro">Otro</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="flex-row">
              <mat-form-field>
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="signer_name" required>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Apellidos</mat-label>
                <input matInput formControlName="signer_last_name" required>
              </mat-form-field>
            </div>
            <div class="flex-row filatres">
              <div class="phone-group">
                <select class="prefix" formControlName="signer_country">
                  <option value="+34" selected>Esp</option>
                  <option value="+593">ECU</option>
                </select>
                <mat-form-field class="phone">
                  <mat-label>Móvil para sms de firma</mat-label>
                  <input matInput formControlName="signer_phone"
                    [required]="signer.controls.signer_phone.hasError('required')">
                  <mat-error *ngIf="signer.controls.signer_phone.hasError('required')">
                    Móvil <strong>requerido</strong>
                  </mat-error>
                  <mat-error
                    *ngIf="signer.controls.signer_phone.hasError('minlength') || signer.controls.signer_phone.hasError('maxlength')">
                    Móvil <strong>debe tener 9 caracteres</strong>
                  </mat-error>
                  <mat-error *ngIf="signer.controls.signer_phone.hasError('pattern')">
                    Móvil <strong>debe ser numérico</strong>
                  </mat-error>
                  <mat-error *ngIf="signer.controls.signer_phone.hasError('invalidNumber')">
                    Móvil <strong>no valido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-form-field class="email">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Ex. pat@example.com" formControlName="signer_mail"
                  [required]="signer.controls.signer_mail.hasError('required')">
                <mat-error
                  *ngIf="signer.controls.signer_mail.hasError('email') && !signer.controls.signer_mail.hasError('required')">
                  Por favor ingrese un correo válido
                </mat-error>
                <mat-error *ngIf="signer.controls.signer_mail.hasError('required')">
                  Correo <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="masmenos">
            <div class="tooltip2 iconomenos">
              <a *ngIf="countSigners>1 || documentForm.controls.addresseeAsSigner.value" class="tp-navandu"
                (click)="removeSigner(j)">
                <mat-icon>remove_circle</mat-icon>
              </a>
              <span class="tooltiptext2">Quitar firmante</span>
            </div>
            <div *ngIf="documentForm.controls.addresseeAsSigner.value; else notAsSigner">
              <div class="tooltip2 iconomas" *ngIf="last && countSignersForms>countSigners+1">
                <a class="tp-navandu" (click)="addSigner()">
                  <mat-icon>add_circle</mat-icon>
                </a>
                <span class="tooltiptext2">Añadir firmante</span>
              </div>
            </div>
            <ng-template #notAsSigner>
              <div class="tooltip2 iconomas" *ngIf="last && countSignersForms>countSigners">
                <a class="tp-navandu" (click)="addSigner()">
                  <mat-icon>add_circle</mat-icon>
                </a>
                <span class="tooltiptext2">Añadir firmante</span>
              </div>
            </ng-template>

          </div>
        </div>
      </div>

      <div class="documento">
        <div *ngIf="template.content != undefined" class="borrador">
          <h1>{{template.name}}</h1>
          <div [innerHTML]="template.content | safeHtml"></div>
        </div>

      </div>

      <div class="documento">
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Tipo de firma</mat-label>
            <mat-select required formControlName="signatureType" (selectionChange)="select($event.value)">
              <mat-option *ngFor="let chanel of signatureChanels" [value]="chanel.id">
                {{chanel.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="expediente">
            <mat-label>Nº Expediente</mat-label>
            <input matInput formControlName="expedient" required>
          </mat-form-field>
        </div>



        <div class="flex-row" *ngIf="documentForm.controls['signatureType'].value == '2'">
          <mat-form-field>
            <mat-label>Dispositivo</mat-label>
            <mat-select formControlName="device">
              <mat-option *ngFor="let device of devices" [value]="device.deviceName">
                {{device.deviceDescription}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>




        <div class="enviar">
          <input type="submit" class="boton" value="Enviar" [disabled]="buttonDisable">
        </div>

      <a routerLink="/templates" class="volver"><span>Volver</span> </a>

    </form>
  </div>
</div>
