export class DocumentBatch {
    public templateUuid: string;
    public documentType: number;
    public templateName: string;
    public signers: Object;
    public signatureDate: Date;
    public expedient: string;
    public companyUuid: string;
    public base64: string;
    public sendEmail: boolean;
    public sendEmailCreator: boolean;
    public coordinates: Object;;
}
