<div class="wrapper">
  <div class="cuerpo">

    <h1 class="tp-navandu line-navandu">FIRMANTES</h1>
    <div class="input-field col s12 buscador">
      <input id="filter" type="text" (keyup)="applyFilter($event)">
      <label for="filter" data-error="Ingrese un dato valido" data-success="">
        <i class="material-icons">search</i>
      </label>
    </div>

    <app-spinner *ngIf="loading"></app-spinner>

    <div class="select-state" *ngIf="!loading">
      <button mat-icon-button class="refrescar" (click)="sign('new')">
        <div class="tooltip3">
          <mat-icon>add_circle</mat-icon>
          <span class="tooltiptext3">Nuevo firmante</span>
        </div>
      </button>
    </div>


    <table mat-table [dataSource]="dataSource" class="tabla" *ngIf="!loading">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.name + " " + element.lastName}}</td>
      </ng-container>

      <ng-container matColumnDef="idn">
        <th mat-header-cell *matHeaderCellDef> Documento </th>
        <td mat-cell *matCellDef="let element"> {{element.idn}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Correo </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Teléfono </th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef> F. Creación </th>
        <td mat-cell *matCellDef="let element"> {{element.creationDate | date:'dd-MM-yyyy HH:mm'}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Acción </th>
        <td mat-cell *matCellDef="let element">
          <button (click)="sign(element.uuid)" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
            <div class="tooltip3">
              <mat-icon>send</mat-icon>
              <span class="tooltiptext3">Editar</span>
            </div>
          </button>
          <button (click)="delete(element.uuid)" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
            <div class="tooltip3">
              <mat-icon>delete</mat-icon>
              <span class="tooltiptext3">Eliminar</span>
            </div>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

  <div class="ver-firmante" *ngIf="viewForm">
    <form name="entrar" class="formulario form-gen-doc" autocomplete="off" [formGroup]="signerForm" (ngSubmit)="onSubmit()">
      <div class="row documento">
        <h2 class="tp-navandu"> INFORMACIÓN DEL FIRMANTE </h2>

        <div class="flex-row">
          <mat-form-field class="example-full-width">
            <mat-label>Número de documento</mat-label>
            <input type="text" matInput formControlName="idn" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tipo de documento</mat-label>
            <mat-select required formControlName="typeOfIdn">
              <mat-option value="Alias">Alias</mat-option>
              <mat-option value="DNI">DNI</mat-option>
              <mat-option value="NIF">NIF</mat-option>
              <mat-option value="NIE">NIE</mat-option>
              <mat-option value="CIF">CIF</mat-option>
              <mat-option value="PASS">PASS</mat-option>
              <mat-option value="RUC">RUC</mat-option>
              <mat-option value="Cedula">Cedula</mat-option>
              <mat-option value="Otro">Otro</mat-option>
            </mat-select>
            <mat-error *ngIf="signerForm.controls.typeOfIdn.hasError('required')">
              Tipo de documento <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" required>
            <mat-error *ngIf="signerForm.controls.name.hasError('required')">
              Nombre <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Apellidos</mat-label>
            <input matInput formControlName="lastName" required>
            <mat-error *ngIf="signerForm.controls.lastName.hasError('required')">
              Apellido <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row filatres">
          <div class="phone-group">
            <mat-form-field class="prefix-section">
              <mat-select required formControlName="countryPhone">
                <mat-option *ngFor="let phonePrefix of phonePrefixes" [value]="phonePrefix.prefix">
                  {{phonePrefix.code}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="phone">
              <mat-label>Móvil para sms de firma</mat-label>
              <input matInput formControlName="phone">
              <mat-error *ngIf="signerForm.controls.phone.hasError('required')">
                Móvil <strong>requerido</strong>
              </mat-error>
              <mat-error *ngIf="signerForm.controls.phone.hasError('minlength') || signerForm.controls.phone.hasError('maxlength')">
                Móvil <strong>debe tener 9 caracteres</strong>
              </mat-error>
              <mat-error *ngIf="signerForm.controls.phone.hasError('pattern')">
                Móvil <strong>debe ser numérico</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <mat-form-field class="email">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Ex. pat@example.com" formControlName="email">
            <mat-error *ngIf="signerForm.controls.email.hasError('email') && !signerForm.controls.email.hasError('required')">
              Por favor ingrese un correo válido
            </mat-error>
            <mat-error *ngIf="signerForm.controls.email.hasError('required')">
              Correo <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row filatres">
          <mat-form-field class="email">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Ex. pat@example.com" formControlName="email2">
            <mat-error *ngIf="signerForm.controls.email.hasError('email2')">
              Por favor ingrese un correo válido
            </mat-error>
          </mat-form-field>
        </div>


      </div>
      <div class="enviar2">

        <input type="button" (click)="cancel()" class="boton volver2" value="Cancelar">
        <input type="submit" class="boton volver2" value="Guardar">
      </div>

    </form>

  </div>

  <div class="ver-detalle" *ngIf="askDelete">
    <div class="datos-detalle">
      <div class="row documento">
        <h2 class="tp-navandu"> ¿Seguro que desea eliminar firmante? </h2>
        <div class="enviar2">
          <input type="button" (click)="beDelete(false)" class="boton volver2" value="Cancelar">
          <input type="button" (click)="beDelete(true)" class="boton volver2" value="Eliminar">
        </div>

      </div>
    </div>

  </div>
