import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { GuestsService } from 'src/app/service/guests.service'
import { Template } from 'src/app/models/template.model'
import { VariableDocument } from 'src/app/models/variableDocument.model'
import { VariableEntity } from 'src/app/entity/variable.entity'
import { Signer } from 'src/app/models/signer.model'
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';


@Component({
  selector: 'app-guest-setsigner',
  templateUrl: './setsigner.component.html',
  styleUrls: ['./setsigner.component.css']
})
export class GuestSetSignerComponent implements OnInit {

  id: string;
  loading: boolean = true;
  readyToSign: boolean = false;
  externalUrl: string;
  documentForm = new FormGroup({});
  buttonDisable = false;
  template: Template = new Template();
  addressee: [VariableEntity] = [new VariableEntity()];
  signerAdressee: Signer;
  signer: [VariableEntity] = [new VariableEntity()];
  document: [VariableEntity] = [new VariableEntity()];
  form: [VariableEntity] = [new VariableEntity()];
  user: [VariableEntity] = [new VariableEntity()];
  sendSignDocument = 0;
  defaultContent: string;
  mapper = {};

  constructor(
    private guestsService: GuestsService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private _snackBar: MatSnackBar
  ) {
    this.activatedRouter.params.subscribe(parametros => {
      this.id = parametros['id'];
    });
  }

  ngOnDestroy() {
    this.sendSignDocument = 8;
  }

  ngOnInit(): void {
    this.loading = true;
    this.guestsService.getDocumentByUuid(this.id).subscribe(data => {
      if (data != null) {
        this.signerAdressee = data.signers[0];
        this.template = data.template;
        this.defaultContent = this.template.content;
        this.documentForm.valueChanges.subscribe(data => {
          this.uploadDocument()
        })
        this.sortComponent(this.template.variables);
        this.breadcrumbService.setElements([
          { name: 'Documentos', link: '/guest/documents' },
          { name: data.templateName, link: `/guest/document/${this.id}/setsigner` }
        ]);
        this.loading = false;

        this.guestsService.getGlobalVariables(this.id).subscribe(data => {
          data.forEach((globalVariable) => {
            if (this.mapper[globalVariable.name]) {
              let patch = {}
              let mapper = this.mapper[globalVariable.name];
              if (mapper.config) {
                if (mapper.config.includes(globalVariable.value)) {
                  patch[mapper.uuid] = globalVariable.value;
                }
              } else {
                patch[mapper.uuid] = globalVariable.value;
              }
              this.documentForm.patchValue(patch);
            }
          });
        });

      }
    }, error => this.onError(error));
  }

  private validDocumentData = () => {
    let promise = new Promise((resolve, reject) => {
      let isValid = true
      this.document.forEach(element => {
        let elementHtml = document.getElementById(element.uuid)
        if (element.type == "dateInput") {

        }
        if (element.requiered) {
          if (element.type == "check") {
            if (!elementHtml['checked']) {
              elementHtml.parentNode['style']['color'] = 'red';
              isValid = false
            } else {
              elementHtml.parentNode['style']['color'] = 'black';
            }
          } else {
            if (elementHtml['value'] == "") {
              elementHtml['style']['background-color'] = "#ffbaba"
              isValid = false
            } else (
              elementHtml['style']['background-color'] = "#C8D6F3"
            )
          }
        }
      });
      resolve(isValid);
    });
    return promise;
  };


  onSubmit() {
    this.validDocumentData().then(dataIsValid => {
      if (dataIsValid && this.documentForm.valid) {
        this.buttonDisable = true;
        Promise.all([this.matchDataAddesse(), this.matchDataSigner(), this.matchDataDocument(), this.matchDataForm(), this.matchDataUser()]).then(values => {
          let dataToSend = {
            "variables": Object.values(values[0]).concat(Object.values(values[1]), Object.values(values[2]), Object.values(values[3]), Object.values(values[4]))
          }
          this.guestsService.putDocument(this.id, dataToSend).subscribe(data => {
            if (data.status == 200) {
              this.showMsgSnack('Generando documento...');
              this.loading = true;
              this.checkDocument();
            }
          }, error => {
            this.buttonDisable = false;
            this.onError(error);
          });

        });

        return null;

      } else {
        this.buttonDisable = false;
        this.showMsgSnack('Por favor ingrese los datos requeridos');
      }
    })
  }

  sign() {
    window.open(this.externalUrl, '_blank').focus();
    this.router.navigate(['/guest/documents']);
  }

  uploadDocument() {
    this.uploadDefault();
    this.form.forEach(element => {
      this.template.content = this.template.content.split(element.anchor).join(element.value);
    });

    this.user.forEach(element => {
      this.template.content = this.template.content.split(element.anchor).join(element.value);
    });
  }

  private checkDocument() {
    this.sendSignDocument++
    this.guestsService.getDocumentByUuid(this.id).subscribe(data => {
      if (data != null) {
        let externalUrl = data.signers[0].externalUrl;
        if (externalUrl) {
          this.externalUrl = externalUrl;
          this.loading = false;
          this.readyToSign = true;
        } else {
          if (this.sendSignDocument > 7) {
            this.router.navigate(['/guest/documents']);
            this.showMsgSnack('Problema de conexión con el servidor, por favor intente mas tarde');
          } else {
            setTimeout(() => {
              this.checkDocument();
            }, 1000);
          }
        }
      }
    }, error => this.onError(error));
  }

  private onError(error) {
    this.showMsgSnack('Ha ocurrido un error');
  }

  private showMsgSnack(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  private sortComponent(variables: [VariableEntity]) {
    variables.forEach(variable => {

      if (variable.config) {
        variable.configParsed = JSON.parse(variable.config);
      }

      if (variable.globalIdentifier) {
        this.mapper[variable.globalIdentifier] = {
          uuid: variable.uuid,
          config: variable.configParsed
        }
      }

      switch (variable.container) {
        case "addressee":
          this.addressee[variable.orederNumer] = variable;
          break;
        case "signer":
          this.signer[variable.orederNumer] = variable;
          break;
        case "document":
          this.document[variable.orederNumer] = variable;
          break;
        case "form":
          this.form[variable.orederNumer] = variable;
          break;
        case "user":
          this.user[variable.orederNumer] = variable;
          break;
        default:
          break;
      }
    });
    this.document.splice(0, 1)
    this.form.splice(0, 1)
    this.user.splice(0, 1)
    this.uploadDefault()
  }

  private uploadDefault() {
    this.template.content = this.defaultContent;
    let date = new Date()
    this.user.forEach(element => {
      if (element.value != null && element.value != "" && element.value != undefined) {
        this.template.content = this.template.content.split(element.anchor).join(element.value)
      }
    });
    let formatDate = date.getDate().toString().length == 2 ? date.getDate().toString() : "0" + date.getDate()
    formatDate += "-" + (date.getMonth().toString().length == 2 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1))
    this.template.content = this.template.content.split("%document_date%").join(formatDate + "-" + date.getFullYear())
    this.template.content = this.template.content.split("id='" + '%signer_name_1%' + "' style='display: none'").join("");
    this.template.content = this.template.content.split('%signer_name_1%').join(this.signerAdressee.name)
    this.template.content = this.template.content.split('%signer_last_name_1%').join(this.signerAdressee.lastName)
    this.template.content = this.template.content.split('%signer_document_type_1%').join(this.signerAdressee.typeOfIdn)
    this.template.content = this.template.content.split('%signer_document_1%').join(this.signerAdressee.idn)
    this.template.content = this.template.content.split('%signer_phone_1%').join(this.signerAdressee.phone)
    this.template.content = this.template.content.split('%signer_mail_1%').join(this.signerAdressee.email)
    this.template.content = this.template.content.split('%signer_role_1%').join("Firmante")
    this.template.content = this.template.content.split('%addressee_name%').join(this.signerAdressee.name)
    this.template.content = this.template.content.split('%addressee_last_name%').join(this.signerAdressee.lastName)
    this.template.content = this.template.content.split('%addressee_document_type%').join(this.signerAdressee.typeOfIdn)
    this.template.content = this.template.content.split('%addressee_document%').join(this.signerAdressee.idn)
    this.template.content = this.template.content.split('%addressee_phone%').join(this.signerAdressee.phone)
    this.template.content = this.template.content.split('%addressee_mail%').join(this.signerAdressee.email)
    this.template.content = this.template.content.split('%addressee_role%').join("Firmante")
    this.document.forEach(variableDocument => {
      switch (variableDocument.type) {
        case 'string':
          if (variableDocument.value == undefined || variableDocument.value == "" || variableDocument.value == null) {
            this.template.content = this.template.content.split(variableDocument.anchor)
              .join("<input type='text' id=" + variableDocument.uuid
                + " placeholder=" + variableDocument.name
                + " style='width: 300px;background-color: #C8D6F3'>")
          } else {
            this.template.content = this.template.content.split(variableDocument.anchor)
              .join("<input type='text' id=" + variableDocument.uuid
                + " placeholder=" + variableDocument.name
                + " value=" + variableDocument.value
                + " style='width: 300px;background-color: #C8D6F3'>")
          }
          break;
        case 'dateInput':
          this.template.content = this.template.content.split(variableDocument.anchor)
            .join("<input type='date' id=" + variableDocument.uuid
              + " placeholder=" + variableDocument.name
              + " style='width: 300px;background-color: #C8D6F3'>")
          break;
        case 'select':
          let optionsSelect = JSON.parse(variableDocument.config)
          let htmlOption = "<option disabled selected>Selecciona una opción</option>"
          optionsSelect.forEach(optionInSelect => {
            htmlOption = htmlOption + "<option value='" + optionInSelect + "'>" + optionInSelect + "</option>"
          })
          this.template.content = this.template.content.split(variableDocument.anchor)
            .join("<select id=" + variableDocument.uuid + ">"
              + htmlOption
              + "</select>"
            )
          break;
        case 'check':
          this.template.content = this.template.content.split(variableDocument.anchor)
            .join("<input type='checkbox' id=" + variableDocument.uuid
              + ">")
          break;

        default:
          break;
      }
    })
  }

  private matchDataAddesse = () => {
    let promise = new Promise((resolve, reject) => {
      let variables = [new VariableDocument()]
      this.addressee.forEach(element => {
        switch (element.name) {
          case "addressee_document_type":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.typeOfIdn })
            break;
          case "addressee_document":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.idn })
            break;
          case "addressee_name":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.name })
            break;
          case "addressee_last_name":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.lastName })
            break;
          case "addressee_mail":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.email })
            break;
          case "addressee_phone":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.phone })
            break;
          default:
            break;
        }
      });
      variables.splice(0, 1)
      resolve(variables);
    });

    return promise;
  };

  private matchDataSigner = () => {
    let promise = new Promise((resolve, reject) => {
      let variables = [new VariableDocument()]
      this.signer.forEach(element => {
        switch (element.name) {
          case "signer_document_type_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.typeOfIdn })
            break;
          case "signer_document_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.idn })
            break;
          case "signer_name_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.name })
            break;
          case "signer_last_name_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.lastName })
            break;
          case "signer_mail_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.email })
            break;
          case "signer_phone_1":
            variables.push({ "uuid": element.uuid, "value": this.signerAdressee.phone })
            break;
          case "signer_role_1":
            variables.push({ "uuid": element.uuid, "value": "Firmante" })
            break;
          default:
            break;
        }

      });
      variables.splice(0, 1)
      resolve(variables);
    });

    return promise;
  };

  private matchDataDocument = () => {
    let promise = new Promise((resolve, reject) => {
      let variables = [new VariableDocument()]
      this.document.forEach(element => {
        switch (element.type) {
          case "check":
            variables.push({ "uuid": element.uuid, "value": document.getElementById(element.uuid)['checked'] })
            break;
          case "dateInput":
            if (document.getElementById(element.uuid)['value'] != "") {
              variables.push({ "uuid": element.uuid, "value": document.getElementById(element.uuid)['value'].split("-").reverse().join("-")})
            }
            break;
          default:
            if (document.getElementById(element.uuid)['value'] != "") {
              variables.push({ "uuid": element.uuid, "value": document.getElementById(element.uuid)['value'] })
            }
            break;
        }

      });
      variables.splice(0, 1)
      resolve(variables);
    });
    return promise;
  };

  private matchDataForm = () => {
    let promise = new Promise((resolve, reject) => {
      let variables = [new VariableDocument()]
      this.form.forEach(element => {
        variables.push({ "uuid": element.uuid, "value": element.value })
      });
      variables.splice(0, 1)
      resolve(variables);
    });
    return promise;
  };

  private matchDataUser = () => {
    let promise = new Promise((resolve, reject) => {
      let variables = [new VariableDocument()]
      this.user.forEach(element => {
        variables.push({ "uuid": element.uuid, "value": element.value })
      });
      variables.splice(0, 1)
      resolve(variables);
    });
    return promise;
  };
}
