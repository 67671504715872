import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";

import { Observable } from 'rxjs'

import { Pageable } from 'src/app/models/pageable.model'
import { Process } from 'src/app/models/process.model'
import { TemplateBatch } from 'src/app/models/templateBatch.model'
import { PageDocument } from 'src/app/models/pageDocument.model'
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiProcessService {

  private basePath = environment.uriApiAzure;

  constructor(private http: HttpClient) { }

  public getProcess(): Observable<Process[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Process[]>(this.basePath + '/process', httpOptions);
  }

  public getDocumentsDownload(processUuid): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/zip',
      'Authorization': localStorage.getItem("token")
    });
    return this.http.get(this.basePath + `/process/${processUuid}/download`, { headers: headers, responseType: 'arraybuffer', observe: 'response' });
  }


  public getProcessbyUUid(id): Observable<Process> {   //PageDocument
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Process>(this.basePath + '/process/' + id, httpOptions);
  }

  public getProcessbyTemplateUUid(idProcess, idTemplate): Observable<Process> {   //PageDocument
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Process>(this.basePath + '/process/' + idProcess + '/template/' + idTemplate, httpOptions);
  }

  public getProcessDocumentsbyUUid(id): Observable<PageDocument> {   //PageDocument
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<PageDocument>(this.basePath + '/process/' + id + '/documents', httpOptions);
  }

  public getProcessFilters(filter: string, pageable: Pageable): Observable<Process[]> {
    let url = ""
    url = url + (pageable.pageNumber != 0 ? "&page=" + pageable.pageNumber : "")
    url = url + (pageable.pageSize != null ? "&size=" + pageable.pageSize : "")
    url = url + (pageable.order != null ? "&order=" + pageable.order : "")
    url = url + (pageable.direction != null ? "&direction=" + pageable.direction : "")
    url = url + (filter != null ? "&filter=" + filter : "")

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Process[]>(this.basePath + '/process?' + url, httpOptions);
  }

  public getProcessbyStatusFilters(statusId, filter: string, pageable: Pageable): Observable<Process[]> {
    let url = ""
    url = url + (pageable.pageNumber != 0 ? "&page=" + pageable.pageNumber : "")
    url = url + (pageable.pageSize != null ? "&size=" + pageable.pageSize : "")
    url = url + (pageable.order != null ? "&order=" + pageable.order : "")
    url = url + (pageable.direction != null ? "&direction=" + pageable.direction : "")
    url = url + (filter != null ? "&filter=" + filter : "")
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Process[]>(this.basePath + '/process/status/' + statusId + "?" + url, httpOptions);
  }

  public getProcessbyStatus(statusId): Observable<Process[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }),
    }
    return this.http.get<Process[]>(this.basePath + '/documents/status/' + statusId, httpOptions);
  }

  public postProcesTemplateBatch(uuid, documentBatch: TemplateBatch): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.post(this.basePath + '/process/' + uuid + '/documents', JSON.stringify(documentBatch), { headers: headers, observe: 'response' });
  }

  public putProces(uuid): Observable<HttpResponse<Object>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token")
    })
    return this.http.put(this.basePath + '/process/' + uuid, null, { headers: headers, observe: 'response' });
  }

}
